import axios from "axios";
import React, { useState, useContext, useEffect, useMemo } from "react";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import { GlobalFilter } from "../../components/globalFilter";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  setGlobalFilter,
  usePagination,
} from "react-table";
import { Card } from "react-bootstrap";

import moment from "moment/moment";
import "moment/locale/it";

import { Image } from "react-bootstrap";
import AdminAppNav from "../../components/AdminAppNav";

function ArchiveCongressScan() {
  //dichiara variabili di congresso
  const [congress, setCongress] = useState([]);

  //variabili server, error
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  //chiamata di visualizzazione congressi, con conteggio iscritti

  const getCongress = async () => {
    try {
      setLoading(true);
      let body = {
        access_token: auth.accessToken,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(
        Globals.ADMINGETALLSCANCONGRESS,
        formdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        setLoading(false);
        setErrMsg(response?.data?.message);
      } else {
        setLoading(false);
        setCongress(response?.data?.congress);
      }
    } catch (err) {
      setLoading(false);
      setErrMsg(err);
    }
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      // {
      // 	id: "Delete",
      // 	Header: "Delete",
      // 	Cell: ({ row }) => (
      // 		<FontAwesomeIcon
      // 			icon={faTrash}
      // 			onClick={() => console.log(row.original.id)}
      // 			className='hover:text-red-500'
      // 		/>
      // 	),
      // },
    ]);
  };

  const congressData = useMemo(() => [...congress], [congress]);

  const congressColumns = useMemo(
    () =>
      congress[0]
        ? Object.keys(congress[0])
            .filter(
              (key) =>
                key !== "id" &&
                key !== "description" &&
                key !== "description_eng"
            )
            .map((key) => {
              if (key === "immagine")
                return {
                  Header: "",
                  accessor: key,
                  Cell: ({ value }) => (
                    <img src={value} height={"150px"} width={"150px"} />
                  ),
                };

              if (key === "data-inizio")
                return {
                  Header: "Inizio",
                  accessor: key,
                  Cell: ({ value }) => (
                    <span> {moment(value).format("DD MMMM YYYY")}</span>
                  ),
                };

              if (key === "data-fine")
                return {
                  Header: "Fine",
                  accessor: key,
                  Cell: ({ value }) => (
                    <span> {moment(value).format("DD MMMM YYYY")}</span>
                  ),
                };

              return { Header: key, accessor: key };
            })
        : [],
    [congress]
  );

  const tableInstance = useTable(
    {
      columns: congressColumns,
      data: congressData,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    page,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  useEffect(() => {
    getCongress();
  }, []);

  //dichiarazione tabella di visualizzazione dei congressi con annesso hover

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div
        className="container"
        style={{ marginTop: "140px", marginBottom: "100px" }}
      >
        <div>
          <h2 className="mt-5"> </h2>
          {/* <Button onClick={() => navigate(-1)}>
						<span> Torna indietro</span>
					</Button> */}
          <AdminAppNav />
        </div>
        {/* <h2 className=" text-center uppercase" style={{fontSize:"50px"}}> Gestione iscrizioni congressi </h2> */}

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />

        <Table
          {...getTableProps()}
          striped
          bordered
          hover
          responsive
          variant="light"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      borderBottom: "solid 3px ",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "20px",
                      textTransform: "capitalize",
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "5px",
                          border: "solid 1px gray",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            "/congressScanSubscribeList/" + row.original.id,
                            {
                              state: { name: row.original.nome },
                            }
                          )
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Card>
          <div className="responsive-pagination">
            <div>
              <button
                className="btn btn-light"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="btn btn-light mx-1"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Precedente
              </button>{" "}
              <button
                className="btn btn-light mx-1"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Successiva
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="btn btn-light mx-1"
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="float-end">
              <span className="w-100">
                Pagina:{" "}
                <strong>
                  {pageIndex + 1} di {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="text-center">
              <span>
                Vai alla pagina{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                  className="d-flex justify-content-center mx-auto responsive-pagination"
                />
              </span>{" "}
            </div>
            <select
              className="form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50, 75].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Visualizza {pageSize} righe
                </option>
              ))}
            </select>
          </div>
        </Card>
        {errMsg && <p className="fs-1 fw-bolder danger"> {errMsg}</p>}
      </div>
    );
  }
}

export default ArchiveCongressScan;
