import React from "react";
import AuthContext from "../../context/AuthProvider";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Globals from "../../parameter/globals";

function HomeClient() {
	const { auth, lang } = useContext(AuthContext);

	return (
		<article
			className='responsive-div-area-admin'
			style={{
				textAlign: "center",
				display: "flex",
				alignItems: "center",
				height: "50em",
			}}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.userArea[lang]}</title>
			</Helmet>
			<div className='container  '>
				<div className='row'>
					<div className='col-lg-12 p-5'>
						<h1 className='d-flex justify-content-center'>
							{" "}
							{Globals.language.userArea.userArea[lang]}
						</h1>

						<h2 className='d-flex justify-content-end my-auto'>
							{" "}
							<i className='bi bi-person my-auto' aria-hidden='true'></i>{" "}
							<p className='text-danger my-auto' style={{ fontSize: "20px" }}>
								{" "}
								{auth.user}
							</p>
						</h2>
						<hr />
					</div>
				</div>
				<div className='row row-admin-area'>
					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/userRegistry' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{ backgroundColor: "#d50037" }}>
									<div className='card-body p-1'>
										<i
											className='bi bi-gear fa-2x'
											aria-hidden='true'
											style={{ fontSize: "30px", color: "#fff" }}></i>
										<hr />
										<p className='card-title lead text-light'>
											{Globals.language.userArea.biography[lang]}
										</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/activeRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{ backgroundColor: "#d50037" }}>
									<div className='card-body p-1'>
										<i
											className='bi bi-clipboard2-data fa-2x'
											aria-hidden='true'
											style={{ fontSize: "30px", color: "#fff" }}></i>
										<hr />
										<p className='card-title lead text-light'>
											{Globals.language.userArea.registrationEventsCongress[lang]}{" "}
										</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{ backgroundColor: "#d50037" }}>
									<div className='card-body p-1'>
										<i
											className='bi bi-cart-fill fa-2x'
											aria-hidden='true'
											style={{ fontSize: "30px", color: "#fff" }}></i>
										<hr />
										<p className='card-title lead text-light'>
											{" "}
											{Globals.language.userArea.storicoIscrizioni[lang]}{" "}
										</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					{/* <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1">
      <a className="text-decoration-none" href="#">
	  <Link to='/adminUsers' className="text-light">
        <div className="card p-3 shadow text-center border-0 h-100" style={{ backgroundColor:"#d50037" }}>
          <div className="card-body p-1">
            <i className="bi bi-person-lines-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            <hr />
            <p className="card-title lead text-light"> Gestione utenti {" "}</p>
          </div>
        </div>
		</Link>
      </a>
    </div> */}

					{/* <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2 p-2">
      <a className="text-decoration-none" href="#">
        <div className="card p-3 shadow bg-danger text-center border-0">
          <div className="card-body">
            <i className="bi bi-cart-plus fa-2x" aria-hidden="true"></i>
            <hr />
            <p className="card-title lead"><Link to='/adminorder' className="text-light"> Gestione Ordini </Link>{" "}</p>
          </div>
        </div>
      </a>
    </div>

    <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2 p-2">
      <a className="text-decoration-none" href="#" data-toggle="modal" data-target="#modelHELP">
        <div className="card p-3 shadow bg-danger text-center border-0">
          <div className="card-body">
            <i className="bi bi-question fa-2x" aria-hidden="true"></i>
            <hr />
            <p className="card-title lead"><Link to='/adminorder' className="text-light"> Gestione Ordini </Link>{" "}</p>
          </div>
        </div>
      </a>
    </div> */}
				</div>
			</div>

			<div
				className='modal fade'
				id='modelHELP'
				tabindex='-1'
				role='dialog'
				aria-labelledby='modelTitleId'
				aria-hidden='true'>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>
								<i className='bi bi-question-circle-o' aria-hidden='true'></i> Technical
								Support 24/7
							</h5>
							<button
								type='button'
								className='close'
								data-dismiss='modal'
								aria-label='Close'>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<div className='modal-body p-5'>
							<p>
								<small>(Mon - Sat / 10AM - 6PM)</small>
							</p>
							<hr />
							<p>
								{" "}
								<i className='bi bi-envelope mr-3' aria-hidden='true'></i>{" "}
							</p>
							<p>
								<i className='bi bi-volume-control-phone mr-3' aria-hidden='true'></i>{" "}
								+91-123654789{" "}
							</p>
						</div>
					</div>
				</div>
			</div>
		</article>

		// <article style={{ textAlign: "center", display: "flex", height: "50em" }}>
		// 	{" "}
		// 	<div style={{ position: "absolute", top: "200px", left: "40%", }}>
		// 		{" "}
		// 		<h1> Benvenuto {auth.user}</h1>{" "}
		// 		<h2>
		// 			{" "}
		// 			Accedi ad <Link to='/userRegistry'> Anagrafica partecipante </Link>{" "}
		// 		</h2>{" "}
		// 		<h2>
		// 			{" "}
		// 			Accedi ad{" "}
		// 			<Link to='/activeRegistration'> Iscrizioni presenti e future </Link>{" "}
		// 		</h2>{" "}
		// 		<h2>
		// 			{" "}
		// 			Accedi ad <Link to='/archiveRegistration'> Storico iscrizioni </Link>{" "}
		// 		</h2>{" "}
		// 	</div>
		// </article>
	);
}

export default HomeClient;
