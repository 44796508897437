import CarouselSol from "../../components/Carousel";
import Team from "../../components/Team";
import Congressi from "../../components/Congressi";
import React, { useEffect, useState, useContext } from "react";
import {useLocation } from "react-router-dom";
import axios from "axios";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import Contact from "../../components/Contact";
import Partners from "../../components/Partners";
import Eventi from "../../components/Eventi";
// import "@fontsource/oswald"
import Newsletter from "../../components/Newsletter";
import "../../scss/home.scss";
import {Helmet} from "react-helmet";
import AuthContext from "../../context/AuthProvider";

function Home() {
	const location = useLocation();
	const { lang } = useContext(AuthContext);


	const [dataComponent, setData] = useState({
		carousel: [],
		chiSiamo: "",
		congresses: "",
		events: "",
		client_partners: "",
		doveSiamo: "",
		team: [],
		homeVideo: [],
		video_home: []
	});

	const [loading, setLoading] = useState(true);

	const getHomeElement = async () => {
		try {
			
			let body = {
				lang: lang
			};
			
			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.GETHOME, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				console.log("err");
			} else {
				setData({
					...dataComponent,
					carousel: response.data.carousel,
					chiSiamo: response.data.chi_siamo,
					congresses: response.data.congresses,
					events: response.data.events,
					client_partners: response.data.client_partners,
					doveSiamo: response.data.doveSiamo,
					team: response.data.staff,
					homeVideo: response.data.home_video,
					video_home: response.data.video_home,
				});

				setLoading(false);
				scrollToIdElement();
			}
		} catch (err) {
			setLoading(false);
		}
	};

	const getPersonalHomeElement = async () => {
		try {
			let body = {
				access_token: localStorage.getItem("accessToken"),
				lang: lang
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.GETPERSONALHOME, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			// console.log(response);

			if (response?.data?.error === true) {
				console.log("err");
			} else {
				setData({
					...dataComponent,
					carousel: response.data.carousel,
					chiSiamo: response.data.chi_siamo,
					congresses: response.data.congresses,
					events: response.data.events,
					client_partners: response.data.client_partners,
					doveSiamo: response.data.doveSiamo,
					team: response.data.staff,
					homeVideo: response.data.home_video,
					video_home: response.data.video_home,
				});

				setLoading(false);
				scrollToIdElement();
			}
		} catch (err) {
			setLoading(false);
		}
	};

	useEffect(() => {
		//Runs only on the first render
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			getPersonalHomeElement();
		} else {
			getHomeElement();
		}
		return () => {
			setData({
				carousel: "",
				chiSiamo: "",
				congresses: "",
				events: "",
				client_partners: "",
				doveSiamo: "",
				team: "",
				homeVideo: "",
			});
			// window.scrollTo(0, 0);
			
		};
	}, [location, lang]);


	useEffect(() => {
		setLoading(true);
	}, [lang])
	

	useEffect(() => {
		scrollToIdElement();
	}, [location.hash]);

	const scrollToIdElement = async () => {
		const { hash } = window.location;
		if (hash !== '') {
		  // Push onto callback queue so it runs after the DOM is updated,
		  // this is required when navigating from a different page so that
		  // the element is rendered on the page before trying to getElementById.
		  setTimeout(() => {
			const id = hash.replace('#', '');
			const yOffset = -100;
			const element = document.getElementById(id);
			const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
		// 	if (element) (element).scrollIntoView({
		// 		top: 150, 
		//   });
		  }, 0);
		}

		// const toObjectElement = element.getBoundingClientRect().toJSON();

		// const { left, top } = toObjectElement;

		// setTimeout(
		// 	() => window.scrollTo({ top:  top, left: left, behavior: "smooth" }),
		// 	500
		// );
	};
	return (
		<div className='App'>
			<Helmet>
                <meta charSet="utf-8" />
                <title>{Globals.language.title.home[lang]}</title>
                <link rel="canonical" href="https://www.soluzioniomniamedia.com/" />
            </Helmet>

			{loading === true ? (
				<Loading />
			) : (
				<>
					<CarouselSol
						data={dataComponent.carousel}
						video={dataComponent.homeVideo}
						video_home={dataComponent.video_home}
					/>
					<Team data={dataComponent.team} chisiamo={dataComponent.chiSiamo} /> 
					<Congressi data={dataComponent.congresses} />
					<Eventi data={dataComponent.events} />
					<Partners data={dataComponent.client_partners} />
					<Contact general={true} />
					<Newsletter />{" "}
				</>
			)}
		</div>
	);
	// }
}

export default Home;

