import "../scss/footer.scss";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Globals from "../parameter/globals";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const Footer = (props) => {
	const { lang } = useContext(AuthContext);
	let modifyCookie = props.modifyCookie || [];
	const [textFooter, setTextFooter] = useState("");
	const [activeCookiebar, setActiveCookiebar] = useState(0);
	let now = new Date().getFullYear();

	const getFooter = async () => {
		try {
			const response = await axios.get(Globals.GETFOOTER + "&lang=" + lang);
			setTextFooter(response.data.footer_text);
			setActiveCookiebar(parseInt(response.data.active_cookie[0].cookie_bar));
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getFooter();
	}, [lang]);

	return (
		<div className='App '>
			<section className='contact-area ' id='contact'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-6 offset-lg-3'>
							<div className='contact-content text-center'>
								<img src={logo} width={"100px"} alt='logo soluzioni omnia media' />

								<p dangerouslySetInnerHTML={{ __html: textFooter }} />
								<div className='hr'></div>
								<h6>
									{Globals.language.footer.footerFirstDesc[lang]} <br />
								</h6>
								<h6>{Globals.language.footer.footerSecondDesc[lang]}</h6>
								<h6>
									(+39) 045 8034553<span>|</span>
									<a href='mailto:marketing@soluzioniomniamedia.com'>
										marketing@soluzioniomniamedia.com
									</a>
								</h6>
								<div className='contact-social'>
									<ul>
										<li>
											<a
												className='hover-target'
												href='https://it-it.facebook.com/soluzioniverona.it/'
												target='_blank'>
												<i className='bi bi-facebook'></i>
											</a>
										</li>
										<li>
											<a
												className='hover-target'
												href='https://www.instagram.com/soluzioniomniamedia/?hl=it'
												target='_blank'>
												<i className='bi bi-instagram'></i>
											</a>
										</li>
										<li>
											<a
												className='hover-target'
												href='https://it.linkedin.com/company/soluzioni-omnia-media-s-r-l'
												target='_blank'>
												<i className='bi bi-linkedin'></i>
											</a>
										</li>
									</ul>
								</div>
								<div className='contact-social'>
									<ul>
										<Link className='mx-2' to='/cookie-policy'>
											<li>{Globals.language.footer.cookiePolicy[lang]}</li>
										</Link>{" "}
										|
										<Link className='mx-2' to='/privacy-policy'>
											<li>{Globals.language.footer.privacyPolicy[lang]}</li>
										</Link>
										|
										<Link className='mx-2' to='/condizioni-vendita'>
											<li>{Globals.language.footer.condizioniVendita[lang]}</li>
										</Link>
										|
										<Link className='mx-2' to='/condizioni-uso'>
											<li>{Globals.language.footer.condizioniUtilizzo[lang]}</li>
										</Link>
										{activeCookiebar !== 0 ? (
											<>
												|
												<Button
													className='mx-2 btn btn-link p-0 bg-transparent'
													onClick={modifyCookie}>
													<li>{Globals.language.footer.preferenzeCookie[lang]}</li>
												</Button>
											</>
										) : (
											<div></div>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<footer>
					<p>
						Copyright &copy; {now} <b>Soluzioni Omnia Media Srl</b> All Rights Reserved.
					</p>
					<div>
						<p>
							<a href='https://www.readynet.it/' target='_blank' rel='noreferrer'>
								Powered by Ready Net Srl
							</a>{" "}
						</p>
					</div>
				</footer>
			</section>
		</div>
	);
};

export default Footer;
