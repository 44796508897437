import React, { useState, useContext } from "react";
import AuthContext from "../context/AuthProvider";
import Button from "react-bootstrap/Button";
import "../scss/active-registration.scss";
import Modal from "react-bootstrap/Modal";
import logo from "../images/logo-mobile.svg";
import Globals from "../parameter/globals";
import useAuth from "../hooks/useAuth";

function CardObject(props) {
	const {
		id,
		name,
		title_description,
		address,
		city,
		start_date,
		end_date,
		total_price,
		data_ora,
		img_url,
		type,
		agents,
		services,
		paid,
	} = props.element;

	const [show, setShow] = useState(false);
	const { auth, lang } = useContext(AuthContext);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	const formatMonth = (item) => {
		let d = new Date(item);

		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		return month;
	};

	const exportPdf = async () => {
		window
			.open(
				Globals.GETMYTICKET +
					id +
					"&type=" +
					type +
					"&accessToken=" +
					auth.accessToken +
					"&lang=" +
					lang,
				"name",
				"titlebar=no"
			)
			.focus();
	};

	return (
		<div>
			{/* <Card className='text-center'>
			<Card.Header>
				{address} - {city}
			</Card.Header>
			<Card.Body>
				<Card.Title>{name}</Card.Title>
				<Card.Text>{title_description}</Card.Text>
				<Card.Text>
					{parseFloat(total_price) === 0
						? "Gratis"
						: Intl.NumberFormat("de-DE", {
								style: "currency",
								currency: "EUR",
						  }).format(total_price)}
				</Card.Text>
				<Button variant='primary'>Dettaglio</Button>
			</Card.Body>
			<Card.Footer className='text-muted'>
				{start_date} - {end_date}
			</Card.Footer>
		</Card> */}

			<div className='m-badge'>
				<section className='m-badge__content'>
					<div className='a-slot'></div>
					<div className='container-fluid w-75 image-responsive-badge '>
						<img className='m-badge__image' src={img_url} height='200' alt='' />
					</div>
					<div className='m-badge__info'>
						<p className='m-badge__infoName'>{name}</p>
						{/* <i className="m-badge__infoCompany"><span className="d-flex justify-content-center"><i className="bi bi-map" style={{fontSize:"30px"}}></i> <br></br></span>{address} - {city}</i> */}
					</div>
					<div className='m-badge__date mx-auto'>
						<p>
							{formatDay(start_date)}-{formatMonth(start_date)}-
							{formatYear(start_date)} / {formatDay(end_date)}-{formatMonth(end_date)}-
							{formatYear(end_date)}
						</p>
					</div>
					{/* <div className="m-badge__social">
			{title_description}
		{parseFloat(total_price) === 0
						? "Gratis"
						: Intl.NumberFormat("de-DE", {
								style: "currency",
								currency: "EUR",
						  }).format(total_price)}    
        </div> */}
					<div className='m-badge__code'>
						{/* <i className='bi bi-qr-code ' style={{ fontSize: "80px" }}></i> */}
						<i className='bi bi-ticket-detailed' style={{ fontSize: "80px" }}></i>
					</div>
					<Button className='mx-auto' onClick={handleShow} variant='primary'>
						{Globals.language.cardObject.buttondetail[lang]}
					</Button>
				</section>
				<footer className='m-badge__footer'>
					<img src={logo} className='logo-fixed-soluzioni'></img>
				</footer>
			</div>
			<Modal
				centered
				show={show}
				size='md'
				onHide={handleClose}
				style={{ zIndex: 9999 }}>
				<Modal.Header closeButton>
					<Modal.Title>{name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<img src={img_url} className='img-responsive image-card-congressi'></img>
					<h3 className='text-center'>
						{formatDay(start_date)}-{formatMonth(start_date)}-{formatYear(start_date)}{" "}
						/ {formatDay(end_date)}-{formatMonth(end_date)}-{formatYear(end_date)}
					</h3>
					<div className='text-center'>
						<div>
							<h2>{Globals.language.cardObject.title[lang]}</h2>
							<h4>{title_description}</h4>
						</div>
						<div>
							<h2>{Globals.language.cardObject.street[lang]}</h2>
							<h4>{address}</h4>
						</div>
						<div>
							<h2>{Globals.language.cardObject.city[lang]}</h2>
							<h4>{city}</h4>
						</div>
					</div>

					{services?.length > 0 && (
						<div className='text-center'>
							<strong> {Globals.language.cardObject.services[lang]} </strong>
							{services.map((item, key) => {
								return (
									<div>
										<span> {item.categories_name}</span> -<span> {item.name} </span>
									</div>
								);
							})}
						</div>
					)}

					{agents?.length > 0 && (
						<div className='text-center'>
							<strong> {Globals.language.cardObject.agents[lang]} </strong>
							{agents.map((item, key) => {
								return (
									<div>
										<span> {item.categories_name}</span> -<span> {item.name} </span>
									</div>
								);
							})}
						</div>
					)}

					<h4 className='d-flex w-25 text-center align-item-center justify-content-center btn btn-danger disabled mx-auto'>
						{parseFloat(total_price) === 0
							? "Gratis"
							: Intl.NumberFormat("de-DE", {
									style: "currency",
									currency: "EUR",
							  }).format(total_price)}
					</h4>
					<div>
						{parseInt(paid) === 1 && (
							<div className='mx-auto text-center'>
								<Button
									className='mx-auto'
									variant='warning'
									onClick={() => exportPdf()}>
									<span> {Globals.language.cardObject.ticket[lang]}</span>
								</Button>
							</div>
						)}
					</div>
				</Modal.Body>
				{/* <i className='bi bi-qr-code mx-auto' style={{ fontSize: "80px" }}></i> */}
				<i
					className='bi bi-ticket-detailed mx-auto'
					style={{ fontSize: "80px" }}></i>

				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						{Globals.language.cardObject.buttonClose[lang]}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default CardObject;
