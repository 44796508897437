import "../scss/cart.scss";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import {useContext, useEffect} from "react";
import CartContext from "../context/CartProvider";
import {Link} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Login from "../pages/public/Login";
import ChoosePayment from "./ChoosePayment";
import {Card} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import defaultImage from "../images/empty_cart/empty-cart.gif";
import Button from "react-bootstrap/Button";
import parse from "html-react-parser";
import {Form} from "react-bootstrap";
import axios from "axios";
import Globals from "../parameter/globals";
import {Helmet} from "react-helmet";
import couponImage from "../images/coupon.png";

function Cart() {
	const {auth, lang} = useAuth();
	const navigate = useNavigate();

	const {
		cartItems,
		totalPrice,
		ecommerce,
		onRemoveService,
		totalQuantities,
		resetCart,
		coupon,
	} = useContext(CartContext);
	const [condizioni, setCondizioni] = useState(false);
	const [clausole, setClausole] = useState(false);

	const [public_administration, setAuth] = useState([]);

	useEffect(() => {
		checkLogin();
	}, []);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				let accessToken = myAccessToken;
				let roles = response?.data?.roles;
				let user = response?.data?.username;
				let is_public_administration = response?.data?.public_administration;
				setAuth({user, accessToken, roles, is_public_administration});
			} catch (err) {
				setAuth({});
				localStorage.setItem("accessToken", "");
			}
		}
	};

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		return month;
	};
	const handleOnChange = () => {
		setCondizioni(!condizioni);
	};
	const handleOnChangeClausole = () => {
		setClausole(!clausole);
	};

	// const handleChange = (e) =>{
	// 	e.preventDefault();

	// 	const test = condizioni;
	// 	console.log(test)
	// 	//We set the state value depending on input that is clicked
	// 	// if(name === "second"){
	// 	// 	if(parseInt(this.state.firstValue) < parseInt(value)){
	// 	// 		this.setState({secondValue:value}, this.updateCheckBox);
	// 	// 	}
	// 	// }else{
	// 	// 	//The first value can't be greater than the second value
	// 	// 	if(parseInt(value) < parseInt(this.state.secondValue)) {
	// 	// 		this.setState({firstValue: value}, this.updateCheckBox);
	// 	// 	}
	//   }

	return (
		<div>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.cart[lang]}</title>
				<meta name="robots" content="noindex" />
			</Helmet>
			{ecommerce.map((item, key) => (
				<div key={key}>
					{item.active !== "1" ? (
						<section
							className='container'
							style={{
								backgroundColor: "#f3f3f3",
								marginBottom: "100px",
								marginTop: "80px",
							}}>
							<div className='container py-5 h-100'>
								<div className='row d-flex justify-content-center align-items-center h-100'>
									<div className='col-12'>
										<div className='card' style={{borderRadius: "15px"}}>
											<div className='card-body p-0'>
												<div className='row g-0'>
													<div className='col-lg-12'>
														<div className='p-2'>
															<Card className='card-cart'>
																<div className='d-flex justify-content-center align-items-center div-responsive-cart-title '>
																	<div className='col-lg-10 col-sm-12 '>
																		{cartItems.length > 0 ? (
																			cartItems.length === 1 ? (
																				<h1 className='fw-bold mb-0 color-predefinito-title'>
																					{Globals.language.cart.riepilogoCart[lang]} "
																					{cartItems[0].name}"{" "}
																				</h1>
																			) : (
																				<h1 className='fw-bold mb-0 color-predefinito-title'>
																					{Globals.language.cart.riepilogoAcquisto[lang]}{" "}
																				</h1>
																			)
																		) : (
																			<h1 className='fw-bold mb-0 color-predefinito-title color-predefinito-title'>
																				{Globals.language.cart.riepilogo[lang]}
																			</h1>
																		)}
																	</div>
																	<div className='col-lg-2 col-sm-12'>
																		<h6 className='mb-0 text-muted'>
																			{" "}
																			{totalQuantities === 0
																				? Globals.language.cart.noTicket[lang]
																				: Globals.language.cart.numberTicket[lang] +
																				  totalQuantities}{" "}
																		</h6>
																	</div>
																</div>
															</Card>

															<hr className='my-5' />

															{totalQuantities === 0 ? (
																<div>
																	{" "}
																	<img src={defaultImage}></img>
																	<h3>
																		{" "}
																		{Globals.language.cart.emptyCart[lang]} <br></br>
																		{Globals.language.cart.emptyCartSecond[lang]}
																		<Link to='/home'> home </Link>
																	</h3>{" "}
																</div>
															) : (
																cartItems.map((item, index) => {
																	return (
																		<div
																			className='row mb-4 d-flex justify-content-between align-items-center'
																			key={index}>
																			<Table responsive className='participants-table'>
																				<thead>
																					<tr>
																						<th width={"33%"} className='border-0 th-custom p-0'>
																							<div className='principal-div-servizi'>
																								<div className='p-2 text-center border-0 text-light categoria-bg'>
																									{item.name}
																								</div>
																							</div>
																						</th>
																					</tr>
																					<tr style={{borderTop: "1px solid #d50037"}}>
																						<th width={"20%"} className='border-0 text-center'></th>
																						<th
																							width={"20%"}
																							className='border-0 text-center remove-th-responsive'>
																							{/* Data */}
																						</th>
																						<th
																							width={"20%"}
																							className='border-0 text-center remove-th-responsive'>
																							{/* Luogo */}
																						</th>
																						<th
																							width={"10%"}
																							className='border-0 text-center remove-th-responsive'>
																							{/* Quantità */}
																						</th>
																						<th
																							width={"30%"}
																							className='border-0 text-center remove-th-responsive'>
																							{/* Prezzo */}
																						</th>
																					</tr>
																				</thead>
																				<tbody>
																					<tr>
																						<td>
																							<img
																								src={item.img_url}
																								className='img-fluid rounded-3 img-cart'
																								alt='Cotton T-shirt'
																							/>
																						</td>

																						<td data-th='Data' className='text-center'>
																							<strong>{Globals.language.cart.data[lang]}</strong>{" "}
																							<br></br>
																							{formatDay(item.start_date)}-
																							{formatMonth(item.start_date)}-
																							{formatYear(item.start_date)} <br></br>{" "}
																							{formatDay(item.end_date)}-{formatMonth(item.end_date)}-
																							{formatYear(item.end_date)}
																						</td>
																						<td data-th='Luogo' className='text-center'>
																							{" "}
																							<strong>{Globals.language.cart.luogo[lang]}</strong>{" "}
																							<br></br>
																							<br></br>
																							{item.address}{" "}
																						</td>
																						<td data-th='Quantità' className='text-center'>
																							<strong>{Globals.language.cart.quantita[lang]}</strong>{" "}
																							<br></br>
																							<br></br>{" "}
																							<input
																								disabled
																								className={"text-center w-50"}
																								value={1}
																							/>{" "}
																						</td>

																						{item?.tickets === false && (
																							<td data-th='Prezzo' className='text-center'>
																								<strong>{Globals.language.cart.price[lang]}</strong>{" "}
																								<br></br>
																								<br></br>{" "}
																								<h6 className='mb-0'>
																									{Intl.NumberFormat("de-DE", {
																										style: "currency",
																										currency: "EUR",
																									}).format(item.price)}
																								</h6>{" "}
																								{parseInt(item.price) === 0 ? (
																									<span></span>
																								) : (
																									<span className='mx-1' style={{fontSize: "15px"}}>
																										+ {Globals.language.cart.vat[lang]}.{" "}
																									</span>
																								)}
																							</td>
																						)}
																					</tr>
																				</tbody>
																			</Table>
																			{item.tickets === true &&
																				item?.ticket_category?.map((element, i) => {
																					return (
																						<div
																							className=' d-flex justify-content-center align-items-center'
																							key={i}>
																							<Table responsive className='participants-table'>
																								<thead>
																									<tr>
																										<th width={"33%"} className='border-0 th-custom p-0'>
																											<div className='principal-div-servizi'>
																												<div className='p-2 text-center border-0 text-light categoria-bg'>
																													{Globals.language.cart.tipoBiglietto[lang]}
																												</div>
																											</div>
																										</th>
																									</tr>
																									<tr
																										style={{
																											borderTop: "1px solid #d50037",
																										}}>
																										<th
																											width={"50%"}
																											className='border-0 text-center remove-th-responsive'>
																											{Globals.language.cart.name[lang]}
																										</th>
																										<th
																											width={"50%"}
																											className='border-0 text-center remove-th-responsive'>
																											{Globals.language.cart.price[lang]}
																										</th>
																										<th
																											width={"50%"}
																											className='border-0 text-center remove-th-responsive'>
																											{Globals.language.cart.quantita[lang]}
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr>
																										<td
																											data-th='Tipo di servizio'
																											className='text-center'>
																											{element.name}
																										</td>
																										<td data-th='Prezzo' className='text-center'>
																											{" "}
																											<h6 className='mb-0'>
																												<h6
																													className='mb-0'
																													style={{
																														textDecoration:
																															coupon.active === true ? "line-through" : "none",
																													}}>
																													{Intl.NumberFormat("de-DE", {
																														style: "currency",
																														currency: "EUR",
																													}).format(element.price)}
																												</h6>
																												{parseInt(element.price) === 0 ? (
																													<span></span>
																												) : (
																													<span className='mx-1' style={{fontSize: "15px"}}>
																														+ {Globals.language.cart.vat[lang]}.{" "}
																													</span>
																												)}
																											</h6>
																										</td>

																										<td data-th='Quantità' className='text-center'>
																											{" "}
																											<input
																												disabled
																												className={"text-center"}
																												value={1}
																											/>{" "}
																										</td>
																									</tr>
																								</tbody>
																							</Table>
																						</div>
																					);
																				})}

																			{item?.congress_added?.length > 0 ? (
																				<p className='title-homepage-all'>
																					{" "}
																					{Globals.language.cart.service[lang]}{" "}
																				</p>
																			) : (
																				<div className='py-3'>
																					<Card className='d-flex justify-content-center align-items-center mx-auto py-2'>
																						<h4>{Globals.language.cart.serviceAddedd[lang]}</h4>
																					</Card>
																				</div>
																			)}
																			{item?.congress_added?.map((element, i) => {
																				return (
																					<div
																						className=' d-flex justify-content-center align-items-center'
																						key={i}>
																						<Table responsive className='participants-table'>
																							<thead>
																								<tr>
																									<th width={"33%"} className='border-0 th-custom p-0'>
																										<div className='principal-div-servizi'>
																											<div className='p-2 text-center border-0 text-light categoria-bg'>
																												{element.category}
																											</div>
																										</div>
																									</th>
																								</tr>
																								<tr
																									style={{
																										borderTop: "1px solid #d50037",
																									}}>
																									<th
																										width={"20%"}
																										className='border-0 text-center remove-th-responsive'>
																										{Globals.language.cart.typeService[lang]}
																									</th>
																									<th
																										width={"20%"}
																										className='border-0 text-center remove-th-responsive'>
																										{Globals.language.cart.price[lang]}
																									</th>
																									<th
																										width={"20%"}
																										className='border-0 text-center remove-th-responsive'>
																										{Globals.language.cart.quantita[lang]}
																									</th>
																									<th width={"30%"} className='border-0 text-center'>
																										{element.start_time !== ""
																											? Globals.language.cart.time[lang]
																											: ""}
																									</th>
																									<th
																										width={"10%"}
																										className='border-0 text-center remove-th-responsive'>
																										{" "}
																										{Globals.language.cart.actions[lang]}
																									</th>
																								</tr>
																							</thead>
																							<tbody>
																								<tr>
																									<td data-th='Tipo di servizio' className='text-center'>
																										{element.name}
																									</td>
																									<td data-th='Prezzo' className='text-center'>
																										{" "}
																										<h6 className='mb-0'>
																											<h6 className='mb-0'>
																												{Intl.NumberFormat("de-DE", {
																													style: "currency",
																													currency: "EUR",
																												}).format(element.price)}
																											</h6>
																											{parseInt(element.price) === 0 ? (
																												<span></span>
																											) : (
																												<span className='mx-1' style={{fontSize: "15px"}}>
																													+ {Globals.language.cart.vat[lang]}.{" "}
																												</span>
																											)}
																										</h6>
																									</td>

																									<td data-th='Quantità' className='text-center'>
																										{" "}
																										<input
																											disabled
																											className={"text-center"}
																											value={1}
																										/>{" "}
																									</td>
																									<td className='text-center'>
																										{" "}
																										{element.start_time && element.end_time !== "" ? (
																											<div>
																												{" "}
																												{Globals.language.cart.start[lang]}:{" "}
																												<strong>{element.start_time}</strong> <br></br>{" "}
																												{Globals.language.cart.end[lang]}:{" "}
																												<strong>{element.end_time}</strong>
																											</div>
																										) : (
																											<div></div>
																										)}
																									</td>
																									<td className='text-center'>
																										{" "}
																										<button
																											className='text-muted btn b-none'
																											onClick={() => onRemoveService(item, element.id)}>
																											<i className='bi bi-trash-fill'></i>
																										</button>
																									</td>
																								</tr>
																							</tbody>
																						</Table>
																					</div>
																				);
																			})}
																			{/* {item?.agents?.length > 0 ? (
																				<p className='title-homepage-all'>  </p>
																			) : (
																				<div className='py-3'>
																					<Card className='d-flex justify-content-center align-items-center mx-auto py-2'>
																						<h4>Non sono presenti agenti in questo congresso</h4>
																					</Card>
																				</div>
																			)} */}
																			{item?.agents?.map((element, i) => {
																				return (
																					<div
																						className='d-flex justify-content-center align-items-center'
																						key={i}>
																						<Card className='border border-danger'>
																							<div style={{fontSize: "22px"}}>
																								{Globals.language.cart.agentSelected[lang]} :{" "}
																								<i class='fa fa-user'></i> {element.name}{" "}
																								{element.surname}
																							</div>
																						</Card>
																					</div>
																				);
																			})}

																			<div className='d-flex justify-content-end align-items-center responsive-btn-cart'>
																				<div className='d-flex flex-end p-2 mx-1 justify-content-end'>
																					<button
																						className='btn btn-danger btn-md text-light'
																						style={{backgroundColor: "#d50037"}}
																						onClick={() => resetCart()}>
																						{Globals.language.cart.buttonEmpty[lang]}
																					</button>
																				</div>
																				{item.category === "Congresso" ? (
																					<div className='d-flex flex-end mx-1 justify-content-end'>
																						<button
																							className='btn btn-warning btn-md text-dark'
																							onClick={() => navigate("/ticket/" + item.id)}>
																							{Globals.language.cart.buttonModify[lang]}
																						</button>
																					</div>
																				) : (
																					<></>
																				)}
																			</div>
																			{item.additional_notes !== "" &&
																			item.additional_notes !== undefined &&
																			item.additional_notes !== null ? (
																				<div className='py-3'>
																					<Card className='d-flex justify-content-center align-items-center mx-auto py-2 shadow shadow-danger'>
																						<h3>{Globals.language.cart.additionaNotesCart[lang]}</h3>
																						<h4>{item.additional_notes}</h4>
																					</Card>
																				</div>
																			) : (
																				<></>
																			)}

																			{item.active_note_extra && item.active_note_extra !== "0" ? (
																				<div className='py-3'>
																					<Card className='d-flex justify-content-center align-items-center mx-auto py-2 shadow shadow-danger'>
																						<h3>{item.title_note}</h3>
																						<h4>{parse(item.desc_note)}</h4>
																					</Card>
																				</div>
																			) : (
																				<></>
																			)}
																		</div>
																	);
																})
															)}

															{Object.keys(cartItems).length !== 0 ? (
																<>
																	<hr className='my-4' />
																	<div className='d-flex flex-row-reverse mx-1'>
																		<p className='h2 text-secondary'>
																			{Globals.language.cart.total[lang]}: {""}
																			{public_administration.is_public_administration !== "0" &&
																			auth?.accessToken ? (
																				<span className='mx-2 h1 ' style={{color: "#d50037"}}>
																					{Intl.NumberFormat("de-DE", {
																						style: "currency",
																						currency: "EUR",
																					}).format(totalPrice)}
																					{/* <span className="mx-1" style={{fontSize:"20px"}}>iva inclusa</span> */}

																					{parseInt(totalPrice) === 0 ? (
																						<span></span>
																					) : (
																						<span className='mx-1' style={{fontSize: "15px"}}>
																							{Globals.language.cart.vatExempt[lang]}.{" "}
																						</span>
																					)}
																				</span>
																			) : (
																				<div>
																					<span className='mx-2 h1 ' style={{color: "#d50037"}}>
																						{Intl.NumberFormat("de-DE", {
																							style: "currency",
																							currency: "EUR",
																						}).format((totalPrice / 100) * 122)}
																						{/* <span className="mx-1" style={{fontSize:"20px"}}>iva inclusa</span> */}

																						{parseInt(totalPrice) === 0 ? (
																							<span></span>
																						) : (
																							<span className='mx-1' style={{fontSize: "15px"}}>
																								{Globals.language.cart.vatIncl[lang]}.{" "}
																							</span>
																						)}
																					</span>
																				</div>
																			)}
																			{coupon?.active === true &&
																			parseInt(coupon?.congress_id) ===
																				parseInt(cartItems[0].id) ? (
																				<>
																					<div className='row align-items-center d-flex'>
																						<div className='col-md-2 col-sm-12'>
																							<img src={couponImage} width={50} />{" "}
																						</div>
																						<div
																							className='col-md-10 col-sm-12 text-success mt-1'
																							style={{fontSize: "22px"}}>
																							{Globals.language.cart.label_coupon[lang]}
																						</div>
																					</div>
																				</>
																			) : (
																				<></>
																			)}
																		</p>
																	</div>
																	<Form>
																		<Form.Group
																			className='mb-3 text-start'
																			controlId='formBasicCheckbox'>
																			<Form.Check
																				type='checkbox'
																				label={
																					<span>
																						{Globals.language.cart.condVend[lang]} {""}
																						<Link to='/condizioni-vendita'>
																							{Globals.language.cart.condVendSecond[lang]}{" "}
																						</Link>
																					</span>
																				}
																				checked={condizioni}
																				onChange={handleOnChange}
																				required
																			/>
																		</Form.Group>
																		<Form.Group
																			className='mb-3 text-start'
																			controlId='formBasicCheckbox'>
																			<Form.Check
																				type='checkbox'
																				label={Globals.language.cart.clausole[lang]}
																				checked={clausole}
																				onChange={handleOnChangeClausole}
																				required
																			/>
																		</Form.Group>
																	</Form>

																	{Object.keys(auth).length !== 0 ? (
																		<div className='d-flex justify-content-center'>
																			<ChoosePayment
																				gratis={parseFloat(totalPrice) === 0 ? true : false}
																				confirmCondizioni={condizioni}
																				confirmClausole={clausole}
																			/>
																		</div>
																	) : (
																		<div className='d-flex justify-content-center'>
																			<Login OnlyForm={true} />
																		</div>
																	)}
																</>
															) : (
																<div className='pt-3'>
																	<h6 className='mb-0'>
																		<Link to='/home' className='text-body'>
																			<button
																				className='btn btn-danger btn-lg'
																				style={{backgroundColor: "#d50037"}}>
																				{Globals.language.cart.backHome[lang]}
																			</button>
																		</Link>
																	</h6>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					) : (
						<div>
							<div
								className='container'
								style={{marginTop: "140px", marginBottom: "200px"}}>
								<Card className='d-flex justify-content-center align-items-center mx-auto py-2'>
									<Card.Header>
										<h1>{Globals.language.cart.closeCartTitle[lang]}</h1>
									</Card.Header>
									<Card.Body>
										<Card.Title>
											<h3>
												{Globals.language.cart.from[lang]} {formatDay(item.start_date)}-
												{formatMonth(item.start_date)}-{formatYear(item.start_date)}{" "}
												{Globals.language.cart.at[lang]} {formatDay(item.end_date)}-
												{formatMonth(item.end_date)}-{formatYear(item.end_date)}
											</h3>
										</Card.Title>
										<Card.Text>
											<h2>{item.message}</h2>
										</Card.Text>
										<Link to='/home'>
											<Button variant='danger' className='btn btn-lg'>
												{Globals.language.cart.backHome[lang]}
											</Button>
										</Link>
									</Card.Body>
								</Card>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	);
}

export default Cart;
