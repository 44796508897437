import React, { useEffect, useState } from "react";
import CardObject from "../../components/CardObject";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import axios from "axios";
import Globals from "../../parameter/globals";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
//IMPORTARE LIBRERIE DI Autenticazione, per ottenere l'accesstoken

function ActiveRegistration() {
	//variabili di caricamento
	const [loading, setLoading] = useState(true);
	const [error, setErrMsg] = useState("");
	const [message, setMessage] = useState("");

	const [congress, setCongress] = useState([]);
	const [events, setEvents] = useState([]);

	const { auth } = useAuth();
	const { lang } = useContext(AuthContext);

	//creazione della modal

	//gestione errori server

	//api di caricamento dei contenuti pre e post data di fine

	const getMyCongressAndEventSubscribe = async () => {
		try {
			setLoading(true);
			let body = {
				access_token: auth.accessToken,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETMYEVENTANDMYCONGRESS + "&lang=" + lang,
				formdata,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				}
			);

			if (response?.data?.error === true) {
				setLoading(false);
				setErrMsg(response.data.message);
			} else {
				setCongress(response.data.congress);
				setEvents(response.data.events);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	useEffect(() => {
		getMyCongressAndEventSubscribe();
	}, [lang]);

	// caricamento delle card con caricamento della relativa modal

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div
				className='container'
				style={{ marginTop: "150px", marginBottom: "200px" }}>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{Globals.language.title.eventAndCongressRegistration[lang]}</title>
					<meta name="robots" content="noindex" />
				</Helmet>

				<h1> {Globals.language.activeRegistration.title[lang]} </h1>

				{congress.length > 0 && (
					<div>
						{" "}
						<h2 className='title-homepage-all text-center'>
							{" "}
							{Globals.language.activeRegistration.congress[lang]}{" "}
						</h2>
						<div className='o-badge'>
							<div className='o-badge__group'>
								{congress.map((item) => (
									<div>
										<CardObject element={item} />{" "}
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				{events.length > 0 && (
					<div>
						{" "}
						<h2 className='title-homepage-all text-center'>
							{" "}
							{Globals.language.activeRegistration.events[lang]}{" "}
						</h2>
						<div className='o-badge'>
							<div className='o-badge__group'>
								{events.map((item) => (
									<article>
										{" "}
										<CardObject element={item} />{" "}
									</article>
								))}
							</div>
						</div>
					</div>
				)}

				{congress.length === 0 && events.length === 0 && (
					<article>
						{" "}
						{Globals.language.activeRegistration.notSubscription[lang]}{" "}
					</article>
				)}
				<p> {error !== "" && error} </p>
			</div>
		);
	}
}

export default ActiveRegistration;
