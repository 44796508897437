import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../context/AuthProvider";
import Globals from "../parameter/globals";

function InfoModal(props) {
	const { lang } = useContext(AuthContext);

	return (
		<Modal
			{...props}
			aria-labelledby='contained-modal-title-vcenter'
			centered
			style={{ zIndex: "9999" }}>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{" "}
					{props.action}{" "}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.text}</Modal.Body>
			<Modal.Footer>
				<Button variant='success' onClick={props.onConfirm}>
					{Globals.language.infoModal.confirm[lang]}
				</Button>
				<Button variant='danger' onClick={props.onHide}>
					{Globals.language.infoModal.cancel[lang]}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default InfoModal;
