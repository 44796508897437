import React, {useState, useEffect, useContext} from "react";
import {useLocation, useParams, useNavigate} from "react-router-dom";
import Globals from "../../parameter/globals";
import axios from "axios";
import CartContext from "../../context/CartProvider";
import Loading from "../../components/Loading";
import "../../scss/ticket.scss";
import {Card} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import InfoModal from "../../components/InfoModal";
import {Helmet} from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import ModalCoupon from "../../components/ModalCoupon";
import couponImage from "../../images/coupon.png";

function TicketService() {
	let {id} = useParams();
	const location = useLocation();
	const {lang} = useContext(AuthContext);

	const {onAdd, cartItems, resetSession} = useContext(CartContext);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [noEng, setNoEng] = useState(false);

	const [showModalCoupon, setShowModalCoupon] = useState(false);
	const [startCouponProcedure, setStartCouponProcedure] = useState(false);

	const [congress, setCongress] = useState({
		id: "",
		address: "",
		city: "",
		description: "",
		name: "",
		start_date: "",
		end_date: "",
		img_url: "",
		price: "",
		congress_added: [],
		ticket_category: [],
		category: "Congresso",
		short_description: "",
		active_note_extra: "",
		title_note: "",
		desc_note: "",
		limit: false,
		tickets: false,
		additional_notes: "",
		agents: [],
		coupon: false,
		sponsor: [],
	});

	//disposizione dei servizi/biglietti come lista selezionabile
	const [service, setService] = useState([]);
	const [multipleTickets, setMultipleTickets] = useState([]);

	const [agents, setAgents] = useState([]);
	const [addedAgent, setAddedAgent] = useState([]);

	//array destinati ad essere riempiti con i valori selezionati al momento
	const [addedService, setAddedService] = useState([]);
	const [addedMultipleTicket, setAddedMultipleTicket] = useState([]);

	//array destinati ad essere riempiti con i valori preselezionati dall'istanza precedente (session)
	const [alreadySetted, setAlreadySetted] = useState([]);
	const [alreadyMultipleTicket, setAlreadyMultipleTicket] = useState([]);
	const [alreadyAgents, setAlreadyAgents] = useState([]);

	useEffect(() => {
		setLoading(true);
		if (noEng) {
			alert("This congress is not available in english.");
			navigate("/home");
			setLoading(false);
		} else {
			getCongress();
		}
	}, [location, lang]);

	useEffect(() => {
		setCongress({...congress, congress_added: addedService});
	}, [addedService]);

	useEffect(() => {
		setCongress({...congress, ticket_category: addedMultipleTicket});
	}, [addedMultipleTicket]);

	useEffect(() => {
		setCongress({...congress, agents: addedAgent});
	}, [addedAgent]);

	const escludePrevService = (element) => {
		setAddedService(
			addedService.filter(
				(item) => item.id_service_category !== element.id_service_category,
			),
		);
		setAddedService((addedService) => [...addedService, element]);
	};

	const escludeAgents = (element) => {
		setAddedAgent([element]);
	};

	const escludePrevTicket = (element) => {
		setAddedMultipleTicket(
			addedMultipleTicket.filter(
				(item) => parseInt(item.id_congress) !== parseInt(element.id_congress),
			),
		);

		setAddedMultipleTicket((addedMultipleTicket) => [
			...addedMultipleTicket,
			element,
		]);
	};

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		return month;
	};

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({top: 0, left: 0, behavior: "smooth"});
		}, 200);
	}, [location.pathname]);
	const getCongress = async () => {
		let cartStorage = (await cartItems[0]) === undefined ? false : cartItems[0];

		try {
			let body = {
				id: id,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETTICKETDETAILS + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
				setLoading(false);
			} else {
				setCongress({
					...congress,
					id: response?.data?.congress?.id,
					address: response?.data?.congress?.address,
					city: response?.data?.congress?.city,
					description: response?.data?.congress?.description,
					name: response?.data?.congress?.name,
					start_date: response?.data?.congress?.start_date,
					end_date: response?.data?.congress?.end_date,
					img_url: response?.data?.image,
					price: parseFloat(response?.data?.congress?.price),
					short_description: response?.data?.congress?.short_description,
					active_note_extra: response?.data?.congress?.active_note_extra,
					title_note: response?.data?.congress?.title_note,
					desc_note: response?.data?.congress?.desc_note,
					limit: response?.data?.congress?.limit,
					tickets: response?.data?.congress?.multiple_ticket,

					congress_added:
						parseInt(cartStorage.id) === parseInt(id)
							? cartStorage.congress_added
							: [],
					ticket_category:
						parseInt(cartStorage.id) === parseInt(id)
							? cartStorage.ticket_category
							: [],
					agents:
						parseInt(cartStorage.id) === parseInt(id) ? cartStorage.agents : [],
					additional_notes:
						parseInt(cartStorage.id) === parseInt(id)
							? cartStorage.additional_notes
							: "",

					coupon: response?.data?.coupon,
					sponsor: response?.data?.sponsor,
				});

				setService(response?.data?.services);
				setMultipleTickets(response?.data?.tickets);
				setAgents(response?.data?.agents);
				setNoEng(parseInt(response?.data?.congress?.lang_en) === 1 ? false : true);

				setLoading(false);
				window.scrollTo({top: 0, left: 0, behavior: "smooth"});
			}
		} catch (err) {
			setError(true);
			setMessage(err);

			setLoading(false);
		}

		if (cartStorage !== false) {
			if (parseInt(cartStorage.id) === parseInt(id)) {
				setAlreadySetted(cartStorage.congress_added);
				setAddedService(cartStorage.congress_added);

				setAlreadyMultipleTicket(cartStorage.ticket_category);
				setAddedMultipleTicket(cartStorage.ticket_category);
				setAlreadyAgents(cartStorage.agents);
			}
		}
	};

	const toPaymentSummary = async (special = false) => {
		if (congress.tickets === true) {
			if (congress.ticket_category.length !== 0) {
				if ((await onAdd(congress, 1, special)) === true) {
					setLoading(false);

					navigate("/cart");
				} else {
					setLoading(false);
					setMessage(Globals.language.errorLabel.serverError[lang]);
					setError(true);

					return;
				}
			} else {
				setLoading(false);
				setMessage(Globals.language.errorLabel.selectTicket[lang]);
				setError(true);

				return;
			}
		} else {
			if ((await onAdd(congress, 1, special)) === true) {
				setLoading(false);
				navigate("/cart");
			} else {
				setLoading(false);
				setMessage(Globals.language.errorLabel.serverError[lang]);
				setError(true);
				return;
			}
		}
	};

	const toSpecialSummary = async (id_quote) => {
		setLoading(true);

		let findQuote = multipleTickets.filter(
			(item) => parseInt(item.id) === parseInt(id_quote),
		);

		setCongress({...congress, ticket_category: findQuote});

		setStartCouponProcedure(true);
	};

	useEffect(() => {
		if (startCouponProcedure) {
			toPaymentSummary(true);
		}
	}, [startCouponProcedure]);

	const deleteChoices = () => {
		setAddedMultipleTicket([]);
		setAddedService([]);
		setAlreadyMultipleTicket([]);
		setAlreadyAgents([]);
		setAlreadySetted([]);

		resetSession();

		window.location.reload();
	};

	if (loading) {
		return <Loading />;
	} else {
		return (
			<div className='container' style={{marginBottom: "150px"}}>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{Globals.language.title.serviceAdded[lang]}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<article className='div-general-ticket' style={{marginTop: "150px"}}>
					<Card className='py-2 mx-1'>
						<h1>{congress.name}</h1>
					</Card>
					<div className='d-flex justify-content-center align-items-center py-5 responsive-services-container'>
						<div className='col-lg-7 col-md-6 col-sm-12'>
							<img
								src={congress.img_url}
								className='col-lg-5 col-md-5 col-sm-5 img-responsive justify-content-center image-card-ticket'
								alt='...'
							/>
						</div>
						<div className='col-lg-5 col-md-6 col-sm-12 responsive-description'>
							<div className='mx-2 div-top-align-title'>
								<div className='' style={{borderBottom: "1px solid black"}}>
									<h2 className='text-danger text-uppercase title-tickets'>
										{congress.name}{" "}
									</h2>
								</div>
								<p className='text-secondary py-1'>
									{Globals.language.ticketService.startDate[lang]}{" "}
									<span className='text-dark mx-3'>
										{formatDay(congress.start_date)}-{formatMonth(congress.start_date)}-
										{formatYear(congress.start_date)}
									</span>{" "}
								</p>
								<p className='text-secondary py-1'>
									{Globals.language.ticketService.endDate[lang]}{" "}
									<span className='text-dark mx-3'>
										{formatDay(congress.end_date)}-{formatMonth(congress.end_date)}-
										{formatYear(congress.end_date)}
									</span>{" "}
								</p>
								<p className='text-secondary py-1'>
									{Globals.language.ticketService.luogo[lang]}:{" "}
									<span className='text-dark mx-3'>
										{congress.address} - {congress.city}
									</span>{" "}
								</p>
								{congress.tickets === false && (
									<p
										className='text-dark py-1  text-light text-center w-50 mx-auto'
										style={{borderBottom: "1px solid #d50037"}}>
										{Globals.language.ticketService.priceCongress[lang]}:{" "}
										<span className='text-dark mx-1'>
											{congress.price} €{" "}
											{parseInt(congress.price) === 0 ? (
												<span></span>
											) : (
												<span className='mx-1' style={{fontSize: "15px"}}>
													+ {Globals.language.ticketService.vat[lang]}. .{" "}
												</span>
											)}
										</span>{" "}
									</p>
								)}
							</div>
						</div>
					</div>
				</article>
				{congress.tickets === true && (
					<Table responsive className='ticket-responsive-table'>
						<div className='responsive-hr my-2'>
							<thead>
								<tr>
									<th width={"33%"} className='border-0 th-custom p-0'>
										<div className='principal-div-servizi'>
											<div className='p-2 text-center border-0 text-light categoria-bg '>
												{Globals.language.ticketService.selectTicket[lang]}
											</div>
										</div>
									</th>
								</tr>
								<tr style={{borderTop: "1px solid #d50037"}}>
									<th
										width={"20%"}
										className='border-0 text-center remove-th-responsive'></th>
									<th
										width={"40%"}
										style={{minWidth: "550px"}}
										className='border-0 text-center remove-th-responsive'>
										{Globals.language.ticketService.descrizione[lang]}
									</th>
									<th
										width={"40%"}
										className='border-0 text-center remove-th-responsive'>
										{Globals.language.ticketService.price[lang]}
									</th>
								</tr>
							</thead>
							{multipleTickets.map((element, index) => {
								return (
									<tbody key={index} responsive>
										<tr style={{opacity: element.correct_date ? 0.3 : 1}}>
											<td className='text-center'>
												<input
													type='radio'
													name='ticket'
													onClick={(e) => escludePrevTicket(element)}
													defaultChecked={
														alreadyMultipleTicket.find(
															(i) => parseInt(i.id) === parseInt(element.id),
														)
															? true
															: false
													}
													disabled={element.correct_date}
												/>
											</td>
											<td
												data-th={Globals.language.ticketService.descrizione[lang]}
												className='text-center'>
												{" "}
												<div style={{backgroundColor: "#eeecea"}}>
													<div style={{backgroundColor: "#e7c3bb", minWidth: "350px"}}>
														{element.name}
													</div>

													{element.description !== "" ? (
														<>
															<span> {element.description} </span>
														</>
													) : (
														""
													)}
												</div>
											</td>
											<td data-th='Prezzo' className='text-center'>
												{Intl.NumberFormat("de-DE", {
													style: "currency",
													currency: "EUR",
												}).format(element.price)}{" "}
												{parseInt(element.price) === 0 ? (
													<span></span>
												) : (
													<span className='mx-1' style={{fontSize: "15px"}}>
														+ {Globals.language.ticketService.vat[lang]}.{" "}
													</span>
												)}
											</td>
										</tr>
									</tbody>
								);
							})}
						</div>
					</Table>
				)}

				{service?.length > 0 ? (
					<div></div>
				) : (
					<div>
						<div className='py-3'>
							<Card className='d-flex justify-content-center align-items-center mx-auto py-2'>
								<h4>{Globals.language.ticketService.noServices[lang]}</h4>
							</Card>
						</div>
					</div>
				)}
				{service?.map((item, key, array) => {
					return (
						<Table responsive key={key}>
							<thead>
								<tr>
									<th width={"33%"} className='border-0 th-custom p-0'>
										<div className='principal-div-servizi'>
											<div className='p-2 text-center border-0 text-light categoria-bg'>
												{item[0].category}
											</div>
										</div>
									</th>
								</tr>
								<tr style={{borderTop: "1px solid #d50037"}}>
									<th width={"10%"} className='border-0 text-center'></th>
									<th width={"20%"} className='border-0 text-center'>
										{Globals.language.ticketService.descrizione[lang]}
									</th>
									<th width={"20%"} className='border-0 text-center'>
										{Globals.language.ticketService.price[lang]}
									</th>
									<th width={"10%"} className='border-0 text-center'>
										{Globals.language.ticketService.quantita[lang]}
									</th>
									<th width={"40%"} className='border-0 text-center'>
										{item.find((element) => element.start_time !== "")
											? Globals.language.ticketService.time[lang]
											: ""}
									</th>
								</tr>
							</thead>
							{item[0].is_esclusive === "1" &&
								item.map((element, index) => {
									return (
										<tbody key={index}>
											<tr style={{opacity: element.disabled === true ? 0.2 : 1}}>
												<td className='text-center'>
													<input
														type='radio'
														name={item[0].category}
														onChange={(e) => escludePrevService(element)}
														defaultChecked={
															alreadySetted.find(
																(i) => parseInt(i.id) === parseInt(element.id),
															)
																? true
																: false
														}
														disabled={element.disabled}
													/>
												</td>
												<td className='text-center'> {element.name} </td>
												<td className='text-center'>
													{Intl.NumberFormat("de-DE", {
														style: "currency",
														currency: "EUR",
													}).format(element.price)}{" "}
													{/* <span>iva compresa</span> */}
													{parseInt(element.price) === 0 ? (
														<span></span>
													) : (
														<span className='mx-1' style={{fontSize: "15px"}}>
															+ {Globals.language.ticketService.vat[lang]}.{" "}
														</span>
													)}
												</td>
												<td className='text-center'>
													{" "}
													<input
														disabled
														className={"text-center"}
														value={
															element.disabled === true
																? Globals.language.ticketService.noDisponibile[lang]
																: 1
														}
													/>{" "}
												</td>
												<td className='text-center'>
													{" "}
													{element.start_time && element.end_time !== "" ? (
														<div>
															{" "}
															{Globals.language.ticketService.start[lang]}:{" "}
															<strong>{element.start_time}</strong> <br></br>{" "}
															{Globals.language.ticketService.end[lang]}:{" "}
															<strong>{element.end_time}</strong>
														</div>
													) : (
														<div></div>
													)}
												</td>
											</tr>
										</tbody>
									);
								})}

							{item[0].is_esclusive === "0" &&
								item.map((element, index) => {
									return (
										<tbody key={index}>
											<tr style={{opacity: element.disabled === true ? 0.2 : 1}}>
												<td className='text-center'>
													<input
														type='checkbox'
														onChange={(e) =>
															e.target.checked
																? setAddedService((addedService) => [...addedService, element])
																: setAddedService(
																		addedService.filter((item) => item.id !== element.id),
																  )
														}
														defaultChecked={
															alreadySetted.find(
																(i) => parseInt(i.id) === parseInt(element.id),
															)
																? true
																: false
														}
														disabled={element.disabled}
													/>
												</td>
												<td className='text-center'> {element.name} </td>
												<td className='text-center'>
													{Intl.NumberFormat("de-DE", {
														style: "currency",
														currency: "EUR",
													}).format(element.price)}{" "}
													{/* <span>iva compresa</span> */}
													{parseInt(element.price) === 0 ? (
														<span></span>
													) : (
														<span className='mx-1' style={{fontSize: "15px"}}>
															+ {Globals.language.ticketService.vat[lang]} .{" "}
														</span>
													)}
												</td>
												<td className='text-center'>
													{" "}
													<input
														disabled
														className={"text-center"}
														value={element.disabled === true ? "Non disponibile " : 1}
													/>{" "}
												</td>
												<td className='text-center'>
													{" "}
													{element.start_time && element.end_time !== "" ? (
														<div>
															{" "}
															{Globals.language.ticketService.start[lang]}:{" "}
															<strong>{element.start_time}</strong> <br></br>
															{Globals.language.ticketService.end[lang]}:{" "}
															<strong>{element.end_time}</strong>
														</div>
													) : (
														<div></div>
													)}
												</td>
											</tr>
										</tbody>
									);
								})}
						</Table>
					);
				})}

				{/* {agents?.length > 0 ? (
					<div></div>
				) : (
					<div>
						<div className='py-3'>
							<Card className='d-flex justify-content-center align-items-center mx-auto py-2'>
								<h4>Non sono presenti agenti</h4>
							</Card>
						</div>
					</div>
				)} */}

				<div className='py-3'>
					{agents.length !== 0 ? (
						<div className='py-3'>
							<h2>{Globals.language.ticketService.selectAgents[lang]} </h2>
						</div>
					) : (
						<div></div>
					)}
					<section className='agents-btn'>
						{agents.length !== 0 &&
							agents.map((element, index) => {
								return (
									<div className='section-div' key={index}>
										<input
											className='radio-btn'
											type='radio'
											name='select'
											id={element.id}
											onChange={(e) => escludeAgents(element)}
											defaultChecked={
												alreadyAgents.find((i) => parseInt(i.id) === parseInt(element.id))
													? true
													: false
											}
										/>
										<label className='label-agents' htmlFor={element.id}>
											<h2>{element.name}</h2>
											<p>{element.surname}</p>
										</label>
									</div>
								);
							})}
					</section>
				</div>

				<div className='py-3'>
					<Card className='d-flex justify-content-center align-items-center mx-auto py-2 shadow shadow-danger'>
						<label for='noteTextBox'>
							{Globals.language.ticketService.additionalNotes[lang]}
						</label>
						<textarea
							className='form-control'
							id='noteTextBox'
							value={congress.additional_notes}
							onChange={(e) =>
								setCongress({...congress, additional_notes: e.target.value})
							}
							rows='5'></textarea>
					</Card>
				</div>

				{congress.coupon && (
					<div className='py-3'>
						<Card className='d-flex justify-content-center align-items-center mx-auto py-2 bg-none border-0 shadow-none'>
							<button
								className='btn btn-outline-danger rounded-0 btn-lg float-end'
								onClick={() => setShowModalCoupon(true)}>
								<div className='row'>
									<div className='col-md-2 col-sm-12'>
										<img src={couponImage} width={50} />{" "}
									</div>
									<div className='col-md-10 col-sm-12'>
										{Globals.language.ticketService.couponButton[lang]}{" "}
									</div>
								</div>
							</button>
						</Card>
					</div>
				)}

				{congress.limit === true ? (
					<span>{Globals.language.ticketService.notBuy[lang]} </span>
				) : (
					<>
						<button
							className='btn btn-danger rounded-0 btn-lg float-end text-light btn-conferma-ticket '
							onClick={() => toPaymentSummary()}>
							{Globals.language.ticketService.buttonConferma[lang]}{" "}
						</button>
						<button
							className='btn btn-warning rounded-0 btn-lg text-light float-end mx-2'
							onClick={() => deleteChoices()}>
							{Globals.language.ticketService.buttonBack[lang]}{" "}
						</button>
					</>
				)}

				<InfoModal
					show={error}
					onHide={() => setError(false)}
					onConfirm={() => setError(false)}
					action={Globals.language.ticketService.attention[lang]}
					text={message}
				/>

				<ModalCoupon
					show={showModalCoupon}
					onHide={() => setShowModalCoupon(false)}
					onConfirm={() => setShowModalCoupon(false)}
					action={Globals.language.ticketService.titleModalCoupon[lang]}
					sponsor={congress.sponsor}
					methodPayment={(controlPassed) => toSpecialSummary(controlPassed)}
					congressId={congress.id}
				/>
			</div>
		);
	}
}

export default TicketService;
