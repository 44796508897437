import { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	let location = useLocation();

	let languageFromStorage =
		localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : "it";

	if (location.hash.includes("eng") === true) {
		languageFromStorage = "en";
	}

	const [auth, setAuth] = useState({});
	const [lang, setLang] = useState(languageFromStorage);

	return (
		<AuthContext.Provider value={{ auth, setAuth, lang, setLang }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
