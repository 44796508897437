import {useRef, useState, useEffect} from "react";
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../scss/register.scss";
import Globals from "../../parameter/globals";
import PasswordStrengthBar from "react-password-strength-bar";
import Contact from "../../components/Contact";
import {Link, useNavigate} from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {Helmet} from "react-helmet";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import Select from "react-select";
import {toast} from "react-hot-toast";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();
    const {lang} = useContext(AuthContext);
    const navigate = useNavigate();


    const [user, setUser] = useState("");
    const [validName, setValidName] = useState(false);

    const [pwd, setPwd] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [pubblicaAmministrazione, SetpubblicaAmministrazione] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [birthPlace, setBirthPlace] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [company, setCompany] = useState("");
    const [province, setProvince] = useState("");
    const [vatCode, setVatCode] = useState("");
    const [address, setAddress] = useState("");
    const [fiscalCode, setFiscalCode] = useState("");

    const [listNation, setListNation] = useState([]);
    const [selectNation, setSelectNation] = useState("");
    const [nation, setNation] = useState("");

    const [medicalSpecialty, setMedicalSpecialty] = useState("");

    const [fiscalCodeCompany, setFiscalCodeCompany] = useState("");
    const [sdi, setSdi] = useState("");
    const [pec, setPec] = useState("");
    const [cap, setCap] = useState("");
    const [city, setCity] = useState("");

    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [contact, setContact] = useState(false);
    const [key, setKey] = useState("private");
    const seeContact = () => {
        setContact(!contact);
    };

    const handleOnChange = () => {
        SetpubblicaAmministrazione(!pubblicaAmministrazione);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConfirmPassword = () => {
        setPasswordConfirmShown(!passwordConfirmShown);
    };

    useEffect(() => {
        userRef.current.focus();
        getNation();
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setErrMsg("");
    }, [user, pwd, matchPwd]);

    // function submitForm(e) {
    // 	e.preventDefault();
    // }

    const getNation = async () => {
        try {
            const response = await axios.get(Globals.NATIONAPI, {
                headers: {"Content-Type": "application/json"},
                withCredentials: true,
            });

            let objectNation = [];

            response?.data?.forEach((element) => {
                let t = {
                    label:
                        lang === "it" ? element.translations.ita.common : element.name.common,
                    value:
                        lang === "it" ? element.translations.ita.common : element.name.common,
                };

                objectNation = [...objectNation, t];
            });

            setListNation(objectNation);
        } catch (error) {
            console.log(error);
            setListNation([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailCheck = email;

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailCheck)) {
            setErrMsg(Globals.language.register.errorEmail[lang]);
            return;
        }

        //tolgo temporaneramente il controllo sul codice fiscale
        // const checkFiscalCode = fiscalCode;
        // if (
        // 	!/^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/.test(
        // 		checkFiscalCode
        // 	)
        // ) {
        // 	setErrMsg(Globals.language.register.errorFiscalCode[lang]);
        // 	return;
        // }
        const checkFiscalCode = fiscalCode;
        if (checkFiscalCode === " ") {
            setErrMsg(Globals.language.register.errorFiscalCodeBlank[lang]);
            return;
        }

        const checkPiva = vatCode;
        if (key === "company") {
            if (!/^[0-9]{11}$/.test(checkPiva)) {
                setErrMsg(Globals.language.register.errorPiva[lang]);
                return;
            }
        }
        const v1 = pwd;
        const v2 = matchPwd;
        if (v1 !== v2) {
            setErrMsg(Globals.language.register.errorPassword[lang]);
            return;
        }
        if (key === "company") {
            const l1 = pec;
            const l2 = sdi;
            if ((l1 || l2) === "") {
                setErrMsg(Globals.language.register.errorCompany[lang]);
                return;
            }
        }

        // if (
        // 	selectNation === "" ||
        // 	selectNation === null ||
        // 	selectNation === undefined
        // ) {
        // 	setErrMsg(Globals.language.register.errorCountyNotSelected[lang]);
        // 	return;
        // }

        try {
            let body = {
                user: email,
                password: pwd,
                name: name,
                surname: surname,
                email: email,
                phoneNumber: phoneNumber,
                company: company,
                vatCode: vatCode,
                address: address,
                fiscalCode: fiscalCode !== "" ? fiscalCode : "Non inserito",
                birthPlace: birthPlace,
                dateOfBirth: dateOfBirth,
                cap: cap,
                city: city,
                sdiCode: sdi,
                pec: pec,
                fiscalCompanyCode: fiscalCodeCompany,
                isCompany: key === "private" ? 0 : 1,
                province: province,
                pubblicaAmministrazione: pubblicaAmministrazione === true ? 1 : 0,
                medical_specialty: medicalSpecialty,
                country: nation,
                // country: selectNation.value,
            };

            let formdata = new FormData();

            formdata.append("body", JSON.stringify(body));

            const response = await axios.post(
                Globals.REGISTER + "&lang=" + lang,
                formdata,
                {
                    headers: {"Content-Type": "application/json"},
                    withCredentials: true,
                },
            );
            // TODO: remove console.logs before deployment
            // console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))

            if (response?.data?.error === true) {
                setErrMsg(response.data.message);
            } else {
                //clear state and controlled inputs
                setUser("");
                setPwd("");
                setMatchPwd("");
                setSuccess(true);
                alert(`${Globals.language.toastMessage.accountCreated[lang]} ${user}.`);
                toast.success(`${Globals.language.toastMessage.registerAndGo[lang]} ${user}.`);
                navigate("/login");


            }
        } catch (err) {
            setErrMsg(Globals.language.errorLabel.serverError[lang]);
            errRef.current.focus();
        }
    };

    return (
        <div
            className='container p-5 responsive-div-mobile'
            style={{textAlign: "center", marginTop: "120px", marginBottom: "50px"}}>
            <Helmet>
                <meta charSet='utf-8'/>
                <title>{Globals.language.title.registration[lang]}</title>
                <link rel='canonical' href='https://www.soluzioniomniamedia.com/register'/>
                <meta name="robots" content="noindex"/>
            </Helmet>
            {success ? (
                <div
                    className='container-fluid text-dark py-5'
                    style={{
                        marginTop: "180px",
                        backgroundColor: "#e9ecef",
                        marginBottom: "200px",
                    }}>
                    <h1 className='display-3'>{Globals.language.register.confirmReg[lang]}</h1>
                    <p className='lead'>
                        <strong>{Globals.language.register.confirmRegFirst[lang]}</strong>{" "}
                        {Globals.language.register.confirmRegSecond[lang]}
                        <p className='mt-3 fs-6'>
                            {Globals.language.register.confirmRegForth[lang]}
                        </p>
                    </p>
                    <hr/>
                    <p>
                        {Globals.language.register.confirmRegThird[lang]}{" "}
                        <button
                            className='btn btn-secondary btn-sm mx-2 py-2'
                            onClick={seeContact}>
                            {Globals.language.register.confirmRegContact[lang]}
                        </button>
                        {contact ? (
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-sm text-center'>
                                        <h1 className='div-heading display-4'>
                                            {Globals.language.register.confirmRegContact[lang]}
                                        </h1>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 mx-auto text-start'>
                                        <Contact general={false} from={"Registrazione"}/>
                                    </div>
                                </div>
                            </div>

                        ) : (
                            <div>
                                <p className='py-2'>
                                    {Globals.language.register.confirmRegContactSecond[lang]}
                                </p>
                            </div>
                        )}
                    </p>
                    <p className='lead'>
                        <a className='btn btn-danger btn-lg' href='/' role='button'>
                            {Globals.language.register.confirmRegContactHomepage[lang]}
                        </a>
                    </p>
                </div>
            ) : (
                <div className='registration-page bg-none py-auto'>
                    <div className='container-fluid my-5'>
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            id='justify-tab-example'
                            className='mb-3 border'
                            justify>
                            <Tab
                                eventKey='private'
                                title={Globals.language.register.accountPrivate[lang]}>
                                <div>
                                    <form onSubmit={handleSubmit}>
                                        <h2 className='text-center'>
                                            {Globals.language.register.privateRegister[lang]}
                                        </h2>
                                        <div className='row shadow-lg rounded'>
                                            <div className='col-sm-12 form-group'>
                                                <label htmlFor='email'>
                                                    {Globals.language.register.email[lang]}
                                                </label>
                                                <input
                                                    type='email'
                                                    id='email'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='email'
                                                    placeholder={Globals.language.register.emailPlaceholder[lang]}
                                                />
                                            </div>
                                            {/*				<div className='col-sm-12 my-2'>
												<label for='chb' className='form-check-label mx-2'>
													&nbsp; {Globals.language.register.publicAdministrationText[lang]}{" "}
												</label>
												<input
													type='checkbox'
													className=' d-inline'
													id='chb'
													checked={pubblicaAmministrazione}
													onChange={handleOnChange}
												/>
											</div>*/}
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='name'>{Globals.language.register.name[lang]}</label>
                                                <input
                                                    type='text'
                                                    id='name'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='name'
                                                    placeholder={Globals.language.register.namePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='username'>
                                                    {Globals.language.register.surname[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='surname'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setSurname(e.target.value)}
                                                    value={surname}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='surname'
                                                    placeholder={Globals.language.register.surnamePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='phoneNumber'>
                                                    {Globals.language.register.phone[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='phoneNumber'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='phoneNumber'
                                                    placeholder={Globals.language.register.phonePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='fiscalCode'>
                                                    {Globals.language.register.birtday[lang]}
                                                </label>
                                                <input
                                                    type='date'
                                                    id='fiscalCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                    value={dateOfBirth}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='fiscalCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.birtdayPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='birthPlace'>
                                                    {Globals.language.register.luogoNascita[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='birthPlace'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setBirthPlace(e.target.value)}
                                                    value={birthPlace}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='birthPlace'
                                                    className='form-control'
                                                    placeholder={
                                                        Globals.language.register.luogoNascitaPlaceholder[lang]
                                                    }
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='fiscalCode'>
                                                    {Globals.language.register.fiscalCode[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='fiscalCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setFiscalCode(e.target.value)}
                                                    value={fiscalCode}
                                                    required={lang === "it" ? true : false}
                                                    aria-describedby='uidnote'
                                                    name='fiscalCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.fiscalCodePlaceholder[lang]}
                                                />
                                            </div>

                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.indirizzo[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='vatCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    value={address}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.indirizzoPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.city[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='city'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setCity(e.target.value)}
                                                    value={city}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.cityPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.province[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='province'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setProvince(e.target.value)}
                                                    value={province}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.provincePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.cap[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='cap'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setCap(e.target.value)}
                                                    value={cap}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.capPlaceholder[lang]}
                                                />
                                            </div>

                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.Nation[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='cap'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setNation(e.target.value)}
                                                    value={nation}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.selectNationNew[lang]}
                                                />
                                            </div>

                                            {/* <div className='col-sm-6'>
												<div className='mb-4'>
													<label htmlFor='nation'>
														{Globals.language.register.Nation[lang]}
													</label>
													<Select
														options={listNation}
														isSearchable={true}
														isMulti={false}
														isClearable={true}
														placeholder={Globals.language.register.selectNation[lang]}
														onChange={(e) => {
															setSelectNation(e);
														}}
													/>
												</div>
											</div> */}

                                            <div className='col-sm-6'>
                                                <div className='mb-4'>
                                                    <label htmlFor='medicalSpecialty'>
                                                        {Globals.language.register.medicalSpecialty[lang]}
                                                    </label>
                                                    <input
                                                        type='text'
                                                        id='medicalSpecialty'
                                                        ref={userRef}
                                                        autoComplete='off'
                                                        onChange={(e) => setMedicalSpecialty(e.target.value)}
                                                        value={medicalSpecialty}
                                                        required
                                                        aria-describedby='uidnote'
                                                        name='medicalSpecialty'
                                                        className='form-control'
                                                        placeholder={
                                                            Globals.language.register.selectMedicalSpecialty[lang]
                                                        }
                                                        // disabled={sdi !== "" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <label htmlFor='password'>
                                                    Password<span className='text-danger'>*</span>
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-text'>
                                                        <i className='bi bi-lock-fill'></i>
                                                    </div>
                                                    <input
                                                        id='password'
                                                        onChange={(e) => setPwd(e.target.value)}
                                                        type={passwordShown ? "text" : "password"}
                                                        value={pwd}
                                                        required
                                                        aria-invalid={validPwd ? "false" : "true"}
                                                        aria-describedby='pwdnote'
                                                        onFocus={() => setPwdFocus(true)}
                                                        onBlur={() => setPwdFocus(false)}
                                                        name='password'
                                                        className='form-control'
                                                        placeholder={Globals.language.register.passwordPlaceholder[lang]}
                                                    />
                                                    <div
                                                        className='input-group-text '
                                                        style={{cursor: "pointer"}}
                                                        onClick={togglePassword}>
                                                        <i
                                                            className={passwordShown ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                                    </div>
                                                </div>
                                                <PasswordStrengthBar
                                                    scoreWords={[
                                                        Globals.language.errorPwLabel.weak[lang],
                                                        Globals.language.errorPwLabel.weak[lang],
                                                        Globals.language.errorPwLabel.average[lang],
                                                        Globals.language.errorPwLabel.good[lang],
                                                        Globals.language.errorPwLabel.strong[lang],
                                                    ]}
                                                    shortScoreWord={[Globals.language.errorPwLabel.tooWeak[lang]]}
                                                    password={pwd}
                                                />
                                            </div>

                                            <div className='col-sm-6'>
                                                <label htmlFor='password'>
                                                    {Globals.language.register.passwordConfirm[lang]}
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-text'>
                                                        <i className='bi bi-lock-fill'></i>
                                                    </div>
                                                    <input
                                                        type={passwordConfirmShown ? "text" : "password"}
                                                        id='confirm_pwd'
                                                        onChange={(e) => setMatchPwd(e.target.value)}
                                                        value={matchPwd}
                                                        required
                                                        aria-invalid={validMatch ? "false" : "true"}
                                                        aria-describedby='confirmnote'
                                                        onFocus={() => setMatchFocus(true)}
                                                        onBlur={() => setMatchFocus(false)}
                                                        name='confirm_pwd'
                                                        className='form-control'
                                                        placeholder={
                                                            Globals.language.register.passwordConfirmPlaceholder[lang]
                                                        }
                                                    />
                                                    <div
                                                        className='input-group-text '
                                                        style={{cursor: "pointer"}}
                                                        onClick={togglePassword}>
                                                        {/* <i
																	className={
																		passwordConfirmShown ? "bi bi-eye" : "bi bi-eye-slash"
																		
																	}></i> */}
                                                        <i
                                                            className={
                                                                passwordConfirmShown ? "bi bi-eye" : "bi bi-eye-slash"
                                                            }
                                                            onClick={toggleConfirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-sm-12 my-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check d-inline'
                                                    id='chb'
                                                    required
                                                />
                                                <label for='chb' className='form-check-label'>
                                                    &nbsp; {Globals.language.register.privacy[lang]}{" "}
                                                    <Link to='/privacy-policy'>
                                                        {Globals.language.register.privacySecond[lang]}{" "}
                                                    </Link>
                                                    {Globals.language.register.privacyDate[lang]}
                                                </label>
                                            </div>

                                            {errMsg !== "" ? (
                                                <div style={{color: "red", textAlign: "center"}}> {errMsg} </div>
                                            ) : (
                                                <></>
                                            )}

                                            <div className='col-sm-12 form-group mb-0'>
                                                <button
                                                    className='btn btn-danger float-right my-2'
                                                    onClick={() => handleSubmit()}>
                                                    {Globals.language.register.buttonIscriviti[lang]}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>
                            <Tab
                                eventKey='company'
                                title={Globals.language.register.accountCompany[lang]}>
                                <div className='div-container-form'>
                                    <form onSubmit={handleSubmit}>
                                        <h2 className='text-center'>
                                            {Globals.language.register.companyRegister[lang]}
                                        </h2>
                                        <div className='row shadow-lg rounded'>
                                            <div className='col-sm-12 form-group'>
                                                <label htmlFor='email'>
                                                    {Globals.language.register.email[lang]}
                                                </label>
                                                <input
                                                    type='email'
                                                    id='email'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='email'
                                                    placeholder={Globals.language.register.emailPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='name'>{Globals.language.register.name[lang]}</label>
                                                <input
                                                    type='text'
                                                    id='name'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='name'
                                                    placeholder={Globals.language.register.namePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='username'>
                                                    {Globals.language.register.surname[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='surname'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setSurname(e.target.value)}
                                                    value={surname}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='surname'
                                                    placeholder={Globals.language.register.surnamePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='phoneNumber'>
                                                    {Globals.language.register.phone[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='phoneNumber'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='phoneNumber'
                                                    placeholder={Globals.language.register.phonePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='fiscalCode'>
                                                    {Globals.language.register.birtday[lang]}
                                                </label>
                                                <input
                                                    type='date'
                                                    id='fiscalCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                    value={dateOfBirth}
                                                    required={lang === "it" ? true : false}
                                                    aria-describedby='uidnote'
                                                    name='fiscalCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.birtdayPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='birthPlace'>
                                                    {Globals.language.register.luogoNascita[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='birthPlace'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setBirthPlace(e.target.value)}
                                                    value={birthPlace}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='birthPlace'
                                                    className='form-control'
                                                    placeholder={
                                                        Globals.language.register.luogoNascitaPlaceholder[lang]
                                                    }
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='fiscalCode'>
                                                    {Globals.language.register.fiscalCode[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='fiscalCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setFiscalCode(e.target.value)}
                                                    value={fiscalCode}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='fiscalCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.fiscalCodePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-4 form-group'>
                                                <label htmlFor='company'>
                                                    {Globals.language.register.companyName[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='company'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setCompany(e.target.value)}
                                                    value={company}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='company'
                                                    placeholder={
                                                        Globals.language.register.companyNamePlaceholder[lang]
                                                    }
                                                />
                                            </div>
                                            <div className='col-sm-2 form-group'>
                                                <label htmlFor='vatCode'>
                                                    {Globals.language.register.vatNumber[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='vatCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setVatCode(e.target.value)}
                                                    value={vatCode}
                                                    required
                                                    aria-describedby='uidnote'
                                                    className='form-control'
                                                    name='vatCode'
                                                    placeholder={Globals.language.register.vatNumberPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='fiscalCode'>
                                                    {Globals.language.register.fiscalCodeCompany[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='fiscalCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setFiscalCodeCompany(e.target.value)}
                                                    value={fiscalCodeCompany}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='fiscalCode'
                                                    className='form-control'
                                                    placeholder={
                                                        Globals.language.register.fiscalCodeCompanyPlaceholder[lang]
                                                    }
                                                />
                                            </div>

                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.indirizzo[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='vatCode'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    value={address}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.indirizzoPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.city[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='city'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setCity(e.target.value)}
                                                    value={city}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.cityPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.province[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='province'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setProvince(e.target.value)}
                                                    value={province}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.provincePlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.cap[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='cap'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setCap(e.target.value)}
                                                    value={cap}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.capPlaceholder[lang]}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='sdi'>
                                                    {Globals.language.register.uniqueCode[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='sdi'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setSdi(e.target.value)}
                                                    value={sdi}
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.uniqueCodePlaceholder[lang]}

                                                    // disabled={pec !== "" ? true : false}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='pec'>{Globals.language.register.pec[lang]}</label>
                                                <input
                                                    type='text'
                                                    id='pec'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setPec(e.target.value)}
                                                    value={pec}
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.pecPlaceholder[lang]}
                                                    // disabled={sdi !== "" ? true : false}
                                                />
                                            </div>
                                            <div className='col-sm-6 form-group'>
                                                <label htmlFor='address'>
                                                    {Globals.language.register.Nation[lang]}
                                                </label>
                                                <input
                                                    type='text'
                                                    id='nazione'
                                                    ref={userRef}
                                                    autoComplete='off'
                                                    onChange={(e) => setNation(e.target.value)}
                                                    value={nation}
                                                    required
                                                    aria-describedby='uidnote'
                                                    name='vatCode'
                                                    className='form-control'
                                                    placeholder={Globals.language.register.selectNationNew[lang]}
                                                />
                                            </div>

                                            {/* <div className='col-sm-6'>
												<div className='mb-4'>
													<label htmlFor='nation'>
														{Globals.language.register.Nation[lang]}
													</label>
													<Select
														options={listNation}
														isSearchable={true}
														required
														isMulti={false}
														isClearable={true}
														placeholder={Globals.language.register.selectNation[lang]}
														onChange={(e) => {
															setSelectNation(e);
														}}
													/>
												</div>
											</div> */}

                                            <div className='col-sm-6'>
                                                <div className='mb-4'>
                                                    <label htmlFor='medicalSpecialty'>
                                                        {Globals.language.register.medicalSpecialty[lang]}
                                                    </label>
                                                    <input
                                                        type='text'
                                                        id='medicalSpecialty'
                                                        ref={userRef}
                                                        autoComplete='off'
                                                        onChange={(e) => setMedicalSpecialty(e.target.value)}
                                                        value={medicalSpecialty}
                                                        required
                                                        aria-describedby='uidnote'
                                                        name='medicalSpecialty'
                                                        className='form-control'
                                                        placeholder={
                                                            Globals.language.register.selectMedicalSpecialty[lang]
                                                        }
                                                        // disabled={sdi !== "" ? true : false}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-sm-6'>
                                                <label htmlFor='password'>
                                                    Password<span className='text-danger'>*</span>
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-text'>
                                                        <i className='bi bi-lock-fill'></i>
                                                    </div>
                                                    <input
                                                        id='password'
                                                        onChange={(e) => setPwd(e.target.value)}
                                                        type={passwordShown ? "text" : "password"}
                                                        value={pwd}
                                                        required
                                                        aria-invalid={validPwd ? "false" : "true"}
                                                        aria-describedby='pwdnote'
                                                        onFocus={() => setPwdFocus(true)}
                                                        onBlur={() => setPwdFocus(false)}
                                                        name='password'
                                                        className='form-control'
                                                        placeholder={Globals.language.register.passwordPlaceholder[lang]}
                                                    />
                                                    <div
                                                        className='input-group-text '
                                                        style={{cursor: "pointer"}}
                                                        onClick={togglePassword}>
                                                        <i
                                                            className={passwordShown ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                                    </div>
                                                </div>
                                                <PasswordStrengthBar
                                                    scoreWords={["Debole", "Debole", "Media", "Buona", "Forte"]}
                                                    shortScoreWord={["Troppo corta"]}
                                                    password={pwd}
                                                />
                                            </div>

                                            <div className='col-sm-6'>
                                                <label htmlFor='password'>
                                                    {Globals.language.register.passwordConfirm[lang]}
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <div className='input-group'>
                                                    <div className='input-group-text'>
                                                        <i className='bi bi-lock-fill'></i>
                                                    </div>
                                                    <input
                                                        type={passwordConfirmShown ? "text" : "password"}
                                                        id='confirm_pwd'
                                                        onChange={(e) => setMatchPwd(e.target.value)}
                                                        value={matchPwd}
                                                        required
                                                        aria-invalid={validMatch ? "false" : "true"}
                                                        aria-describedby='confirmnote'
                                                        onFocus={() => setMatchFocus(true)}
                                                        onBlur={() => setMatchFocus(false)}
                                                        name='confirm_pwd'
                                                        className='form-control'
                                                        placeholder={
                                                            Globals.language.register.passwordConfirmPlaceholder[lang]
                                                        }
                                                    />
                                                    <div
                                                        className='input-group-text '
                                                        style={{cursor: "pointer"}}
                                                        onClick={togglePassword}>
                                                        <i
                                                            className={
                                                                passwordConfirmShown ? "bi bi-eye" : "bi bi-eye-slash"
                                                            }
                                                            onClick={toggleConfirmPassword}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-sm-12 my-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check d-inline mt-3'
                                                    id='chb'
                                                    required
                                                />
                                                <label for='chb' className='form-check-label'>
                                                    &nbsp; {Globals.language.register.privacy[lang]}{" "}
                                                    <Link to='/privacy-policy'>
                                                        {Globals.language.register.privacySecond[lang]}{" "}
                                                    </Link>
                                                    {Globals.language.register.privacyDate[lang]}
                                                </label>
                                            </div>
                                            {errMsg !== "" ? (
                                                <div style={{color: "red", textAlign: "center"}}> {errMsg} </div>
                                            ) : (
                                                <></>
                                            )}

                                            {/* <div className='col-sm-12 my-2'>
												<input
													type='checkbox'
													className='form-check d-inline mt-3'
													id='chb'
													required
												/>
												<label for='chb' className='form-check-label'>
													&nbsp;Accetto{" "}
													<Link to='/condizioni-uso'>termini e condizioni</Link> del
													servizio.
												</label>
											</div> */}

                                            <div className='col-sm-12 form-group mb-0'>
                                                <button
                                                    className='btn btn-danger float-right my-2'
                                                    onClick={() => handleSubmit()}>
                                                    {Globals.language.register.buttonIscriviti[lang]}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            )}
        </div>
    );
};

export default Register;
