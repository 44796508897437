import React, {createContext, useState, useEffect} from "react";
import {toast} from "react-hot-toast";
import axios from "axios";
import Globals from "../parameter/globals";

const CartContext = createContext();

export const CartProvider = ({children}) => {
	const [cartItems, setCartItems] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalQuantities, setTotalQuantities] = useState(0);
	const [qty, setQty] = useState(1);
	const [ecommerce, setEcommerce] = useState([]);
	const [activeEcommerce, setActiveEcommerce] = useState([]);

	const [coupon, setCoupon] = useState({
		active: false,
		code: "",
		sponsor: "",
		congress_id: "",
	});

	useEffect(() => {
		getSessionCart();
		getEcommerce();
	}, []);
	let foundProduct, foundService, foundCongressAdded;

	let lang = localStorage.getItem("lang");

	const addOnServer = async (product, price, quantity, special = false) => {
		try {
			let body = {
				product: product,
				totalPrice: price,
				totalQuantities: quantity,
				special: special,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADDTOCART, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				return false;
			} else {
				return true;
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const getSessionCart = async () => {
		try {
			const response = await axios.get(Globals.GETSESSIONCART, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (
				response?.data.error !== true &&
				response?.data.cart !== false &&
				response?.data.totalPrice !== false &&
				response?.data.totalQuantities !== false
			) {
				setCartItems([response?.data?.cart]);
				setTotalPrice(response?.data?.totalPrice);
				setTotalQuantities(response?.data?.totalQuantities);

				if (response?.data?.coupon !== false) {
					setCoupon({
						...coupon,
						active: true,
						congress_id: response?.data?.coupon,
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const resetSession = async () => {
		try {
			const response = await axios.get(Globals.RESETSESSIONCART, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const onAdd = async (product, quantity, special) => {
		// trova se il prodotto è nel carrello

		let services_price = null;
		product?.congress_added?.map(
			(item) => (services_price += parseFloat(item.price)),
		);
		let ticket_price = null;
		product?.ticket_category?.map(
			(item) => (ticket_price += parseFloat(item.price)),
		);

		//se non presente aggiungi come nuovo elemento array
		product.quantity = quantity;

		setCartItems([product]);

		let price = special ? 0 : product.price + services_price + ticket_price;

		//in caso di coupon
		if (special) {
			setTotalPrice(0);
		} else {
			setTotalPrice(product.price + services_price + ticket_price);
		}

		setTotalQuantities(1);

		let is_add_on_server = await addOnServer(product, price, quantity, special);

		if (special === false) {
			setCoupon({
				active: false,
				code: "",
				sponsor: "",
				congress_id: "",
			});
		}

		if (is_add_on_server === true) {
			toast.success(
				`${qty} ${product.name} ${
					Globals.language.toastMessage.addToCart[lang === null ? "it" : lang]
				}`,
			);

			return true;
		} else {
			toast.error("Server error");
			return false;
		}
	};

	const onRemove = async (product) => {
		foundProduct = cartItems.find((item) => item.id === product.id);
		const newCartItems = cartItems.filter((item) => item.id !== product.id);

		let services_price = null;

		product?.congress_added.map(
			(item) => (services_price += parseFloat(item.price)),
		);

		setCartItems(newCartItems);
		await resetSession();
	};

	const onRemoveService = async (product, id_service) => {
		foundProduct = cartItems.find((item) => item.id === product.id);

		foundService = foundProduct.congress_added.find(
			(item) => item.id === id_service,
		);

		foundCongressAdded = foundProduct.congress_added;

		setTotalPrice((prevTotalPrice) => prevTotalPrice - foundService.price);

		foundCongressAdded = foundCongressAdded.filter(
			(item) => item.id !== foundService.id,
		);

		foundProduct.congress_added = foundCongressAdded;

		await addOnServer(
			foundProduct,
			totalPrice - foundService.price,
			totalQuantities,
		);
	};

	const resetCart = async () => {
		setCartItems({});
		setTotalQuantities(0);
		setTotalPrice(0);

		setCoupon({
			active: false,
			code: "",
			sponsor: "",
			congress_id: "",
		});

		await resetSession();

		toast.success(
			Globals.language.toastMessage.emptyCart[lang === null ? "it" : lang],
		);
	};

	const getEcommerce = async () => {
		try {
			const response = await axios.get(Globals.GETECOMMERCE + "&lang=" + lang, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				console.log(response?.data?.error);
			} else {
				setEcommerce(response?.data?.ecommerce);

				const attivo = response?.data?.ecommerce.map((item) => item.active);
				setActiveEcommerce(attivo);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<CartContext.Provider
			value={{
				resetCart,
				cartItems,
				totalPrice,
				totalQuantities,
				ecommerce,
				activeEcommerce,
				qty,
				coupon,
				onAdd,
				onRemove,
				resetSession,
				onRemoveService,
				setCartItems,
				setTotalPrice,
				setTotalQuantities,
				setCoupon,
			}}>
			{children}
		</CartContext.Provider>
	);
};

export default CartContext;
