import "../scss/congressi.scss";
import Image from "react-bootstrap/Image";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../context/CartProvider";
import Globals from "../parameter/globals";
import AuthContext from "../context/AuthProvider";
import { Helmet } from "react-helmet";

function Congressi(props) {
	const { lang } = useContext(AuthContext);

	const congressData = props.data || false;
	const { activeEcommerce } = useContext(CartContext);
	const navigate = useNavigate();

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	// const formatMonth = (item) => {
	// 	let d = new Date(item);
	// 	let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	// 	if (month.length < 2) month = "0" + month;
	// 	return month;
	// };
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		d.setMonth(month - 1);

		let local = lang === "it" ? "it-IT" : "en-GB";

		let chartMonth = d.toLocaleString(local, { month: "long" });

		return chartMonth.substring(0, 3);
	};

	return (
		<div className='container container-congressi' id='congress'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Congressi - Soluzioni Omnia media</title>
				<link rel='canonical' href='https://www.soluzioniomniamedia.com/#congress' />
			</Helmet>
			<h2 className='text-center  title-homepage-all'>
				{congressData.length !== 0
					? Globals.language.congress.titleCongress[lang]
					: ""}
			</h2>
			<ul className='cards-congressi scroll-color-colors'>
				{congressData !== false && congressData.length !== 0 ? (
					congressData.map((item, key) => (
						<li
							key={key}
							className={
								item.already_subscribe === true || item.limit === true
									? "card card-congressi border border-0"
									: "card card-congressi-special border border-0"
							}
							data-label={
								item.already_subscribe === true
									? Globals.language.congress.iscritto[lang]
									: item.limit === true
									? Globals.language.congress.capienzaRaggiunta[lang]
									: ""
							}

							// style={{
							// 	opacity:
							// 		item.already_subscribe === true || item.limit === true ? 0.5 : 1,

							// }}
						>
							<div>
								<div
									className='my-2 title-card-congress mb-auto'
									style={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
										height:"100px"
									}}>
									<div className='circle-congressi'></div>
									{/* Aggiungere text-dark */}
									<h3 className='card-title  mx-5 mb-3 '>{item.name}</h3>
								</div>

								<div className='container-card-congressi-responsive '>
									<section className='data-container'>
										<span className='mx-1'>
											{formatDay(item.start_date) === "NaN"
												? "00"
												: formatDay(item.start_date)}{" "}
											{formatMonth(item.start_date) === "NaN"
												? "00"
												: formatMonth(item.start_date)}{" "}
										</span>
										<div>
											<i className='bi bi-calendar-event' style={{ fontSize: "15px" }}></i>{" "}
											<span style={{ fontSize: "15px" }} className='mx-1 year-font'>
												{formatYear(item.start_date) !== "Nan"
													? formatYear(item.start_date)
													: "0000"}
											</span>
										</div>
									</section>

									<aside className='image-container'>
										<img
											src={item.url}
											className=' img-responsive image-card-congressi'
											alt='...'
										/>
									</aside>
								</div>
								<div
									className='resposnive-events-card'

									style={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
									}}>
									<div className='col-lg-2 col-md-0 col-sm-0'></div>
									<div className='col-lg-10 col-md-11 col-sm-12'>
										<div
											className='text-congress-description my-2'
											style={{ minHeight: "100px" }}>
											<span className='mx-2 my-2'>
												{item.short_description !== ""
													? item.short_description
													: Globals.language.congress.nessunaDescrizione[lang]}
											</span>
										</div>
										<div className='card-link-wrapper w-75 btn-congress'>
											{/* {item.already_subscribe === true || item.limit === true ? (
												<div>
													<button
														className='navLink card-link-info my-2 border border-danger'
														style={{ cursor: "cursor", opacity: 0.5, textDecoration: "none" }}
														to={"/"}>
														{" "}
														{Globals.language.congress.scopriPiu[lang]}{" "}
													</button>
												</div>
											) : ( */}
											<div>
												<Link
													className='navLink card-link-info my-2 border border-danger'
													to={"/congress/" + item.id + "/" + item.slug_url + "#detail"}>
													{" "}
													{Globals.language.congress.scopriPiu[lang]}{" "}
												</Link>
											</div>
											{/* )} */}

											{item.already_subscribe === true && (
												<span
													className='card-link border-0'
													style={{ backgroundColor: "grey" }}>
													{Globals.language.congress.iscrittoCongresso[lang]}
												</span>
											)}
											{parseInt(item.is_visualization) === 1 ? (
												<div></div>
											) : (
												<div>
													{(item.already_subscribe === undefined ||
														item.already_subscribe === false) &&
													activeEcommerce[0] !== "1" ? (
														// <div>
														// 	{item.limit === true ? (
														// 		<span className='card-link border-0'>
														// 			Raggiunta capienza massima
														// 		</span>
														// 	) : (
														// 		<button
														// 			className='card-link border-0'
														// 			onClick={() => navigate("/ticket/" + item.id)}>
														// 			Iscriviti al congresso
														// 		</button>
														// 	)}
														// </div>
														<div>
															{item.limit === true && (
																<span className='card-link border-0'>
																	{Globals.language.congress.capienzaRaggiunta[lang]}
																</span>
															)}

															{item.is_buy === "1" && item.limit === false && (
																<button
																	className='card-link border-0'
																	onClick={() =>
																		navigate(
																			"/ticket/" + item.id + "/" + item.slug_url + "#detail"
																		)
																	}>
																	{Globals.language.congress.iscriviti[lang]}
																</button>
															)}
															{item.is_buy === "2" && item.limit === false  &&  (
																<button
																	className='card-link border-0'
																	onClick={() =>
																		navigate(
																			"/ticket/" + item.id + "/" + item.slug_url + "#detail"
																		)
																	}>
																	{Globals.language.congress.iscrivitiGratuitamente[lang]}
																</button>
															)}
															{/* {item.is_buy === "3" && <div></div>} */}
														</div>
													) : (
														<span> </span>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</li>
					))
				) : (
					<div></div>
				)}
			</ul>
		</div>
	);
}

export default Congressi;
