import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Globals from "../../parameter/globals";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import ImageGallery from "react-image-gallery";
import Newsletter from "../../components/Newsletter";
import CartContext from "../../context/CartProvider";
import Contact from "../../components/Contact";
import CardGroup from "react-bootstrap/CardGroup";
import Loading from "../../components/Loading";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";

function Congress(props) {
	let { id } = useParams();
	const { lang } = useContext(AuthContext);

	const navigate = useNavigate();
	const parse = require("html-react-parser");

	const [images, setImage] = useState([
		{
			original: "",
			thumbnail: "",
		},
	]);
	const [imagesMultiple, setImageMultiple] = useState([]);
	const [attachment, setAttachment] = useState([]);
	const [loading, setLoading] = useState(true);
	const location = useLocation();

	const [error, setError] = useState(false);
	const [noEng, setNoEng] = useState(false);
	const [message, setMessage] = useState("");
	const [congress, setCongress] = useState({
		id: "",
		address: "",
		city: "",
		title_description: "",
		description: "",
		name: "",
		start_date: "",
		end_date: "",
		gallery_title: "",
		gallery_description: "",
		img_url: "",
		is_buy: "",
		img_detail: "",
		price: "",
		limit: false,
		is_subscribe: false,
		is_visualization: "",
		slug_url: "",
		short_description: "",
	});
	const [program, setProgram] = useState([]);
	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	// const formatMonth = (item) => {
	//   let d = new Date(item);
	//   let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	//   if (month.length < 2) month = "0" + month;
	//   return month;
	// };

	const formatMonth = (item) => {
		if (windowDimenion.winWidth < 600) {
			let d = new Date(item);
			let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
			if (month.length < 2) month = "0" + month;
			return month;
		} else {
			let d = new Date(item);
			let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
			if (month.length < 2) month = "0" + month;
			d.setMonth(month - 1);

			let local = lang === "it" ? "it-IT" : "en-GB";
			return d.toLocaleString(local, { month: "long" });
		}
	};

	useEffect(() => {
		setLoading(true);

		if (noEng) {
			setLoading(false);
			alert("This congress is not available in english.");

			navigate("/home");
		} else {
			getCongress();
		}
	}, [lang]);
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
	// 	  }, 200);

	//   }, [location.pathname])

	useEffect(() => {
		scrollToIdElement();
	}, [location.hash]);

	const scrollToIdElement = async () => {
		const { hash } = window.location;
		if (hash !== "") {
			// Push onto callback queue so it runs after the DOM is updated,
			// this is required when navigating from a different page so that
			// the element is rendered on the page before trying to getElementById.
			setTimeout(() => {
				const id = hash.replace("#", "");
				// const yOffset =250;
				const element = document.getElementById(id);
				if (element) element.scrollIntoView();
				// const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				// window.scrollTo({top: y, behavior: 'smooth'});
				// 	if (element) (element).scrollIntoView({
				// 		top: 150,
				//   });
			}, 100);
		}
	};

	const getCongress = async () => {
		try {
			let body = {
				id: id,
				access_token: localStorage.getItem("accessToken")
					? localStorage.getItem("accessToken")
					: "",
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETCONGRESS + "&lang=" + lang,
				formdata,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				}
			);

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setCongress({
					...congress,
					id: response?.data?.congress?.id,
					address: response?.data?.congress?.address,
					city: response?.data?.congress?.city,
					title_description: response?.data?.congress?.title_description,
					description: response?.data?.congress?.description,
					name: response?.data?.congress?.name,
					start_date: response?.data?.congress?.start_date,
					end_date: response?.data?.congress?.end_date,
					gallery_title: response?.data?.congress?.gallery_title,
					gallery_description: response?.data?.congress?.gallery_description,
					img_url: response?.data?.image,
					is_buy: response?.data?.congress?.is_buy,
					img_detail: response?.data?.fileUrlDetail,
					price: parseFloat(response?.data?.congress?.price),
					limit: response?.data?.congress?.limit,
					is_subscribe: response?.data?.congress?.is_subscribe,
					is_visualization: response?.data?.congress?.is_visualization,
					slug_url: response?.data?.congress?.slug_url,
					short_description: response?.data?.congress?.short_description,
				});
				setProgram(response?.data?.programCongress);
				setImage([
					{
						...congress,
						original: response?.data?.image,
						thumbnail: response?.data?.image,
					},
				]);
				setImageMultiple(response?.data?.multi_image);

				setAttachment(response?.data?.attachment);

				setNoEng(parseInt(response?.data?.congress?.lang_en) === 1 ? false : true);

				setLoading(false);

				window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			}
		} catch (err) {
			setError(true);
			setMessage(err);
			setLoading(false);
		}
	};

	const dates = getDates(
		new Date(congress.start_date),
		new Date(congress.end_date)
	);

	function getDates(startDate, endDate) {
		const dates = [];
		let currentDate = startDate;
		const addDays = function (days) {
			const date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		while (currentDate <= endDate) {
			dates.push(currentDate);
			currentDate = addDays.call(currentDate, 1);
		}
		return dates;
	}
	const { activeEcommerce } = useContext(CartContext);

	const [windowDimenion, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	});

	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener("resize", detectSize);

		return () => {
			window.removeEventListener("resize", detectSize);
		};
	}, [windowDimenion]);

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div style={{ textAlign: "center" }} id='detail'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{congress.name} - Soluzioni Omnia media</title>
					<meta name='description' content={congress.short_description} />
					<link rel='canonical' href='https://www.soluzioniomniamedia.com/' />
				</Helmet>

				{error === true ? (
					<span style={{ fontSize: 20, color: "red" }}> {message} </span>
				) : (
					<span></span>
				)}
				<Carousel
					variant='light'
					controls={false}
					interval={5000}
					indicators={false}>
					<Carousel.Item className='responsive-position-caption-congress congress-item'>
						<img
							src={congress.img_detail}
							className='img-responsive image-card-congress'
							alt='...'
						/>
						<Carousel.Caption className='position-caption-congress'>
							<Card className='card card-congress shadow-none border-0'>
								<Card.Text className='date-congress'>
									{congress.start_date === congress.end_date ? (
										<div>
											{formatDay(congress.start_date)}{" "}
											{windowDimenion.winWidth < 600 ? "-" : " "}
											{formatMonth(congress.start_date)}
											{windowDimenion.winWidth < 600 ? "-" : " "}
											{formatYear(congress.start_date)}
										</div>
									) : (
										<div>
											{/* {formatDay(congress.start_date)}-{formatMonth(congress.start_date)}-
									{formatYear(congress.start_date)} / {formatDay(congress.end_date)}-
									{formatMonth(congress.end_date)}-{formatYear(congress.end_date)} */}
											{dates.map((item, key) => {
												if (dates.length - 1 === key) {
													return (
														<span key={key}>
															{" "}
															{formatDay(item)}
															{windowDimenion.winWidth < 600 ? "-" : " "} {formatMonth(item)}{" "}
															{windowDimenion.winWidth < 600 ? "-" : " "}
															{formatYear(item)}{" "}
														</span>
													);
												} else {
													return (
														<span key={key}>
															{" "}
															{formatDay(item)} {windowDimenion.winWidth < 600 ? "-" : " "}{" "}
															{formatMonth(item)} {windowDimenion.winWidth < 600 ? "-" : " "}
															{formatYear(item)} |{" "}
														</span>
													);
												}
											})}
										</div>
									)}
									<span className='information-address-congress'>
										<p>
											{congress.address} - {congress.city}
										</p>
									</span>
								</Card.Text>
								<Card.Body className='body-congress'>
									<Card.Title className='date-title-congress' >
										{congress.name}
									</Card.Title>

									<div className='container-btn-congress'></div>
								</Card.Body>
							</Card>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>

				<h2 className='my-2' >{congress.title_description}</h2>
				<div className='my-5 container-fluid' style={{ display: "flex" }}>
					<div className='col-md-0 text-center mx-2'>
						{congress.description.length !== 0 && 
						<i
							className='bi bi-list-nested'
							style={{ fontSize: "30px", color: "#d50037" }}></i>
						}
					</div>
					<div className='col-md-11'>
						<article className='border-about-us mx-2'>
							<p className='mx-2 text-start'>
								<div
									dangerouslySetInnerHTML={{ __html: congress.description }}
									className='mx-3'
								/>
							</p>
						</article>
					</div>
				</div>
				{program.length ? (
					<h1 className='text-center' style={{color:"#53565a"}}>{Globals.language.readMore.programm[lang]}</h1>
				) : (
					<></>
				)}
				{program.map((item, key) => (
					<div className='d-inline w-100' key={key}>
						{key % 3 === 0 ? (
							<CardGroup className='d-inline-block w-50 responsive-card-congress'>
								<Card className='my-1 mx-1'>
									<Card.Body>
										<Card.Title >{item.title}</Card.Title>
										<Card.Text>{parse(item.text)}</Card.Text>
									</Card.Body>
									<Card.Footer>
										<small className='text-muted'>{item.sub_title}</small>
									</Card.Footer>
								</Card>
							</CardGroup>
						) : (
							<CardGroup className='d-inline-block w-50 responsive-card-congress'>
								<Card className='my-1 mx-1' style={{ backgroundColor: "#E0E0E0" }}>
									<Card.Body>
										<Card.Title>{item.title}</Card.Title>
										<Card.Text>{parse(item.text)}</Card.Text>
									</Card.Body>
									<Card.Footer>
										<small className='text-muted'>{item.sub_title}</small>
									</Card.Footer>
								</Card>
							</CardGroup>
						)}
					</div>
				))}

				<div className='d-flex section-description-detail-congress py-2'>
					<div className='col-md-12 text-center w-100 section-description-detail-congress-full'>
						<div className='text-center p-5'>
							{attachment.length ? <h1 style={{color:"#53565a"}}>{Globals.language.ticketService.allegati[lang]}</h1> : <></>}

							<div>
								{attachment !== false ? (
									attachment.map((item, key) => (
										<div className='d-inline-block align-content-center justify-content-center' key={key}>
											{/* <Link
												to={item.url}
												className='btn btn-outline-danger my-1 btn-lg mx-2'
												target='_blank'
												download>
												{item.title}
											</Link> */}
											<Button
												variant='none'
												onClick={(e) => {
													e.preventDefault();
													window.location.href = item.url;
												}}
												className='btn btn-outline-danger my-1 btn-lg mx-2 hover-btn'

												download>
												{item.title}
											</Button>
										</div>
									))
								) : (
									<div></div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className=' section-description-detail-congress py-2'>
					<div className='col-md-12 text-center w-100 section-description-detail-congress-full'>
						<div className=' text-center'>
							<h1 style={{color:"#53565a"}}>
								{congress.gallery_title !== "" ? congress.gallery_title : "Gallery"}{" "}
							</h1>
							<h3 className=' text-center'>{congress.gallery_description}</h3>
							<div className='container'>
								<div className='d-flex align-content-center justify-content-center'>
									<ImageGallery
										showBullets={true}
										items={imagesMultiple !== null ? imagesMultiple : images}
										thumbnailPosition={"bottom"}
										onPlay={true}
										showFullscreenButton={false}
										showThumbnails={windowDimenion.winWidth < 1400 ? false : true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='sticky'>
					<div className='d-flex justify-content-center align-items-center text-center mx-auto'>
						<div className='d-flex align-items-center'>
							<h3 className='mx-2' >{congress.name} </h3>
							{/*<h5>{formatDay(congress.start_date)}-{formatMonth(congress.start_date)}-{formatYear(congress.start_date)} <br/>  {formatDay(congress.end_date)}-{formatMonth(congress.end_date)}-{formatYear(congress.end_date)}*/}
							{/*</h5>*/}
						</div>

						<div>
							{congress.is_subscribe === true && (
								<span className='btn btn-outline-light btn-lg mx-2'>
									{Globals.language.readMore.alreadySubscribeCongress[lang]}
								</span>
							)}

							{parseInt(congress.is_visualization) === 1 ? (
								<div></div>
							) : (
								<div>
									{(congress.is_subscribe === undefined ||
										congress.is_subscribe === false) &&
									activeEcommerce[0] !== "1" ? (
										<div>
											{congress.limit === true ? (
												<span className='btn btn-outline-light btn-lg mx-2'>
													{Globals.language.readMore.maximumCapacityReached[lang]}
												</span>
											) : (
												<div>
													{congress.is_buy === "1" && (
														<button
															className='btn btn-outline-light btn-lg mx-2'
															onClick={() =>
																navigate("/ticket/" + congress.id + "/" + congress.slug_url)
															}>
															{Globals.language.readMore.subscribeCongress[lang]}
														</button>
													)}
													{congress.is_buy === "2" && (
														<button
															className='btn btn-outline-light btn-lg mx-2'
															onClick={() =>
																navigate("/ticket/" + congress.id + "/" + congress.slug_url)
															}>
															{Globals.language.readMore.freeMembership[lang]}
														</button>
													)}
													{/* {event.is_buy === "3" && (
                      <div
                        style={{ borderRadius: "5px", margin: "2px" }}
                        className="bg-dark"
                        onClick={() => navigate("/ticketevent/" + event.id)}
                      >
                        <h3 className="mx-2 py-1">
                          {" "}
                          Evento a libera entrata
                        </h3>
                      </div>
                    )} */}
												</div>
											)}{" "}
										</div>
									) : (
										<div></div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm text-center'>
							<h1 className='div-heading display-4' style={{color:"#53565a"}}>
								{Globals.language.contacts.titleCustomers[lang]}
							</h1>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6 col-sm-12 mx-auto text-start'>
							<Contact general={false} from={"Congresso - " + congress.name} />
						</div>
					</div>
				</div>
				<div>
					<Newsletter />
				</div>
			</div>
		);
	}
}

export default Congress;
