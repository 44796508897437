import axios from "axios";
import React, {useState, useContext, useEffect, useMemo} from "react";
import {useTable, useGlobalFilter, useSortBy, usePagination} from "react-table";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import Table from "react-bootstrap/Table";
import {GlobalFilter} from "../../components/globalFilter";
import "bootstrap-icons/font/bootstrap-icons.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-hot-toast";

import {Card} from "react-bootstrap";

import {faEdit, faTrash, faCircle} from "@fortawesome/free-solid-svg-icons";
import {Modal, Button, Col, Row, Form} from "react-bootstrap";
import Select from "react-select";
import AdminAppNav from "../../components/AdminAppNav";

function AdminOperator() {
	// server and auth variable
	const {auth} = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate();

	//table variable
	const [users, setusers] = useState([]);
	const [alldata, setAllData] = useState([]);
	const [formdata, setFormData] = useState(false);

	//create/edit user variable
	const [active, setActiveUser] = useState(false);
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [birthPlace, setBirthPlace] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

	const [fiscalCode, setFiscalCode] = useState("");
	const [idEdit, setIdEdit] = useState();

	//form variable
	const [showForm, setShowForm] = useState("");
	const [action, setAction] = useState("");

	//call all data from db
	const getUsers = async () => {
		try {
			setLoading(true);
			let body = {
				access_token: auth.accessToken,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.ADMINGETOPERATOR + "&lang=it",
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
				setLoading(false);
			} else {
				setusers(response.data.users);
				setAllData(response.data.allData);

				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 400) {
				setErrMsg("Missing Username or Password");
			} else if (err.response?.status === 401) {
				setErrMsg("Unauthorized");
			} else {
				console.log(err);
				setErrMsg("Errore in fase di raccoglimento dati utente");
				// console.log(err);
			}
		}
	};

	//delete row classic method
	const deleteRow = async (id) => {
		if (window.confirm("Vuoi eliminare questo utente?") === true) {
			try {
				setLoading(true);
				let body = {
					access_token: auth.accessToken,
					id: id,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(
					Globals.ADMINDELETEUSER + "&lang=it",
					formdata,
					{
						headers: {"Content-Type": "application/json"},
						withCredentials: true,
					},
				);

				if (response?.data?.error === true) {
					setErrMsg(response?.data?.message);
					toast.error(response?.data?.message);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success(`Utente eliminato con successo.`);
					getUsers();
				}
			} catch (err) {
				setLoading(false);
				setErrMsg("Errore di comunicazione con il server");
			}
		}
	};

	const modifyUserState = async (id) => {
		if (window.confirm("Vuoi modificare lo stato di questo utente?") === true) {
			try {
				setLoading(true);
				let body = {
					access_token: auth.accessToken,
					id: id,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(
					Globals.ADMINACTIVEUSER + "&lang=it",
					formdata,
					{
						headers: {"Content-Type": "application/json"},
						withCredentials: true,
					},
				);

				if (response?.data?.error === true) {
					setErrMsg(response?.data?.message);
					toast.error(response?.data?.message);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success(`Modificato lo stato dell'utente con successo.`);
					getUsers();
				}
			} catch (err) {
				setLoading(false);
				if (!err?.response) {
					setErrMsg("No Server Response");
				} else if (err.response?.status === 400) {
					setErrMsg("Missing Username or Password");
				} else if (err.response?.status === 401) {
					setErrMsg("Unauthorized");
				} else {
					console.log(err);
					// console.log(err);
				}
			}
		}
	};

	//on start loading page load method
	useEffect(() => {
		getUsers();
	}, []);

	//react table const
	const usersData = useMemo(() => [...users], [users]);
	const usersColumns = useMemo(
		() =>
			users[0]
				? Object.keys(users[0])
						.filter(
							(key) =>
								key !== "id" &&
								key !== "attivo" &&
								key !== "is_sponsor" &&
								key !== "is_public_administration" &&
								key !== "is_superadmin",
						)
						.map((key) => {
							return {Header: key, accessor: key};
						})
				: [],
		[users],
	);

	//show create user bootstrap form, reset all value before show
	const showCreateUserForm = () => {
		setAction("Nuovo utente operatore");
		setErrMsg("");

		setActiveUser(false);
		setPwd("");
		setName("");
		setSurname("");
		setEmail("");
		setDateOfBirth("");
		setBirthPlace("");
		setPhoneNumber("");

		setFiscalCode("");

		setShowForm(true);
	};

	const showEditUserForm = (id) => {
		setAction("Modifica utente");
		setErrMsg("");

		let searchOrder = alldata.find((item) => parseInt(item.id) === parseInt(id));

		setActiveUser(parseInt(searchOrder.active) === 1 ? true : false);
		setPwd("");
		setName(searchOrder.name);
		setSurname(searchOrder.surname);
		setEmail(searchOrder.email);
		setDateOfBirth(searchOrder.date_of_birth);
		setBirthPlace(searchOrder.birthplace);
		setPhoneNumber(searchOrder.mobile_number);

		setFiscalCode(searchOrder.fiscal_code);

		setIdEdit(id);

		setShowForm(true);
	};

	//createUser Method
	const createUser = async (e) => {
		try {
			let body = {
				active: active,
				user: email,
				password: pwd,
				name: name,
				surname: surname,
				email: email,
				phoneNumber: phoneNumber,
				birthPlace: birthPlace,
				dateOfBirth: dateOfBirth,
				fiscalCode: fiscalCode,

				access_token: auth.accessToken,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.ADMINCREATEOPERATOR + "&lang=it",
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
			} else {
				toast.success(`Operatore ${email} con successo.`);
				getUsers();
				setShowForm(false);
				//close the modal
			}
		} catch (err) {
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 409) {
				setErrMsg("Username Taken");
			} else {
				setErrMsg("Creation Failed");
			}
		}
	};

	const editUser = async (e) => {
		try {
			let body = {
				operator: true,
				active: active,
				user: email,
				password: pwd,
				name: name,
				surname: surname,
				email: email,
				phoneNumber: phoneNumber,

				id: idEdit,

				access_token: auth.accessToken,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINEDITUSER, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
			} else {
				toast.success(`Utente ${email} modificato con successo.`);
				getUsers();
				setShowForm(false);
				//close the modal
			}
		} catch (err) {
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 409) {
				setErrMsg("Username Taken");
			} else {
				setErrMsg("Edit Failed");
			}
		}
	};

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: "Visualize",
				Header: "Attivo",
				Cell: ({row}) =>
					parseInt(row.original.attivo) === 1 ? (
						<FontAwesomeIcon
							icon={faCircle}
							style={{color: "green"}}
							onClick={() => modifyUserState(row.original.id)}
							cursor={"pointer"}
						/>
					) : (
						<FontAwesomeIcon
							icon={faCircle}
							style={{color: "red"}}
							onClick={() => modifyUserState(row.original.id)}
							cursor={"pointer"}
						/>
					),
			},
			{
				id: "Edit",
				Header: "Edit",
				Cell: ({row}) => (
					<FontAwesomeIcon
						icon={faEdit}
						onClick={() => showEditUserForm(row.original.id)}
						className='hover:text-red-500'
						cursor={"pointer"}
					/>
				),
			},
			{
				id: "Delete",
				Header: "Delete",
				Cell: ({row}) =>
					usersData[row.id]["is_superadmin"] !== "superadmin" ? (
						<div>
							<FontAwesomeIcon
								icon={faTrash}
								onClick={() => deleteRow(row.original.id)}
								className='hover:text-red-500'
								cursor={"pointer"}
							/>
						</div>
					) : (
						<div></div>
					),
			},
		]);
	};

	const tableInstance = useTable(
		{
			columns: usersColumns,
			data: usersData,
			initialState: {pageIndex: 0, pageSize: 25},
		},
		useGlobalFilter,
		tableHooks,
		useSortBy,
		usePagination,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		preGlobalFilteredRows,
		setGlobalFilter,
		state,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		page,
	} = tableInstance;
	const {pageIndex, pageSize} = state;

	if (loading) {
		return <Loading />;
	} else {
		return (
			<div
				className='container'
				style={{marginTop: "140px", marginBottom: "100px"}}>
				<div>
					{/* <Button onClick={() => navigate(-1)}>
						<span> Torna indietro</span>
					</Button> */}
					<AdminAppNav />
					{/* <h2 className=" text-center uppercase" style={{fontSize:"50px"}}> Gestione Utenti </h2> */}
					<Button onClick={() => showCreateUserForm()}>
						<i className='bi bi-plus'></i> Nuovo operatore{" "}
					</Button>
				</div>

				{loading === false && users?.length > 0 ? (
					<>
						<GlobalFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							setGlobalFilter={setGlobalFilter}
							globalFilter={state.globalFilter}
						/>

						<Table
							{...getTableProps()}
							striped
							bordered
							hover
							responsive
							variant='light'>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												style={{
													borderBottom: "solid 3px ",
													color: "black",
													fontWeight: "bold",
													fontSize: "20px",
													textTransform: "capitalize",
												}}>
												{column.render("Header")}
												{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
											</th>
										))}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row);

									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td
														{...cell.getCellProps()}
														style={{
															padding: "5px",

															border: "solid 1px gray",
														}}>
														{cell.render("Cell")}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</Table>
						<Card>
							<div className='responsive-pagination'>
								<div>
									<button
										className='btn btn-light'
										onClick={() => gotoPage(0)}
										disabled={!canPreviousPage}>
										{"<<"}
									</button>{" "}
									<button
										className='btn btn-light mx-1'
										onClick={() => previousPage()}
										disabled={!canPreviousPage}>
										Precedente
									</button>{" "}
									<button
										className='btn btn-light mx-1'
										onClick={() => nextPage()}
										disabled={!canNextPage}>
										Successiva
									</button>{" "}
									<button
										onClick={() => gotoPage(pageCount - 1)}
										disabled={!canNextPage}
										className='btn btn-light mx-1'>
										{">>"}
									</button>{" "}
								</div>
								<div className='float-end'>
									<span className='w-100'>
										Pagina:{" "}
										<strong>
											{pageIndex + 1} di {pageOptions.length}
										</strong>{" "}
									</span>
								</div>
								<div className='text-center'>
									<span>
										Vai alla pagina{" "}
										<input
											type='number'
											defaultValue={pageIndex + 1}
											onChange={(e) => {
												const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
												gotoPage(pageNumber);
											}}
											className='d-flex justify-content-center mx-auto responsive-pagination'
										/>
									</span>{" "}
								</div>
								<select
									className='form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination'
									value={pageSize}
									onChange={(e) => setPageSize(Number(e.target.value))}>
									{[10, 25, 50, 75].map((pageSize) => (
										<option key={pageSize} value={pageSize}>
											Visualizza {pageSize} righe
										</option>
									))}
								</select>
							</div>
						</Card>
					</>
				) : (
					<>
						<article style={{textAlign: "center"}}>
							<h1> Nessun operatore registrato</h1>
						</article>
					</>
				)}

				{errMsg && <p className='fs-1 fw-bolder danger'> {errMsg}</p>}

				<Modal
					show={showForm}
					onHide={() => setShowForm(false)}
					size='xl'
					aria-labelledby='contained-modal-title-vcenter'
					centered
					style={{zIndex: "9999"}}>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'> {action}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridEmail'>
									<div className='form-check form-switch'>
										<input
											className='form-check-input'
											type='checkbox'
											checked={active}
											onChange={(e) => setActiveUser(!active)}
										/>
										<label className='form-check-label' for='flexSwitchCheckDefault'>
											Attivo
										</label>
									</div>
								</Form.Group>
							</Row>

							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridEmail'>
									<Form.Label>Email/Username</Form.Label>
									<Form.Control
										type='email'
										placeholder='Inserisci una email'
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formGridPassword'>
									<Form.Label>Password</Form.Label>
									<Form.Control
										type='password'
										placeholder='Inserisci la nuova password'
										value={pwd}
										onChange={(e) => setPwd(e.target.value)}
									/>
								</Form.Group>
							</Row>
							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridEmail'>
									<Form.Label>Nome</Form.Label>
									<Form.Control
										type='text'
										placeholder='Inserisci un nome'
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formGridPassword'>
									<Form.Label>Cognome</Form.Label>
									<Form.Control
										type='text'
										placeholder='Inserisci un cognome'
										value={surname}
										onChange={(e) => setSurname(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<Row className='mb-3'>
								<Form.Group as={Col} controlId='formGridPassword'>
									<Form.Label>Codice fiscale</Form.Label>
									<Form.Control
										type='text'
										placeholder='Inserisci il codice fiscale'
										value={fiscalCode}
										onChange={(e) => setFiscalCode(e.target.value)}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId='formGridPassword'>
									<Form.Label>Data di nascita</Form.Label>
									<Form.Control
										type='date'
										placeholder='Inserisci una data di nascita'
										value={dateOfBirth}
										onChange={(e) => setDateOfBirth(e.target.value)}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId='formGridPassword'>
									<Form.Label>Luogo di nascita</Form.Label>
									<Form.Control
										type='text'
										placeholder='Inserisci un luogo di nascita'
										value={birthPlace}
										onChange={(e) => setBirthPlace(e.target.value)}
									/>
								</Form.Group>

								<Form.Group as={Col} controlId='formGridEmail'>
									<Form.Label>Numero di telefono</Form.Label>
									<Form.Control
										type='tel'
										placeholder='Numero di telefono'
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
									/>
								</Form.Group>
							</Row>

							<Form.Group className='mb-3' controlId='formGridState'>
								<Form.Label>Tipo di utenza</Form.Label>
								<Form.Control
									placeholder='Operatore'
									value={"Operatore"}
									disabled={true}
								/>
							</Form.Group>

							{/* <Form.Group className='mb-3' id='formGridCheckbox'>
								<Form.Check
									type='checkbox'
									label='Account sponsor?'
									defaultChecked={isSponsor}
									onChange={(e) =>
										e.target.checked === true ? setIsSponsor(true) : setIsSponsor(false)
									}
								/>
							</Form.Group> */}

							{errMsg !== "" ? (
								<h4 style={{textAlign: "center", color: "red"}}> Errore : {errMsg}</h4>
							) : (
								""
							)}
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setShowForm(false)}>
							Chiudi
						</Button>
						<Button
							variant='success'
							onClick={(e) =>
								action === "Nuovo utente operatore" ? createUser(e) : editUser(e)
							}>
							Conferma
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default AdminOperator;
