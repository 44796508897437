import React, {useState, useContext, useEffect} from "react";
import CartContext from "../context/CartProvider";
import AuthContext from "../context/AuthProvider";
import {toast} from "react-hot-toast";
import Loading from "./Loading";
import axios from "axios";
import Globals from "../parameter/globals";
import {useNavigate} from "react-router-dom";
import {PayPalButtons} from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function ChoosePayment(props) {
	const [transfer, setTransfer] = useState(false);
	const [paypal, setPaypal] = useState(false);
	const [iban, setIban] = useState("");
	const [infoPayment, setInfoPayment] = useState("");
	const [ecommerce, setEcommerce] = useState([]);

	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [errMsgHome, setErrMsgHome] = useState();

	const [show, setShow] = useState(false);
	const [modalReturnToHome, setMoodalReturnToHome] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showError, setShowError] = useState(false);

	const handleCloseError = () => setShowError(false);
	const handleShowError = () => setShowError(true);

	// const [showSuccess, setShowSuccess] = useState(false);

	// const handleCloseSuccess = () => setShow(false);
	// const handleShowSuccess = () => setShow(true);

	const navigate = useNavigate();
	// creare una pagina di atterraggio per uscire con la pagina di successo

	const {totalQuantities, totalPrice, cartItems, resetCart, coupon} =
		useContext(CartContext);

	const {auth, setAuth, lang} = useContext(AuthContext);

	// if (auth.is_public_administration !== "0"){
	// 	console.log(Math.round((totalPrice/100)*122));
	// }
	const [public_administration, setPublicAdministration] = useState([]);

	useEffect(() => {
		checkLogin();
	}, []);

	useEffect(() => {
		getPaymentInfo();
	}, [lang]);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				let accessToken = myAccessToken;
				let roles = response?.data?.roles;
				let user = response?.data?.username;
				let is_public_administration = response?.data?.public_administration;
				setPublicAdministration({
					user,
					accessToken,
					roles,
					is_public_administration,
				});
			} catch (err) {
				setPublicAdministration({});
				localStorage.setItem("accessToken", "");
			}
		}
	};

	const copyIban = () => {
		//bisogna recuperare dinamicamente l'iban con suddetta frase
		navigator.clipboard.writeText(iban);
		toast.success(Globals.language.toastMessage.iban_copy[lang]);
	};

	const getPaymentInfo = async () => {
		try {
			const response = await axios.get(Globals.GETPAYMENTINFO + "&lang=" + lang);
			setIban(response.data.iban);
			setInfoPayment(response.data.info_payment);
		} catch (err) {
			handleShow(true);
			setErrMsg(Globals.language.errorLabel.errorPaymentInfo[lang]);
		}
	};

	const checkIfAllIsCorrect = async () => {
		let access_token =
			localStorage.getItem("accessToken") === ""
				? auth.accessToken
				: localStorage.getItem("accessToken");

		//controllo se l'utene è loggato, in caso negativo ritorna alla home (funzione di controllo iscrizione ed esistenza dell'elemernto)
		if (
			access_token !== "" &&
			access_token !== null &&
			access_token !== undefined
		) {
			try {
				let body = {
					access_token: localStorage.getItem("accessToken"),
					id: cartItems[0].id,
					category: cartItems[0].category,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(
					Globals.CHECKSUBSCRIBE + "&lang=" + lang,
					formdata,
					{
						headers: {"Content-Type": "application/json"},
						withCredentials: true,
					},
				);

				if (response?.data?.error === true) {
					setMoodalReturnToHome(true);
					setErrMsgHome(response?.data?.home);
					setErrMsg(response.data.message);
					handleShow(true);
				} else {
					setLoading(false);
				}
			} catch (err) {
				setLoading(false);
			}
		} else {
			setMoodalReturnToHome(true);
			setErrMsg(Globals.language.errorLabel.doLogin[lang]);
			handleShow(true);
			setAuth({});
		}
	};

	const transferPayment = async (gratis = false) => {
		//raccogliere i dati del carrello
		if (Object.keys(cartItems).length === 0) {
			setErrMsg(Globals.language.errorLabel.noSelectCongress[lang]);
			handleShow(true);
			return false;
		}
		//verificare che non siano vuoti e verificare che auth non siano vuoto
		if (Object.keys(auth).length === 0) {
			setErrMsg(Globals.language.errorLabel.noAuth[lang]);
			handleShow(true);
			return false;
		}

		//fare la chiamata passsando carrello e access_token

		try {
			setLoading(true);
			let body = {
				access_token: auth.accessToken,
				product: cartItems,
				total_price:
					parseInt(public_administration.is_public_administration) !== 0
						? totalPrice
						: ((totalPrice / 100) * 122).toFixed(2),
				total_quantities: totalQuantities,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			let apiName =
				gratis === true
					? Globals.SUBSCRIBEFREE + "&lang=" + lang
					: Globals.TRANSFERPAYMENT + "&lang=" + lang;

			const response = await axios.post(apiName, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			//in caso di succcesso fare una form di successo e svuotare il carrello

			if (response?.data?.error === true) {
				setLoading(false);
				setErrMsg(response?.data?.message);
				handleShow(true);
			} else {
				setLoading(false);
				resetCart();

				navigate("/success", {
					state: {
						product: body.product,
						code: response?.data?.code,
						typePayment: gratis === true ? "gratis" : "transfer",
					},
				});
			}
		} catch (err) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			handleShow(true);
		}

		//in caso di errore far ritornare l'errore
	};

	const handleApprove = async (orderID, order) => {
		// fare la chiamata php

		try {
			setLoading(true);
			let body = {
				access_token: auth.accessToken,
				product: cartItems,
				total_price:
					parseInt(public_administration.is_public_administration) !== 0
						? totalPrice
						: ((totalPrice / 100) * 122).toFixed(2),
				total_quantities: totalQuantities,
				orderID: orderID,
				order: order,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.PAYPALPAYMENT + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			//in caso di succcesso fare una form di successo e svuotare il carrello

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
				handleShow(true);
				setLoading(false);
			} else {
				setLoading(false);

				resetCart();
				navigate("/success", {
					state: {
						paypal_order_id: orderID,
						product: body.product,
						code: response?.data?.code,
						typePayment: "paypal",
					},
				});
			}
		} catch (err) {
			setLoading(false);
			handleShow(true);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			// console.log(err);
		}
	};

	const getEcommerce = async () => {
		try {
			const response = await axios.get(Globals.GETECOMMERCE, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				console.log(response?.data?.error);
			} else {
				setEcommerce(response?.data?.ecommerce);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getPaymentInfo();
		checkIfAllIsCorrect();
		getEcommerce();
	}, []);

	if (loading === true) {
		return (
			<div className='container'>
				<Loading />
			</div>
		);
	} else {
		return (
			<div className='container'>
				{props?.gratis === true && (
					<div>
						{props?.confirmCondizioni && props?.confirmClausole ? (
							<button
								className='btn btn-success btn-lg mt-5'
								onClick={() => transferPayment(true)}>
								{coupon?.active === true
									? Globals.language.choosePayment.signUpCoupon[lang]
									: Globals.language.choosePayment.signUpFree[lang]}
							</button>
						) : (
							<button
								className='btn btn-success btn-lg mt-5'
								onClick={() => handleShowError(true)}>
								{coupon?.active === true
									? Globals.language.choosePayment.signUpCoupon[lang]
									: Globals.language.choosePayment.signUpFree[lang]}
							</button>
						)}
					</div>
				)}

				{transfer === false && paypal === false && props?.gratis === false && (
					<div>
						{ecommerce.map((item, key) => (
							<div key={key}>
								{item.active_paypal !== "1" ? (
									<div className='row mt-5'>
										<h2> {Globals.language.choosePayment.chooseMethod[lang]}</h2>

										<div className='col-sm-12 py-1'>
											<div className='card h-100'>
												<div className='card-body'>
													<h5 className='card-title'>
														{Globals.language.choosePayment.advancePayment[lang]}
													</h5>
													<p className='card-text'>
														{Globals.language.choosePayment.advancePaymentExplain[lang]}
													</p>
													{props?.confirmCondizioni && props?.confirmClausole ? (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => setTransfer(true)}>
															{Globals.language.choosePayment.payTransfer[lang]}
														</button>
													) : (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => handleShowError(true)}>
															{Globals.language.choosePayment.payTransfer[lang]}
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className='row mt-5'>
										<h2> {Globals.language.choosePayment.chooseMethod[lang]}</h2>
										<div className='col-sm-6 py-1'>
											<div className='card h-100'>
												<div className='card-body'>
													<h5 className='card-title'>
														{Globals.language.choosePayment.advancePayment[lang]}
													</h5>
													<p className='card-text'>
														{Globals.language.choosePayment.advancePaymentExplain[lang]}
													</p>
													{props?.confirmCondizioni && props?.confirmClausole ? (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => setTransfer(true)}>
															{Globals.language.choosePayment.payTransfer[lang]}
														</button>
													) : (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => handleShowError(true)}>
															{Globals.language.choosePayment.payTransfer[lang]}
														</button>
													)}
												</div>
											</div>
										</div>
										<div className='col-sm-6 py-1'>
											<div className='card h-100'>
												<div className='card-body'>
													<h5 className='card-title'>
														{Globals.language.choosePayment.paypalLabel[lang]}
													</h5>
													<p className='card-text'>
														{Globals.language.choosePayment.creditCardLabel[lang]}
													</p>
													{props?.confirmCondizioni && props?.confirmClausole ? (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => setPaypal(true)}>
															{Globals.language.choosePayment.paypalLabel[lang]}
														</button>
													) : (
														<button
															className='btn btn-danger'
															style={{backgroundColor: "#d50037"}}
															onClick={() => handleShowError(true)}>
															{Globals.language.choosePayment.paypalLabel[lang]}
														</button>
													)}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				)}

				{transfer === true && (
					<>
						<div className='d-flex'>
							<button className='btn btn-primary' onClick={() => setTransfer(false)}>
								{" "}
								<i className='bi bi-caret-left-fill'></i>
								{Globals.language.choosePayment.chooseAnotherPaymentMethod[lang]}{" "}
							</button>
						</div>
						<h3> {Globals.language.choosePayment.bankTransfer[lang]}</h3>

						<div className='row mt-5'>
							<div className='col-md-15'>
								<div className='card'>
									<div className='card-body'>
										<h2 className='card-title'>
											{" "}
											{Globals.language.choosePayment.detailPayment[lang]}
										</h2>
										<p className='card-text'>
											<p dangerouslySetInnerHTML={{__html: infoPayment}} />
											<br></br>
											IBAN: {iban}
										</p>
										{parseInt(public_administration.is_public_administration) !== 0 ? (
											<h4>
												{Globals.language.choosePayment.amountToPay[lang]}{" "}
												{Intl.NumberFormat("de-DE", {
													style: "currency",
													currency: "EUR",
												}).format(totalPrice)}{" "}
											</h4>
										) : (
											<div>
												<h4>
													{Globals.language.choosePayment.amountToPay[lang]}{" "}
													{Intl.NumberFormat("de-DE", {
														style: "currency",
														currency: "EUR",
													}).format((totalPrice / 100) * 122)}{" "}
												</h4>
											</div>
										)}

										<button className='btn btn-secondary' onClick={() => copyIban()}>
											{Globals.language.choosePayment.copyIban[lang]}
										</button>
									</div>
								</div>
							</div>
						</div>

						{props?.confirmCondizioni && props?.confirmClausole ? (
							<button
								className='btn btn-success btn-lg mt-5'
								onClick={() => transferPayment()}>
								{" "}
								{Globals.language.choosePayment.placeOrder[lang]}{" "}
							</button>
						) : (
							<button
								className='btn btn-success btn-lg mt-5'
								onClick={() => handleShowError(true)}>
								{Globals.language.choosePayment.placeOrder[lang]}{" "}
							</button>
						)}
					</>
				)}

				{paypal === true && (
					<>
						<div className='d-flex'>
							<button className='btn btn-primary' onClick={() => setPaypal(false)}>
								{" "}
								<i className='bi bi-caret-left-fill'></i>
								{Globals.language.choosePayment.chooseAnotherPaymentMethod[lang]}{" "}
							</button>
						</div>
						<hr></hr>
						<h3> {Globals.language.choosePayment.paypalLabel[lang]} </h3>
						<div className='mt-3'>
							<PayPalButtons
								fundingSource='paypal'
								style={{
									layout: "horizontal",
									height: 48,
									tagline: false,
									shape: "pill",
									label: "buynow",
								}}
								createOrder={(data, actions) => {
									return actions.order.create({
										purchase_units: [
											{
												description: (
													"Soluzioni Omnia Media s.r.l. - " +
													cartItems[0].category +
													" - " +
													cartItems[0].name
												).slice(0, 127),
												amount: {
													value:
														parseInt(public_administration.is_public_administration) !== 0
															? totalPrice
															: ((totalPrice / 100) * 122).toFixed(2),
												},
											},
										],
										application_context: {
											shipping_preference: "NO_SHIPPING",
										},
									});
								}}
								onClick={(data, actions) => {
									//validate on button click, client o server side
								}}
								onApprove={async (data, actions) => {
									const order = await actions.order.capture();
									handleApprove(data.orderID, order);
								}}
								onError={(err) => {
									setErrMsg(err);
									handleShow(true);
								}}
								onCancel={() => {
									//display cancel message , or redirect page back to cart
									setErrMsg(Globals.language.choosePayment.exitPaypalMessage[lang]);
									handleShow(true);
								}}
							/>
						</div>
						{Globals.language.choosePayment.or[lang]}
						<div className='mt-3'>
							<PayPalButtons
								fundingSource='card'
								style={{
									layout: "horizontal",
									height: 48,
									tagline: false,
									shape: "pill",
									label: "buynow",
								}}
								createOrder={(data, actions) => {
									return actions.order.create({
										purchase_units: [
											{
												description: (
													"Soluzioni Omnia Media s.r.l. - " +
													cartItems[0].category +
													" - " +
													cartItems[0].name
												).slice(0, 127),
												amount: {
													value:
														parseInt(public_administration.is_public_administration) !== 0
															? totalPrice
															: ((totalPrice / 100) * 122).toFixed(2),
												},
											},
										],
										application_context: {
											shipping_preference: "NO_SHIPPING",
										},
									});
								}}
								onClick={(data, actions) => {
									//validate on button click, client o server side
								}}
								onApprove={async (data, actions) => {
									const order = await actions.order.capture();
									handleApprove(data.orderID, order);
								}}
								onError={(err) => {
									setErrMsg(err);
									handleShow(true);
								}}
								onCancel={() => {
									//display cancel message , or redirect page back to cart
									setErrMsg(Globals.language.choosePayment.exitPaypalMessage[lang]);
									handleShow(true);
								}}
							/>
						</div>
					</>
				)}
				<Modal
					show={show}
					centered
					onHide={() => (modalReturnToHome === true ? navigate("/") : handleClose)}>
					<Modal.Header closeButton={modalReturnToHome === true ? false : true}>
						<Modal.Title>
							{Globals.language.choosePayment.warningTitle[lang]}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>{errMsg}</Modal.Body>
					{errMsgHome === true ? (
						<div>
							<Modal.Footer>
								{modalReturnToHome === true ? (
									<Button
										variant='success'
										className='mx-auto'
										onClick={() => navigate("/home")}>
										{Globals.language.choosePayment.goToHome[lang]}
									</Button>
								) : (
									<Button variant='success' className='mx-auto' onClick={handleClose}>
										{Globals.language.ticketService.buttonConferma[lang]}
									</Button>
								)}
							</Modal.Footer>
						</div>
					) : (
						<div>
							<Modal.Footer>
								{modalReturnToHome === true ? (
									<Button
										variant='success'
										className='mx-auto'
										onClick={() => navigate("/login")}>
										{Globals.language.choosePayment.goToLogin[lang]}
									</Button>
								) : (
									<Button variant='success' className='mx-auto' onClick={handleClose}>
										{Globals.language.ticketService.buttonConferma[lang]}
									</Button>
								)}
							</Modal.Footer>
						</div>
					)}
				</Modal>

				<Modal
					show={showError}
					centered
					onHide={handleCloseError}
					backdrop='static'
					keyboard={false}>
					<Modal.Header closeButton>
						<Modal.Title>
							{Globals.language.choosePayment.warningTitle[lang]}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{Globals.language.choosePayment.accepConditionModal[lang]}
					</Modal.Body>
					<Modal.Footer>
						<Button variant='success' onClick={handleCloseError}>
							{Globals.language.ticketService.buttonConferma[lang]}
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default ChoosePayment;
