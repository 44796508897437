import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Globals from "../../parameter/globals";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import ImageGallery from "react-image-gallery";
import Newsletter from "../../components/Newsletter";
import { useLocation, useNavigate } from "react-router-dom";
import Contact from "../../components/Contact";
import CardGroup from "react-bootstrap/CardGroup";
import parse from "html-react-parser";
import CartContext from "../../context/CartProvider";
import Button from "react-bootstrap/Button";
import Loading from "../../components/Loading";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";

function SingleEvent() {
	let { id } = useParams();

	const { lang } = useContext(AuthContext);
	const [error, setError] = useState(false);

	const [loading, setLoading] = useState(true);
	const [noEng, setNoEng] = useState(false);

	const [message, setMessage] = useState("");
	const navigate = useNavigate();
	const location = useLocation();

	const [images, setImage] = useState([
		{
			original: "",
			thumbnail: "",
		},
	]);
	const [imagesMultiple, setImageMultiple] = useState([]);
	const [attachment, setAttachment] = useState([]);
	const [event, setEvent] = useState({
		id: "",
		address: "",
		city: "",
		title_description: "",
		description: "",
		name: "",
		start_date: "",
		end_date: "",
		gallery_title: "",
		gallery_description: "",
		is_buy: "",
		img_url: "",
		img_detail: "",
		limit: false,
		is_subscribe: false,
		slug_url: "",
		short_description: "",
	});
	const [program, setProgramEvent] = useState([]);

	useEffect(() => {
		setLoading(true);

		if (noEng) {
			alert("This event is not available in english.");
			navigate("/home", { replace: true });
			setLoading(false);
		} else {
			getEvent();
		}
	}, [lang]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
	// 	  }, 200);

	//   }, [location.pathname])
	useEffect(() => {
		scrollToIdElement();
	}, [location.hash]);

	const scrollToIdElement = async () => {
		const { hash } = window.location;
		if (hash !== "") {
			// Push onto callback queue so it runs after the DOM is updated,
			// this is required when navigating from a different page so that
			// the element is rendered on the page before trying to getElementById.
			setTimeout(() => {
				const id = hash.replace("#", "");
				// const yOffset =250;
				const element = document.getElementById(id);
				if (element) element.scrollIntoView();
				// const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				// window.scrollTo({top: y, behavior: 'smooth'});
				// 	if (element) (element).scrollIntoView({
				// 		top: 150,
				//   });
			}, 100);
		}
	};

	const getEvent = async () => {
		try {
			let body = {
				id: id,
				access_token: localStorage.getItem("accessToken")
					? localStorage.getItem("accessToken")
					: "",
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETEVENT + "&lang=" + lang,
				formdata,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				}
			);

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setEvent({
					...event,
					id: response?.data?.event?.id,
					address: response?.data?.event?.address,
					city: response?.data?.event?.city,
					title_description: response?.data?.event?.title_description,
					description: response?.data?.event?.description,
					name: response?.data?.event?.name,
					start_date: response?.data?.event?.start_date,
					end_date: response?.data?.event?.end_date,
					gallery_title: response?.data?.event?.gallery_title,
					gallery_description: response?.data?.event?.gallery_description,
					is_buy: response?.data?.event?.is_buy,
					img_url: response?.data?.image,
					img_detail: response?.data?.fileUrlDetail,
					limit: response?.data?.event?.limit,
					is_subscribe: response?.data?.event?.is_subscribe,
					slug_url: response?.data?.event?.slug_url,
					short_description: response?.data?.event?.short_description,
				});
				setImage([
					{
						...event,
						original: response?.data?.image,
						thumbnail: response?.data?.image,
					},
				]);
				setProgramEvent(response?.data?.programEvent);
				setImageMultiple(response?.data?.multi_image);
				setNoEng(parseInt(response?.data?.congress?.lang_en) === 1 ? false : true);

				setAttachment(response?.data?.attachment);
				setLoading(false);
				// window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			}
		} catch (err) {
			setError(true);
			setMessage(err);
			setLoading(false);
		}
	};

	const dates = getDates(new Date(event.start_date), new Date(event.end_date));

	function getDates(startDate, endDate) {
		const dates = [];
		let currentDate = startDate;
		const addDays = function (days) {
			const date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		while (currentDate <= endDate) {
			dates.push(currentDate);
			currentDate = addDays.call(currentDate, 1);
		}
		return dates;
	}

	const { activeEcommerce } = useContext(CartContext);

	const [windowDimenion, detectHW] = useState({
		winWidth: window.innerWidth,
		winHeight: window.innerHeight,
	});

	const detectSize = () => {
		detectHW({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener("resize", detectSize);

		return () => {
			window.removeEventListener("resize", detectSize);
		};
	}, [windowDimenion]);

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	// const formatMonth = (item) => {
	//   let d = new Date(item);
	//   let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	//   if (month.length < 2) month = "0" + month;
	//   return month;
	// };
	// const formatMonth = (item) => {
	//   let d = new Date(item);
	//   let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	//   if (month.length < 2) month = "0" + month;
	//   d.setMonth(month - 1);

	//   return  d.toLocaleString([], { month: 'long' });
	// };
	const formatMonth = (item) => {
		if (windowDimenion.winWidth < 600) {
			let d = new Date(item);
			let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
			if (month.length < 2) month = "0" + month;
			return month;
		} else {
			let d = new Date(item);
			let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
			if (month.length < 2) month = "0" + month;
			d.setMonth(month - 1);

			let local = lang === "it" ? "it-IT" : "en-GB";

			return d.toLocaleString(local, { month: "long" });
		}
	};

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div style={{ textAlign: "center" }} id='detail'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{event.name} - Soluzioni Omnia media</title>
					<meta name='description' content={event.short_description} />
					<link rel='canonical' href='https://www.soluzioniomniamedia.com/' />
				</Helmet>
				{error === true ? (
					<span style={{ fontSize: 20, color: "red" }}> {message} </span>
				) : (
					<span></span>
				)}

				{error === true ? (
					<span style={{ fontSize: 20, color: "red" }}> {message} </span>
				) : (
					<span></span>
				)}

				<Carousel
					variant='light'
					controls={false}
					interval={5000}
					indicators={false}>
					<Carousel.Item className='responsive-position-caption-event event-item'>
						<img
							src={event.img_detail}
							className='img-responsive image-card-event'
							alt='...'
						/>

						<Carousel.Caption className='position-caption-event'>
							<Card className='card card-event shadow-none border-0'>
								<Card.Text className='date-event'>
									{event.start_date === event.end_date ? (
										<div>
											{formatDay(event.start_date)}
											{windowDimenion.winWidth < 600 ? "-" : " "}
											{formatMonth(event.start_date)}
											{windowDimenion.winWidth < 600 ? "-" : " "}
											{formatYear(event.start_date)}{" "}
										</div>
									) : (
										<div>
											{dates.map((item, key) => {
												if (dates.length - 1 === key) {
													return (
														<span>
															{" "}
															{formatDay(item)}
															{windowDimenion.winWidth < 600 ? "-" : " "}
															{formatMonth(item)}
															{windowDimenion.winWidth < 600 ? "-" : " "}
															{formatYear(item)}{" "}
														</span>
													);
												} else {
													return (
														<span>
															{" "}
															{formatDay(item)}
															{windowDimenion.winWidth < 600 ? "-" : " "}
															{formatMonth(item)}
															{windowDimenion.winWidth < 600 ? "-" : " "}
															{formatYear(item)} |{" "}
														</span>
													);
												}
											})}
										</div>
									)}

									<span className='information-address-event'>
										<p>
											{event.address} - {event.city}
										</p>
									</span>
								</Card.Text>
								<Card.Body className='body-event'>
									<Card.Title className='date-title-event'>{event.name}</Card.Title>
									<div className='container-btn-event'></div>
								</Card.Body>
							</Card>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>

				<h2 className='my-2'>
					{event.title_description !== ""
						? event.title_description
						: "Nessun titolo"}
				</h2>
				<div className='my-5 container-fluid' style={{ display: "flex" }}>
					<div className='col-md-0 text-center mx-2'>
					{event.description.length !== 0 && 

						<i
							className='bi bi-list-nested'
							style={{ fontSize: "30px", color: "#d50037" }}></i>
					}
					</div>
					<div className='col-md-11'>
						<article className='border-about-us mx-2'>
							<p className='mx-2 text-start color-predefinito-desc'>
								<div
									dangerouslySetInnerHTML={{ __html: event.description }}
									className='mx-3'
								/>
							</p>
						</article>
					</div>
				</div>
				{program.length ? (
					<h1 className='text-center' style={{color:"#53565a"}}>{Globals.language.readMore.programm[lang]}</h1>
				) : (
					<></>
				)}
				{program.map((item, key) => (
					<div className='d-inline w-100' key={key}>
						{key % 3 === 0 ? (
							<CardGroup className='d-inline-block w-50 responsive-card-congress'>
								<Card className='my-1 mx-1'>
									<Card.Body>
										<Card.Title>{item.title}</Card.Title>
										<Card.Text>{parse(item.text)}</Card.Text>
									</Card.Body>
									<Card.Footer>
										<small className='text-muted'>{item.sub_title}</small>
									</Card.Footer>
								</Card>
							</CardGroup>
						) : (
							<CardGroup className='d-inline-block w-50 responsive-card-congress'>
								<Card className='my-1 mx-1' style={{ backgroundColor: "#E0E0E0" }}>
									<Card.Body>
										<Card.Title>{item.title}</Card.Title>
										<Card.Text>{parse(item.text)}</Card.Text>
									</Card.Body>
									<Card.Footer>
										<small className='text-muted'>{item.sub_title}</small>
									</Card.Footer>
								</Card>
							</CardGroup>
						)}
					</div>
				))}
				<div className='d-flex section-description-detail-event py-2'>
					<div className='col-md-12 text-center w-100 section-description-detail-event-full'>
						<div className=' text-center p-5'>
							{attachment.length ? (
								<h1>{Globals.language.ticketService.allegati[lang]}</h1>
							) : (
								<></>
							)}
							<div>
								{attachment !== false ? (
									attachment.map((item) => (
										<div className='d-inline-block align-content-center justify-content-center'>
											<Button
												variant='none'
												onClick={(e) => {
													e.preventDefault();
													window.location.href = item.url;
												}}
												className='btn btn-outline-danger my-1 btn-lg mx-2 hover-btn'
												
												download>
												{item.title}
											</Button>
										</div>
									))
								) : (
									<div></div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='d-flex section-description-detail-event py-2'>
					<div className='col-md-12 text-center w-100 section-description-detail-event-full'>
						<h1 style={{color:"#53565a"}}>{event.gallery_title !== "" ? event.gallery_title : "Gallery"} </h1>
						<h3 className=' text-center' style={{color:"#53565a"}}>{event.gallery_description}</h3>
						<div className='container'>
							<div className='d-flex align-content-center justify-content-center'>
								<ImageGallery
									showBullets={true}
									items={imagesMultiple !== null ? imagesMultiple : images}
									thumbnailPosition={"bottom"}
									showFullscreenButton={false}
									autoPlay
									showThumbnails={windowDimenion.winWidth < 600 ? false : true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='sticky'>
					<div className='d-flex justify-content-center align-items-center text-center mx-auto'>
						<div className='d-flex align-items-center'>
							<h3 className='mx-2'>{event.name} </h3>
							{/*<h5 className="mx-auto"><span className="mx-2">{formatDay(event.start_date)}-{formatMonth(event.start_date)}-{formatYear(event.start_date)}</span>/<span>   {formatDay(event.end_date)}-{formatMonth(event.end_date)}-{formatYear(event.end_date)}</span></h5>*/}
						</div>
						<div>
							{event.is_subscribe === true && (
								<span className='btn btn-outline-light btn-lg mx-2'>
									{event.is_subscribe}
									{Globals.language.readMore.alreadySubscribeEvent}
								</span>
							)}

							{(event.is_subscribe === undefined || event.is_subscribe === false) &&
								activeEcommerce[0] !== "1" && (
									<div>
										{event.limit === true ? (
											<span className='btn btn-outline-light btn-lg mx-2'>
												{Globals.language.readMore.maximumCapacityReached[lang]}
											</span>
										) : (
											<div>
												{event.is_buy === "1" && (
													<button
														className='btn btn-outline-light btn-lg mx-2'
														onClick={() =>
															navigate("/ticketevent/" + event.id + "/" + event.slug_url)
														}>
														{Globals.language.readMore.subscribeEvent[lang]}
													</button>
												)}
												{event.is_buy === "2" && (
													<button
														className='btn btn-outline-light btn-lg mx-2'
														onClick={() =>
															navigate("/ticketevent/" + event.id + "/" + event.slug_url)
														}>
														{Globals.language.readMore.freeMembership[lang]}
													</button>
												)}
												{event.is_buy === "3" && (
													<div
														style={{ borderRadius: "5px", margin: "2px" }}
														className='bg-dark'
														onClick={() =>
															navigate("/ticketevent/" + event.id + "/" + event.slug_url)
														}>
														<h3 className='mx-2 py-1'>
															{Globals.language.readMore.freeMembershipEvent[lang]}
														</h3>
													</div>
												)}
											</div>
										)}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm text-center'>
							<h1 className='div-heading display-4' style={{color:"#53565a"}}>
								{Globals.language.contacts.titleCustomers[lang]}{" "}
							</h1>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6 col-sm-12 mx-auto text-start'>
							<Contact general={false} from={"Evento - " + event.name} />
						</div>
					</div>
				</div>
				<div>
					<Newsletter />
				</div>
			</div>
		);
	}
}

export default SingleEvent;
