import axios from "axios";
import React, {useState, useContext, useEffect, useMemo} from "react";
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	setGlobalFilter,
	usePagination,
} from "react-table";
import {Navigate, useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import Table from "react-bootstrap/Table";
import {GlobalFilter} from "../../components/globalFilter";
import "bootstrap-icons/font/bootstrap-icons.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-hot-toast";
import {Link} from "react-router-dom";
import AdminNav from "../../components/AdminNav";

import {
	faTrash,
	faEye,
	faCheckCircle,
	faXmark,
	faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import OrderFormModal from "../../components/OrderFormModal";
import {Button, Card} from "react-bootstrap";
import LogOrderModal from "../../components/LogOrderModal";

function AdminOrder() {
	const {auth} = useContext(AuthContext);
	const navigation = useNavigate();

	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const [orders, setOrders] = useState([]);
	const [alldata, setAllData] = useState([]);

	const [logModalShow, setLogModalShow] = useState(false);
	const [logdata, setLogdata] = useState([]);

	const [formdata, setFormData] = useState({
		fields: [
			{
				label: "id",
				visible: false,
				type: "hidden",
				value: null,
				errorMessage: "",
				disable: false,
			},
			{
				label: "Prezzo totale",
				name: "total_price",
				visible: false,
				type: "hidden",
				value: null,
				errorMessage: "",
				disable: false,
			},
			{
				name: "codice",
				label: "codice",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "payment_method",
				label: "Metodo di pagamento",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "paypal_cart",
				label: "Codice ordine paypal",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "paid",
				label: "Pagato",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "name",
				label: "Nome",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "surname",
				label: "Cognome",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "email",
				label: "Indirizzo email",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "mobile_number",
				label: "Numero di telefono",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "address",
				label: "Indirizzo",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "fiscal_code",
				label: "Codice fiscale",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "company",
				label: "Azienda",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "congress_name",
				label: "Nome congresso",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "data_ora",
				label: "Data",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "zip_code",
				label: "Cap",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "province",
				label: "Provincia",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "city",
				label: "City",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "sdi",
				label: "Codice univoco",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "status",
				label: "Pec",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "state",
				label: "Stato",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
			{
				name: "medical_specialty",
				label: "Specilità medica",
				visible: true,
				type: "text",
				errorMessage: "",
				disable: false,
			},
		],

		order_row: [
			{value: "", label: "Prezzo senza servizi"},
			{
				value: "",
				label: "Quantità",
			},
			{
				value: "",
				label: "Tipo",
			},
			{
				value: "",
				label: "Nome",
			},
			{
				value: "",
				label: "Descrizione",
			},
		],

		biglietti_multipli: [],
		service: [],
		agents: [],
		additional_notes: "",

		image: "",
		method: "",
		action: "",
	});

	const [modalShow, setModalShow] = useState(false);

	const getOrder = async () => {
		try {
			setLoading(true);

			let body = {
				access_token: auth.accessToken,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.GETADMINORDERS, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response?.data?.message);
				setLoading(false);
			} else {
				setOrders(response.data.orders);
				setAllData(response.data.allData);

				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 400) {
				setErrMsg("Missing Username or Password");
			} else if (err.response?.status === 401) {
				setErrMsg("Unauthorized");
			} else {
				console.log(err);
				setErrMsg("Errore di caricamemento dati");
				// console.log(err);
			}
		}
	};

	const modifyPaidState = async (id) => {
		if (window.confirm("Vuoi modificare lo stato di questo ordine?") === true) {
			try {
				setLoading(true);
				let body = {
					access_token: auth.accessToken,
					id: id,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINSETPAIDUSER, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setErrMsg(response?.data?.message);
					toast.error(response?.data?.message);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success(`Modificato lo stato dell'ordine con successo.`);
					getOrder();
				}
			} catch (err) {
				setLoading(false);
				if (!err?.response) {
					setErrMsg("No Server Response");
				} else if (err.response?.status === 400) {
					setErrMsg("Missing Username or Password");
				} else if (err.response?.status === 401) {
					setErrMsg("Unauthorized");
				} else {
					console.log(err);
					// console.log(err);
				}
			}
		}
	};

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear(),
			hour = "" + d.getHours(),
			minutes = "" + d.getMinutes(),
			seconds = "" + d.getSeconds();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		if (hour.length < 2) hour = "0" + hour;
		if (minutes.length < 2) minutes = "0" + minutes;
		if (seconds.length < 2) seconds = "0" + seconds;

		return [day, month, year].join("-") + " " + [hour, minutes].join(":");
	};

	// const data = React.useMemo(() => );

	const ordersData = useMemo(() => [...orders], [orders]);

	const ordersColumns = useMemo(
		() =>
			orders[0]
				? Object.keys(orders[0])
						.filter((key) => key !== "id" && key !== "pagato")
						.map((key) => {
							if (key === "totale")
								return {
									Header: key,
									accessor: key,
									Cell: ({value}) => (
										<span>
											{" "}
											{Intl.NumberFormat("de-DE", {
												style: "currency",
												currency: "EUR",
											}).format(value)}{" "}
										</span>
									),
									maxWidth: 70,
								};

							if (key === "data")
								return {
									Header: "Data ordine",
									accessor: key,
									maxWidth: 70,
									Cell: ({value}) => <span>{formatDate(value)}</span>,
								};

							return {Header: key, accessor: key};
						})
				: [],
		[orders],
	);

	useEffect(() => {
		getOrder();
	}, []);

	const viewModal = (id) => {
		let searchOrder = alldata.find((item) => parseInt(item.id) === parseInt(id));

		setFormData({
			...formdata,

			fields: [
				{
					...formdata.fields[0],
					value: searchOrder.id,
				},
				{
					...formdata.fields[1],
					value: searchOrder.total_price,
					disable: true,
				},
				{
					...formdata.fields[2],
					value: searchOrder.codice,
					disable: true,
				},
				{
					...formdata.fields[3],
					value: searchOrder.payment_method,
					disable: true,
				},
				{
					...formdata.fields[4],
					value: searchOrder.paypal_cart,
					disable: true,
				},
				{
					...formdata.fields[5],
					value:
						parseInt(searchOrder.paid) === 1 ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								style={{
									color: "green",
									backgroundColor: "#fff",
									padding: "3px",
									fontSize: "20px",
								}}
							/>
						) : (
							<FontAwesomeIcon
								icon={faXmark}
								style={{
									color: "red",
									backgroundColor: "#fff",
									padding: "3px",
									fontSize: "20px",
								}}
							/>
						),
					disable: true,
				},
				{
					...formdata.fields[6],
					value: searchOrder.name,
					disable: true,
				},
				{
					...formdata.fields[7],
					value: searchOrder.surname,
					disable: true,
				},
				{
					...formdata.fields[8],
					value: searchOrder.email,
					disable: true,
				},
				{
					...formdata.fields[9],
					value: searchOrder.mobile_number,
					disable: true,
				},
				{
					...formdata.fields[10],
					value: searchOrder.address,
					disable: true,
				},
				{
					...formdata.fields[11],
					value: searchOrder.fiscal_code,
					disable: true,
				},
				{
					...formdata.fields[12],
					value: searchOrder.azienda === 1 ? "Azienda" : "Privato",
					disable: true,
				},
				{
					...formdata.fields[13],
					value: searchOrder.congress_name,
					disable: true,
				},
				{
					...formdata.fields[14],
					value: formatDate(searchOrder.data_ora),
					disable: true,
				},
				{
					...formdata.fields[15],
					value: searchOrder.zipcode,
					disable: true,
				},
				{
					...formdata.fields[16],
					value: searchOrder.province,
					disable: true,
				},
				{
					...formdata.fields[17],
					value: searchOrder.city,
					disable: true,
				},
				{
					...formdata.fields[18],
					value: searchOrder.sdi,
					disable: true,
				},
				{
					...formdata.fields[19],
					value: searchOrder.pec,
					disable: true,
				},
				{
					...formdata.fields[20],
					value: searchOrder.country,
					disable: true,
				},
				{
					...formdata.fields[21],
					value: searchOrder.medical_specialty,
					disable: true,
				},
			],

			orders_row: [
				{
					...formdata.order_row[0],
					value: searchOrder.order_row.price,
				},
				{
					...formdata.order_row[1],
					value: searchOrder.order_row.quantity,
				},
				{
					...formdata.order_row[2],
					value: searchOrder.order_row.type,
				},
				{
					...formdata.order_row[3],
					value: searchOrder.order_row.name,
				},
				{
					...formdata.order_row[4],
					value: searchOrder.order_row.description,
				},
			],
			additional_notes: searchOrder?.additional_notes,

			image: searchOrder?.image_url,
			service: searchOrder?.service,
			biglietti_multipli: searchOrder?.biglietti_multipli,
			agents: searchOrder?.agents,
			method: "view",
			action: "Visualizza ordine n°" + searchOrder.codice,
		});

		setModalShow(true);
	};

	const NewModal = () => {
		setFormData({
			...formdata,

			fields: [
				{
					...formdata.fields[0],
					value: "",
				},
				{
					...formdata.fields[1],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[2],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[3],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[4],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[5],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[6],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[7],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[8],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[9],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[10],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[11],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[12],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[13],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[13],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[14],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[15],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[16],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[17],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[18],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[19],
					value: "",
					disable: false,
				},
				{
					...formdata.fields[20],
					value: "",
					disable: false,
				},
			],
			additional_notes: "",

			image: "",
			service: "",
			biglietti_multipli: "",
			method: "new",
			action: "Nuovo ordine",
		});

		setModalShow(true);
	};

	const deleteRow = async (id) => {
		if (window.confirm("Vuoi eliminare questo ordine?") === true) {
			try {
				setLoading(true);
				let body = {
					access_token: auth.accessToken,
					id: id,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.DELETEORDERS, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setErrMsg(response?.data?.message);
					toast.error(response?.data?.message);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success(`Ordine eleminato con successo.`);
					getOrder();
				}
			} catch (err) {
				setLoading(false);
				if (!err?.response) {
					setErrMsg("No Server Response");
				} else if (err.response?.status === 400) {
					setErrMsg("Missing Username or Password");
				} else if (err.response?.status === 401) {
					setErrMsg("Unauthorized");
				} else {
					console.log(err);
					// console.log(err);
				}
			}
		}
	};

	const getLog = async (id) => {
		try {
			let body = {
				id: id,
				access_token: auth.accessToken,
				type: "",
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINGETLOG, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				toast.error(response?.data?.message);
				setLoading(false);
			} else {
				setLogdata(response?.data?.log || []);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				toast.error("Errore del server");
			} else if (err.response?.status === 400) {
				toast.error("Errore 400");
			} else if (err.response?.status === 401) {
				toast.error("Errore 401");
			} else {
				console.log(err);
				toast.error("Errore di caricamento dei dati");
			}
		}

		setLogModalShow(true);
	};

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: "Paid",
				Header: "Pagato",
				Cell: ({row}) =>
					parseInt(row.original.pagato) === 1 ? (
						<div className='text-center'>
							<FontAwesomeIcon
								icon={faCheckCircle}
								style={{color: "green"}}
								onClick={() => modifyPaidState(row.original.id)}
								cursor={"pointer"}
							/>
						</div>
					) : (
						<div className='text-center'>
							<FontAwesomeIcon
								icon={faXmark}
								style={{color: "red"}}
								onClick={() => modifyPaidState(row.original.id)}
								cursor={"pointer"}
							/>
						</div>
					),
			},
			{
				id: "Visualize",
				Header: "Visualizza",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faEye}
							onClick={() => viewModal(row.original.id)}
							className='hover:text-red-500'
							cursor={"pointer"}
						/>
					</div>
				),
			},
			// {
			// 	id: "Edit",
			// 	Header: "Edit",
			// 	Cell: ({ row }) => (
			// 		<FontAwesomeIcon
			// 			icon={faEdit}
			// 			onClick={() => setModalShow(true)}
			// 			className='hover:text-red-500'
			// 		/>
			// 	),
			// },
			{
				id: "register",
				Header: "Log Azioni",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faFileInvoice}
							onClick={() => getLog(row.original.id)}
							className='hover:text-red-500'
							cursor={"pointer"}
						/>{" "}
						{/* {send !== "" ? send : ""} */}
					</div>
				),
			},
			{
				id: "Delete",
				Header: "Delete",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTrash}
							onClick={() => deleteRow(row.original.id)}
							className='hover:text-red-500'
							cursor={"pointer"}
						/>
					</div>
				),
			},
			// {
			// 	id: "Esporta",
			// 	Header: "Esporta",
			// 	Cell: ({ row }) => (
			// 		<FontAwesomeIcon icon={faFile} className='hover:text-red-500' />
			// 	),
			// },
			// {
			// 	id: "qr_code",
			// 	Header: "Invia qrcode",
			// 	Cell: ({ row }) => (
			// 		<button onClick={() => console.log(row.original.id)}>invia qrcode</button>
			// 	),
			// },
		]);
	};

	const tableInstance = useTable(
		{
			columns: ordersColumns,
			data: ordersData,
			initialState: {pageIndex: 0, pageSize: 25},
		},
		useGlobalFilter,
		tableHooks,
		useSortBy,
		usePagination,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		preGlobalFilteredRows,
		setGlobalFilter,
		state,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		page,
	} = tableInstance;
	const {pageIndex, pageSize} = state;

	return (
		<div
			className='container'
			style={{marginTop: "140px", marginBottom: "100px"}}>
			<AdminNav />

			{/* <Button onClick={() => navigate(-1)}>
						<span> Torna indietro</span>
					</Button> */}
			{/* <Button onClick={() => NewModal()}> Inserisci Nuovo Ordine </Button> */}

			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				setGlobalFilter={setGlobalFilter}
				globalFilter={state.globalFilter}
			/>

			<div className='py-3'>
				<Button onClick={() => NewModal()}> Crea un nuovo ordine</Button>
			</div>

			{loading ? (
				<Loading />
			) : (
				<div>
					{orders.length !== 0 ? (
						<div>
							<Table
								{...getTableProps()}
								striped
								bordered
								hover
								responsive
								variant='light'>
								<thead>
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													{...column.getHeaderProps(column.getSortByToggleProps())}
													style={{
														borderBottom: "solid 3px ",
														color: "black",
														fontWeight: "bold",
														fontSize: "20px",
														textTransform: "capitalize",
													}}>
													{column.render("Header")}
													{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
												</th>
											))}
										</tr>
									))}
								</thead>

								{/* <tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row);

							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td
												{...cell.getCellProps()}
												style={{
													padding: "5px",

													border: "solid 1px gray",
												}}>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody> */}
								<tbody {...getTableBodyProps()}>
									{page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
												})}
											</tr>
										);
									})}
								</tbody>
							</Table>
							<Card>
								<div className='responsive-pagination'>
									<div>
										<button
											className='btn btn-light'
											onClick={() => gotoPage(0)}
											disabled={!canPreviousPage}>
											{"<<"}
										</button>{" "}
										<button
											className='btn btn-light mx-1'
											onClick={() => previousPage()}
											disabled={!canPreviousPage}>
											Precedente
										</button>{" "}
										<button
											className='btn btn-light mx-1'
											onClick={() => nextPage()}
											disabled={!canNextPage}>
											Successiva
										</button>{" "}
										<button
											onClick={() => gotoPage(pageCount - 1)}
											disabled={!canNextPage}
											className='btn btn-light mx-1'>
											{">>"}
										</button>{" "}
									</div>
									<div className='float-end'>
										<span className='w-100'>
											Pagina:{" "}
											<strong>
												{pageIndex + 1} di {pageOptions.length}
											</strong>{" "}
										</span>
									</div>
									<div className='text-center'>
										<span>
											Vai alla pagina{" "}
											<input
												type='number'
												defaultValue={pageIndex + 1}
												onChange={(e) => {
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
													gotoPage(pageNumber);
												}}
												className='d-flex justify-content-center mx-auto responsive-pagination'
											/>
										</span>{" "}
									</div>
									<select
										className='form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination'
										value={pageSize}
										onChange={(e) => setPageSize(Number(e.target.value))}>
										{[10, 25, 50, 75].map((pageSize) => (
											<option key={pageSize} value={pageSize}>
												Visualizza {pageSize} righe
											</option>
										))}
									</select>
								</div>
							</Card>
						</div>
					) : (
						<div>Nessun ordine presente</div>
					)}
				</div>
			)}

			{errMsg && <p className='fs-1 fw-bolder danger'> {errMsg}</p>}
			<OrderFormModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				data={formdata}
				setFormData={setFormData}
				accessToken={auth.accessToken}
				getOrder={() => getOrder()}
			/>

			<LogOrderModal
				show={logModalShow}
				onHide={() => setLogModalShow(false)}
				onConfirm={() => setLogModalShow(false)}
				action={"Storico ordine"}
				data={logdata}
			/>
		</div>
	);
}

export default AdminOrder;
