import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Globals from "../../parameter/globals";
import axios from "axios";
import { useContext } from "react";
import CartContext from "../../context/CartProvider";
import Loading from "../../components/Loading";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";

function EventTicket() {
	let { id } = useParams();
	let location = useLocation();
	const { onAdd, cartItems } = useContext(CartContext);
	const navigate = useNavigate();
	const { lang } = useContext(AuthContext);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [noEng, setNoEng] = useState(false);

	const [events, setEvents] = useState({
		id: "",
		address: "",
		city: "",
		description: "",
		name: "",
		start_date: "",
		end_date: "",
		img_url: "",
		price: "",
		category: "Evento",
		short_description: "",
		active_note_extra: "",
		title_note: "",
		desc_note: "",
		limit: false,
		tickets: false,
		additional_notes: "",
		agents: [],
	});

	useEffect(() => {
		setLoading(true);
		if (noEng) {
			alert("This event is not available in english.");
			navigate("/home");
			setLoading(false);
		} else {
			getEvents();
		}
	}, [location, lang]);

	const [agents, setAgents] = useState([]);
	const [addedAgent, setAddedAgent] = useState([]);
	const [alreadyAgents, setAlreadyAgents] = useState([]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
	// 	  }, 200);

	//   }, [location.pathname])

	useEffect(() => {
		setEvents({ ...events, agents: addedAgent });
	}, [addedAgent]);

	useEffect(() => {
		scrollToIdElement();
	}, [location.hash]);

	const scrollToIdElement = async () => {
		const { hash } = window.location;
		if (hash !== "") {
			// Push onto callback queue so it runs after the DOM is updated,
			// this is required when navigating from a different page so that
			// the element is rendered on the page before trying to getElementById.
			setTimeout(() => {
				const id = hash.replace("#", "");
				// const yOffset =250;
				const yOffset = -150;
				const element = document.getElementById(id);
				const y =
					element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: "smooth" });
				// const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				// window.scrollTo({top: y, behavior: 'smooth'});
				// 	if (element) (element).scrollIntoView({
				// 		top: 150,
				//   });
			}, 200);
		}
	};

	const escludeAgents = (element) => {
		setAddedAgent([element]);
	};

	const getEvents = async () => {
		let cartStorage = (await cartItems[0]) === undefined ? false : cartItems[0];
		try {
			let body = {
				id: id,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETEVENTDETAILS + "&lang=" + lang,
				formdata,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				}
			);

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
				setLoading(false);
			} else {
				setEvents({
					...events,
					id: response?.data?.events?.id,
					address: response?.data?.events?.address,
					city: response?.data?.events?.city,
					description: response?.data?.events?.description,
					name: response?.data?.events?.name,
					start_date: response?.data?.events?.start_date,
					end_date: response?.data?.events?.end_date,
					img_url: response?.data?.image,
					price: parseFloat(response?.data?.events?.price),
					short_description: response?.data?.events?.short_description,
					active_note_extra: response?.data?.events?.active_note_extra,
					title_note: response?.data?.events?.title_note,
					desc_note: response?.data?.events?.desc_note,
					limit: response?.data?.events?.limit,
					additional_notes:
						parseInt(cartStorage?.id) === parseInt(id)
							? cartStorage?.additional_notes
							: "",
					agents:
						parseInt(cartStorage.id) === parseInt(id) ? cartStorage.agents : [],
				});
				setAgents(response?.data?.agents);
				setNoEng(parseInt(response?.data?.congress?.lang_en) === 1 ? false : true);

				setTimeout(() => {
					scrollToIdElement();
				}, 250);

				setLoading(false);
			}
			if (cartStorage !== false) {
				if (parseInt(cartStorage.id) === parseInt(id)) {
					setAlreadyAgents(cartStorage.agents);
				}
			}
		} catch (err) {
			setError(true);
			setMessage(err);

			setLoading(false);
		}
	};
	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		return month;
	};

	const toPaymentSummary = async () => {
		if ((await onAdd(events, 1)) === true) {
			setLoading(false);
			navigate("/cart");
		} else {
			setLoading(false);
			setMessage(Globals.language.errorLabel.serverError[lang]);
			setError(true);
			return;
		}
	};

	if (loading) {
		return <Loading />;
	} else {
		return (
			// <div className='container'>
			// 	<article style={{ marginTop: "10%" }}>
			// 		<h1> Acquisto biglietti e servizi per l'evento {events.name}</h1>

			// 		<img
			// 			src={events.img_url}
			// 			className='col-lg-5 col-md-5 col-sm-5 img-responsive image-card-congressi'
			// 			alt='...'
			// 		/>
			// 		<h2>{events.name} </h2>
			// 		<p>Data inizio:{events.start_date} </p>
			// 		<p>Data fine: {events.end_date} </p>

			// 		<p>
			// 			Luogo : {events.address} - {events.city}{" "}
			// 		</p>
			// 	</article>

			// 	{error && <p className='fs-1 fw-bolder danger'> {message}</p>}
			// 	<button onClick={() => toPaymentSummary()}>Conferma </button>
			// </div>
			<div className='container' style={{ marginBottom: "150px" }} id='detail'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{Globals.language.title.serviceAdded[lang]}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<article className='div-general-ticket' style={{ marginTop: "120px" }}>
					<Card className='py-2 mx-1'>
						<h1>{events.name}</h1>
					</Card>
					<div className='d-flex justify-content-center align-items-center py-5 responsive-services-container'>
						<div className='col-lg-7 col-md-6 col-sm-12'>
							<img
								src={events.img_url}
								className='col-lg-5 col-md-5 col-sm-5 img-responsive justify-content-center image-card-ticket'
								alt='...'
							/>
						</div>
						<div className='col-lg-5 col-md-6 col-sm-12 responsive-description'>
							<div className='mx-2 div-top-align-title'>
								<div className='' style={{ borderBottom: "1px solid black" }}>
									<h2 className='text-danger text-uppercase title-tickets'>
										{events.name}{" "}
									</h2>
								</div>
								<p className='text-secondary py-1'>
									Data inizio:{" "}
									<span className='text-dark mx-3'>
										{formatDay(events.start_date)}-{formatMonth(events.start_date)}-
										{formatYear(events.start_date)}{" "}
									</span>{" "}
								</p>
								<p className='text-secondary py-1'>
									Data fine:{" "}
									<span className='text-dark mx-3'>
										{formatDay(events.end_date)}-{formatMonth(events.end_date)}-
										{formatYear(events.end_date)}
									</span>{" "}
								</p>
								<p className='text-secondary py-1'>
									Luogo:{" "}
									<span className='text-dark mx-3'>
										{events.address} - {events.city}
									</span>{" "}
								</p>
								<p
									className='text-dark py-1  text-light text-center w-50 mx-auto'
									style={{ borderBottom: "1px solid #d50037" }}>
									{Globals.language.ticketService.priceEvent[lang]}:{" "}
									<span className='text-dark mx-1'>
										{events.price} €{" "}
										{parseInt(events.price) === 0 ? (
											<span></span>
										) : (
											<span className='mx-1' style={{ fontSize: "15px" }}>
												+ {Globals.language.ticketService.vat[lang]}{" "}
											</span>
										)}
									</span>{" "}
								</p>
							</div>
						</div>
					</div>
				</article>
				<div className='py-3'>
					{agents.length !== 0 ? (
						<div className='py-3'>
							<h2>{Globals.language.ticketService.selectAgents[lang]} </h2>
						</div>
					) : (
						<div></div>
					)}
					<section className='agents-btn'>
						{agents.length !== 0 &&
							agents.map((element, index) => {
								return (
									<div className='section-div' key={index}>
										<input
											className='radio-btn'
											type='radio'
											name='select'
											id={element.id}
											onChange={(e) => escludeAgents(element)}
											defaultChecked={
												alreadyAgents.find((i) => parseInt(i.id) === parseInt(element.id))
													? true
													: false
											}
										/>
										<label className='label-agents' htmlFor={element.id}>
											<h2>{element.name}</h2>
											<p>{element.surname}</p>
										</label>
									</div>
								);
							})}
					</section>
				</div>
				<div className='py-3'>
					<Card className='d-flex justify-content-center align-items-center mx-auto py-2 shadow shadow-danger'>
						<label for='noteTextBox'>
							{Globals.language.ticketService.additionalNotes[lang]}
						</label>
						<textarea
							className='form-control'
							id='noteTextBox'
							value={events.additional_notes}
							onChange={(e) =>
								setEvents({ ...events, additional_notes: e.target.value })
							}
							rows='5'></textarea>
					</Card>
				</div>

				{events.limit === true ? (
					<span> {Globals.language.ticketService.notBuy[lang]} </span>
				) : (
					<button
						className='btn btn-danger rounded-0 btn-lg float-end text-light btn-conferma-ticket '
						onClick={() => toPaymentSummary()}>
						{Globals.language.ticketService.buttonConferma[lang]}{" "}
					</button>
				)}
			</div>
		);
	}
}

export default EventTicket;
