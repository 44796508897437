import {useRef, useState, useEffect} from "react";
import {useContext} from "react";
import axios from "axios";
import {useNavigate, useLocation} from "react-router-dom";
import Globals from "../../parameter/globals";
import useAuth from "../../hooks/useAuth";
import "../../scss/login.scss";
import {toast} from "react-hot-toast";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import CartContext from "../../context/CartProvider";
import img1 from "../../images/login_images/001.png";
import img2 from "../../images/login_images/002.png";
import img3 from "../../images/login_images/003.png";
import {Helmet} from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import {Link} from "react-router-dom";

const LOGIN_URL = Globals.LOGIN;

const Login = (props) => {
	const {setAuth} = useAuth();
	const {lang} = useContext(AuthContext);

	const {cartItems, totalPrice} = useContext(CartContext);
	const navigate = useNavigate();
	const location = useLocation();

	const from = location.state?.from?.pathname || "/";

	const OnlyForm = props.OnlyForm || false;

	const userRef = useRef();
	const errRef = useRef();

	const [user, setUser] = useState("");
	const [pwd, setPwd] = useState("");
	const [errMsg, setErrMsg] = useState(false);

	const [passwordShown, setPasswordShown] = useState(false);
	const [pswrecovery, passwordRecoveryEmail] = useState("");
	const [success, setSuccess] = useState(false);
	const [codeOtp, setCodeOtp] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const [show, setShow] = useState(false);
	const [showOtp, setShowOtp] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleCloseOtp = () => setShowOtp(false);
	const handleShowOtp = () => setShowOtp(true);
	const handleCloseSuccess = () => setsuccessChangePwd(false);

	const [successChangePwd, setsuccessChangePwd] = useState(false);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	useEffect(() => {
		userRef.current.focus();
	}, []);

	useEffect(() => {
		setErrMsg("");
	}, [user, pwd]);

	const startPasswordRecovery = async (e) => {
		e.preventDefault();

		try {
			let body = {
				email: pswrecovery,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.POSTPASSWORD + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
				setShow(true);
				setShowOtp(false);
				setsuccessChangePwd(false);
			} else {
				//clear state and controlled inputs
				setErrMsg(false);

				setSuccess(true);
				setShow(false);
				setShowOtp(true);
			}
		} catch (err) {
			setSuccess(false);

			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const changePassword = async (e) => {
		e.preventDefault();

		try {
			let body = {
				recovery_code: codeOtp,
				newPassword: newPassword,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.CHANGEPASSWORD + "&lang=" + lang,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);
			// TODO: remove console.logs before deployment
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setSuccess(true);
				handleCloseOtp(false);
				setShowOtp(false);
				setsuccessChangePwd(true);
			}
		} catch (err) {
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			let body = {
				user: user,
				pass: pwd,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(LOGIN_URL + "&lang=" + lang, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			const accessToken = response?.data?.access_token;
			const roles = response?.data?.roles;
			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
			} else {
				setUser("");
				setPwd("");
				setAuth({user, pwd, roles, accessToken});
				localStorage.setItem("accessToken", accessToken);
				if (OnlyForm === false) {
					if (Object.keys(cartItems).length !== 0) {
						navigate("/cart");
						// setTimeout(() => {
						// 	window.location.reload();
						//   }, 200);
					} else {
						if (roles === "admin") {
							navigate("/homeadmin", {replace: true});
						} else {
							navigate(from, {replace: true});
						}
					}
				}
				toast.success(`${Globals.language.toastMessage.welcome[lang]} ${user}.`);
			}
		} catch (err) {
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
			errRef.current.focus();
		}
	};

	if (OnlyForm) {
		return (
			<div className='col-md-5 pe-0'>
				<h3 className='mb-3'>{Globals.language.loginPage.paymentRequest[lang]} </h3>

				<div className='form-left h-100 py-5 px-5'>
					<form onSubmit={handleSubmit} className='row g-4'>
						<div className='col-12'>
							<label htmlFor='email'>
								Email
								<span className='text-danger flex-start'>*</span>
							</label>
							<div className='input-group'>
								<div className='input-group-text'>
									<i className='bi bi-person-fill'></i>
								</div>
								<input
									type='text'
									id='username'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setUser(e.target.value)}
									value={user}
									required
									className='form-control'
									placeholder={Globals.language.loginPage.emailPlaceholder[lang]}
								/>
							</div>
						</div>

						<div className='col-12'>
							<label htmlFor='password'>
								Password<span className='text-danger'>*</span>
							</label>
							<div className='input-group'>
								<div className='input-group-text'>
									<i className='bi bi-lock-fill'></i>
								</div>
								<input
									type='password'
									id='password'
									onChange={(e) => setPwd(e.target.value)}
									value={pwd}
									required
									className='form-control'
									placeholder={Globals.language.loginPage.passwordPlaceholder[lang]}
								/>
							</div>
						</div>

						<div className='col-sm-6'>
							<div className='form-check'>
								<input
									className='form-check-input d-none'
									type='checkbox'
									id='inlineFormCheck'
								/>
								<label className='form-check-label' htmlFor='inlineFormCheck'></label>
							</div>
						</div>

						{/* <div className='col-sm-6'>
							<Button
								variant='link'
								className='btn btn-link b-0 float-end text-primary'
								onClick={handleShow}>
								Password dimenticata?
							</Button>
						</div> */}
						<div
							ref={errRef}
							className={errMsg ? "errmsg" : "offscreen"}
							style={{color: "red", textAlign: "center"}}
							aria-live='assertive'>
							{errMsg}
						</div>
						<div className='col-12'>
							<button type='submit' className='btn btn-danger px-4 float-end mt-4'>
								{Globals.language.loginPage.buttonEntra[lang]}
							</button>
							<p className='text-start text-secondary  mt-4'>
								{Globals.language.loginPage.notRegister[lang]}
								<Link to='/register'>
									<a className='mx-1' href='/register'>
										{Globals.language.loginPage.registrati[lang]}
									</a>
								</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		);
	} else {
		return (
			<div className='app'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{Globals.language.title.login[lang]}</title>

					<link rel='canonical' href='https://www.soluzioniomniamedia.com/login' />
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className='login-page bg-none'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-10 offset-lg-1'>
								<div className='bg-white shadow rounded'>
									<div className='row'>
										<div className='col-md-7 pe-0 full-w-login'>
											<h3 className='mb-3'>
												{Globals.language.loginPage.welcomeArea[lang]}
											</h3>

											<div className='form-left h-100 py-5 px-5'>
												<form onSubmit={handleSubmit} className='row g-4'>
													<div className='col-12'>
														<label htmlFor='username'>
															Email
															<span className='text-danger flex-start'>*</span>
														</label>
														<div className='input-group'>
															<div className='input-group-text'>
																<i className='bi bi-person-fill'></i>
															</div>
															<input
																type='text'
																id='username'
																ref={userRef}
																autoComplete='off'
																onChange={(e) => setUser(e.target.value)}
																value={user}
																required
																className='form-control'
																placeholder={Globals.language.loginPage.emailPlaceholder[lang]}
															/>
														</div>
													</div>

													<div className='col-12'>
														<label htmlFor='password'>
															Password<span className='text-danger'>*</span>
														</label>
														<div className='input-group'>
															<div className='input-group-text'>
																<i className='bi bi-lock-fill'></i>
															</div>
															<input
																type={passwordShown ? "text" : "password"}
																id='password'
																onChange={(e) => setPwd(e.target.value)}
																value={pwd}
																required
																className='form-control'
																placeholder={
																	Globals.language.loginPage.passwordPlaceholder[lang]
																}
															/>
															<div
																className='input-group-text '
																style={{cursor: "pointer"}}
																onClick={togglePassword}>
																<i
																	className={
																		passwordShown ? "bi bi-eye" : "bi bi-eye-slash"
																	}></i>
															</div>
														</div>
													</div>

													<div className='col-sm-6'>
														<div className='form-check'>
															<input
																className='form-check-input d-none'
																type='checkbox'
																id='inlineFormCheck'
															/>
															<label
																className='form-check-label'
																htmlFor='inlineFormCheck'></label>
														</div>
													</div>

													<div className='col-sm-6'>
														<Button
															variant='link'
															className='btn btn-link b-0 float-end text-primary'
															onClick={handleShow}>
															{Globals.language.loginPage.pwdDimenticata[lang]}
														</Button>
													</div>
													<Modal
														size='md'
														aria-labelledby='contained-modal-title-vcenter'
														centered
														show={show}
														onHide={handleClose}>
														<Modal.Header closeButton>
															<Modal.Title>
																{Globals.language.loginPage.recuperoPasswordTitle[lang]}
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															{Globals.language.loginPage.recuperoPasswordDesc[lang]}
														</Modal.Body>
														<Modal.Body>
															<Form.Label>Email</Form.Label>
															<Form.Control
																type='email'
																placeholder={Globals.language.loginPage.emailPlaceholder[lang]}
																autoFocus
																className='form-control'
																value={pswrecovery}
																onChange={(e) => passwordRecoveryEmail(e.target.value)}
															/>
															<div
																ref={errRef}
																className={errMsg ? "errmsg" : "offscreen"}
																style={{color: "red", textAlign: "center"}}
																aria-live='assertive'>
																{errMsg}
															</div>
														</Modal.Body>
														<Modal.Footer>
															{/*<Button variant='secondary' onClick={handleClose}>*/}
															{/*	Chiudi*/}
															{/*</Button>*/}
															<Button variant='success' onClick={startPasswordRecovery}>
																{Globals.language.loginPage.buttonConferma[lang]}
															</Button>
														</Modal.Footer>
													</Modal>

													<Modal
														size='md'
														aria-labelledby='contained-modal-title-vcenter'
														centered
														show={successChangePwd}
														onHide={handleCloseSuccess}>
														<Modal.Header>
															<Modal.Title>
																{Globals.language.loginPage.successChangePwd[lang]}
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															{" "}
															<h2 className='text-center'>
																{Globals.language.loginPage.operationCompleted[lang]}{" "}
																<i
																	className='bi bi-check-circle-fill '
																	style={{fontSize: "30px", color: "green"}}></i>
															</h2>
														</Modal.Body>
														<Modal.Body></Modal.Body>
														<Modal.Footer>
															{/*<Button variant='secondary' onClick={handleCloseSuccess}>*/}
															{/*	Chiudi*/}
															{/*</Button>*/}
															<Button variant='danger' onClick={handleCloseSuccess}>
																{Globals.language.loginPage.goToLogin[lang]}
															</Button>
														</Modal.Footer>
													</Modal>
													<Modal centered show={showOtp} onHide={handleCloseOtp}>
														<Modal.Header>
															<Modal.Title>
																{Globals.language.loginPage.changePassword[lang]}
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															{Globals.language.loginPage.changePasswordDesc[lang]}
														</Modal.Body>
														<Modal.Body>
															<Form>
																<Form.Group
																	className='mb-3'
																	controlId='exampleForm.ControlInput1'>
																	<Form.Label>
																		{Globals.language.loginPage.code[lang]}
																	</Form.Label>
																	<Form.Control
																		type='text'
																		placeholder={Globals.language.loginPage.code[lang]}
																		autoFocus
																		value={codeOtp}
																		onChange={(e) => setCodeOtp(e.target.value)}
																	/>
																	<Form.Label>
																		{Globals.language.loginPage.newPassword[lang]}
																	</Form.Label>
																	<Form.Control
																		type='password'
																		placeholder={Globals.language.loginPage.newPassword[lang]}
																		autoFocus
																		value={newPassword}
																		onChange={(e) => setNewPassword(e.target.value)}
																	/>
																</Form.Group>
															</Form>
															<div
																ref={errRef}
																className={errMsg ? "errmsg" : "offscreen"}
																style={{color: "red", textAlign: "center"}}
																aria-live='assertive'>
																{errMsg}
															</div>
														</Modal.Body>
														<Modal.Footer>
															{/*<Button variant='secondary' onClick={handleCloseOtp}>*/}
															{/*	Annulla*/}
															{/*</Button>*/}
															<Button variant='success' onClick={changePassword}>
																{Globals.language.loginPage.buttonConferma[lang]}
															</Button>
														</Modal.Footer>
													</Modal>
													<div
														ref={errRef}
														className={errMsg ? "errmsg" : "offscreen"}
														style={{color: "red", textAlign: "center"}}
														aria-live='assertive'>
														{errMsg}
													</div>
													<div className='col-12'>
														<button
															type='submit'
															className='btn btn-danger px-4 float-end mt-4'>
															{Globals.language.loginPage.buttonEntra[lang]}
														</button>
														<p className='text-start text-secondary  mt-4'>
															{Globals.language.loginPage.notRegister[lang]}
															<a className='mx-1' href='/register'>
																{Globals.language.loginPage.registrati[lang]}
															</a>
														</p>
													</div>
												</form>
											</div>
										</div>
										<div className='col-md-5 ps-0 d-none d-lg-block mx-auto'>
											<div className='form-right h-100 bg-light text-white text-center pt-5 border-left carousel-login'>
												<div className='px-1'>
													<Carousel>
														<Carousel.Item>
															<img className='d-block w-100' src={img1} alt='First slide' />
															{/* <Carousel.Caption>
																<h3>First slide label</h3>
																<p>
																	Nulla vitae elit libero, a pharetra augue mollis interdum.
																</p>
															</Carousel.Caption> */}
														</Carousel.Item>
														<Carousel.Item>
															<img className='d-block w-100' src={img2} alt='Second slide' />

															{/* <Carousel.Caption>
																<h3>Second slide label</h3>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
															</Carousel.Caption> */}
														</Carousel.Item>
														<Carousel.Item>
															<img className='d-block w-100' src={img3} alt='Third slide' />

															{/* <Carousel.Caption>
																<h3>Third slide label</h3>
																<p>
																	Praesent commodo cursus magna, vel scelerisque nisl
																	consectetur.
																</p>
															</Carousel.Caption> */}
														</Carousel.Item>
													</Carousel>
												</div>
												<h2 className='fs-1 text-danger' style={{color: "#d50037"}}>
													{Globals.language.loginPage.welcome[lang]}
												</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Login;
