import React from "react";
import useAuth from "../../hooks/useAuth";
import Globals from "../../parameter/globals";

function AdminArea() {
	const { auth } = useAuth();

	return (
		<div
			className='container-fluid position-relative'
			style={{
				position: "relative",
				top: "130px",
				width: "100%",
				height: "950px",
				overflow:"hidden"
			}}>
			<iframe
				className='iframe'
				style={{
					position: "relative",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					width: "100%",
					minHeight: "800px",
					height: "auto",
					border: "none",
					margin: 0,
					padding: 0,
				}}
				src={Globals.ADMINLOGIN + auth.accessToken}
				title='Area Admin'></iframe>
		</div>
	);
}

export default AdminArea;
