import "../scss/team.scss";
import React from "react";
import ReactPlayer from "react-player/youtube";
import { useContext } from "react";
import Globals from "../parameter/globals";
import AuthContext from "../context/AuthProvider";
import { Helmet } from "react-helmet";

function Team(props) {
	const text = props.chisiamo || false;
	const { lang } = useContext(AuthContext);
	const html_chisiamo = props.chisiamo.text;
	const team = props.data || [];
	return (
		<div className='App'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Chi siamo - Soluzioni Omnia media</title>
				<link rel='canonical' href='#chisiamo' />
			</Helmet>
			<div className='container' id='chisiamo'>
				<h1 className='text-center title-homepage-all'>{Globals.language.aboutUs.titleCustomers[lang]} </h1>
				<div className='container container-team' style={{ display: "flex" }}>
					<div className="overlay-how-to"></div>
					<div className='w-50 col-sm-6 col-md-4 col-lg-2 width-image-responsive'>
						<h2 className='p-2 mx-5 mobile-width-desc text-center' style={{color:"#53565a"}}>{text.title}</h2>
						<h3 className='p-2 mx-5 mobile-width-desc' style={{fontSize:"20px",color:"#53565a"}}>{text.subtext}</h3>
					</div>
					<div className='image-card-team  w-50 col-sm-6 col-md-4 col-lg-2 text-center width-image-responsive'>
						{text.youtube_active !== "1" ? (
						<img
							alt=''
							className='img-responsive image-card-how-to'
							src={text.url_image}
						/>
						):(
						<div className="video-team">
							<ReactPlayer
								className="iframe-react overlay-how-to"
								width="100%"
								height="41vh"
								url={
								"https://www.youtube-nocookie.com/embed/" +
								text.youtube_id +
								"?autoplay=1&loop=1&mute=1&playlist=" +
								text.youtube_id +
								"&showinfo=0&controls=0&rel=0&disablekb=1&fs=0"
								}
								playing={true}
								playIcon={false}
								loop={true}
								muted={true}
								stopOnUnmount={false}
								playbackRate={1}
								controls={false}
								
							/>	
						</div>
						
						)}
					</div>
				</div>
				{/*<h3 >{text.subtext}</h3>*/}
				<div className='my-5' style={{ display: "flex" }}>
					<div className='col-md-0 text-center'>
						<i
							className='bi bi-journal'
							style={{ fontSize: "30px", color: "#d50037" }}></i>
					</div>
					<div className='col-md-11 '>
						<article className='border-about-us mx-2'>
							<div
							style={{color:"#53565a"}}
								dangerouslySetInnerHTML={{ __html: html_chisiamo }}
								className='mx-3'
							/>
							{/*<p>{html_chisiamo}</p>*/}
						</article>
					</div>
				</div>
				

				{team.length !== 0 ?(

				<div className='my-5' style={{ display: "flex" }}>
					<div className='col-md-0 col-sm-0 text-center'>
						<i
							className='bi bi-person'
							style={{ fontSize: "30px", color: "#d50037" }}></i>
					</div>
					
					<div className='col-md-10'>
						<section className='section section-light mt-none mb-none border-about-us mx-2'>
							<div
								className='container'
								style={{ display: "flex", flexDirection: "row" }}>
								<strong>
									<div className='row'>
										{ 
											team.map((item) => (
												<div className='col-sm-6 col-md-4 col-lg-4'>
													<div className='square-holder-team'>
														<h1 className='title-card-team'>{item.job}</h1>
														<div style={{color:"#53565a", borderTop: "2px solid #d50037"}}>
															{item.name} {item.surname}
														</div>
														<div style={{color:"#53565a"}}>{item.email} </div>
													</div>
												</div>
											))}
										
									</div>
								</strong>
							</div>
						</section>
					</div>
				</div>
				):(<div></div>)}
			</div>
		</div>
	);
}

export default Team;
