import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 300);

	return (
		<div className='mb-5 mt-5 flex items-center'>
			<p style={{fontWeight:600}}>Ricerca Totale:</p>
			<input
				value={value || ""}
				style={{width:"100%"}}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`${count} righe...`}
			/>
		</div>
	);
}
