import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from "./context/CartProvider";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
		<BrowserRouter>
			<ScrollToTop>
				<CartProvider>
					<AuthProvider>
						<Toaster position='bottom-right' reverseOrder={false} />
						<Routes>
							<Route path='/*' element={<App />} />
						</Routes>
					</AuthProvider>
				</CartProvider>
			</ScrollToTop>
		</BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
