import React, {useState} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import {matchPath, useLocation, Link} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AdminNav() {
	const {pathname} = useLocation();

	const [showAppMenu, setShowAppScan] = useState(false);

	const AppMenu = (props) => (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
      dialogClassName="mt-5 py-5"

			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					Seleziona voce di menù per App & Scan
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row row-admin-area'>
					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminOperator' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-menu-up fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'>Operatori</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminAllScans' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>

										<hr />
										<p className='card-title lead text-light'>Tutte le scan</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveScanCongressRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<i
											className='bi bi-journal-medical fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<hr />
										<p className='card-title lead text-light'>Scan Congressi</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveScanEventsRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<i
											className='bi bi-journal-richtext fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>

										<hr />
										<p className='card-title lead text-light'>Scan Eventi</p>
									</div>
								</div>
							</Link>
						</a>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Chiudi</Button>
			</Modal.Footer>
		</Modal>
	);

	return (
		<div className='container  '>
			{/* <div className="row ">
							<div className="col-lg-12 p-5">
							<h1 className="d-flex justify-content-center"> Gestione dei contenuti</h1>
							<h2 className="d-flex justify-content-end my-auto"> <i className="bi bi-person my-auto" aria-hidden="true"></i> <p className="text-danger my-auto" style={{fontSize:"20px"}}> {auth.user}</p></h2>
							<hr />
							</div>
						</div> */}
			<div className='row row-admin-area'>
				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/admin' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0 '
								style={{backgroundColor: "#d50037", height: "80%"}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-gear fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            							<hr /> */}
									<p className='card-title lead text-light'>CMS e CRM</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/adminorder' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: pathname.includes("/adminorder") ? "#fff" : "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-clipboard2-data fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            				<hr /> */}
									<p
										className='card-title lead'
										style={{
											color: pathname.includes("/adminorder") ? "#d50037" : "#fff",
										}}>
										{" "}
										Gestione Ordini{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/archiveEventsRegistration' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor:
										pathname.includes("/archiveEventsRegistration") ||
										pathname.includes("/eventsSubscribeList")
											? "#fff"
											: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-cart-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            					<hr /> */}
									<p
										className='card-title lead'
										style={{
											color:
												pathname.includes("/archiveEventsRegistration") ||
												pathname.includes("/eventsSubscribeList")
													? "#d50037"
													: "#fff",
										}}>
										{" "}
										Gestione iscrizione eventi{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/archiveCongressRegistration' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor:
										pathname.includes("/archiveCongressRegistration") ||
										pathname.includes("/congressSubscribeList")
											? "#fff"
											: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-cart-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            						<hr /> */}
									<p
										className='card-title lead'
										style={{
											color:
												pathname.includes("/archiveCongressRegistration") ||
												pathname.includes("/congressSubscribeList")
													? "#d50037"
													: "#fff",
										}}>
										{" "}
										Gestione iscrizione congressi{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/adminUsers' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: pathname.includes("/adminUsers") ? "#fff" : "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-person-lines-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
           					 <hr /> */}
									<p
										className='card-title lead'
										style={{
											color: pathname.includes("/adminUsers") ? "#d50037" : "#fff",
										}}>
										{" "}
										Gestione utenti{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				{/* <div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/adminOperatorAgencies' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: pathname.includes("/adminOperatorAgencies") ? "#fff" : "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									<p
										className='card-title lead'
										style={{
											color: pathname.includes("/adminOperatorAgencies") ? "#d50037" : "#fff",
										}}>
										{" "}
										Gestione Operatori e Agenzie{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div> */}

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' onClick={() => setShowAppScan(true)}>
						<div
							className='card p-3 shadow text-center border-0'
							style={{
								backgroundColor: "#d50037",
								height: "80%",
							}}>
							<div className='card-body p-1'>
								{/* <i className="bi bi-person-lines-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
           					 <hr /> */}
								<p
									className='card-title lead'
									style={{
										color: "#fff",
									}}>
									{" "}
									App & Scan{" "}
								</p>
							</div>
						</div>
					</a>
				</div>
			</div>

			<AppMenu show={showAppMenu} onHide={() => setShowAppScan(false)} />
		</div>
	);
}

export default AdminNav;
