import Home from "./pages/public/Home";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Congress from "./pages/public/Congress";
import SingleEvent from "./pages/public/SingleEvent";
import NavbarSol from "./components/NavbarSol";
import Login from "./pages/public/Login";
import Register from "./pages/public/Register";
import ErrorPage from "./pages/public/ErrorPage";
import AdminArea from "./pages/admin/AdminArea";
import HomeAdmin from "./pages/admin/HomeAdmin";
import Footer from "./components/Footer";
import Unathourized from "./pages/public/Unathourized";
import RequireAuth from "./components/RequireAuth";
import HomeClient from "./pages/private/HomeClient";
import CartSol from "./pages/public/CartSol";
import axios from "axios";
import Globals from "./parameter/globals";
import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import AccountActivated from "./pages/public/AccountActivated";
import ActiveRegistration from "./pages/private/ActiveRegistration";
import UserRegistry from "./pages/private/UserRegistry";
import ArchiveCongressRegistration from "./pages/admin/ArchiveCongressRegistration";
import TicketService from "./pages/public/TicketService";
import PortfolioSol from "./pages/public/Portfolio";
import SuccessPage from "./pages/public/SuccessPage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AdminOrder from "./pages/admin/AdminOrder";
import EventTicket from "./pages/public/EventTicket";
import AdminUsers from "./pages/admin/AdminUsers";
import CondizioniUso from "./pages/public/CondizioniUso";
import CondizioniVendita from "./pages/public/CondizioniVendita";
import CookiePolicy from "./pages/public/CookiePolicy";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import CongressSubscibersList from "./pages/admin/CongressSubscribersList";
import ArchiveEventsRegistration from "./pages/admin/ArchiveEventsRegistration";
import EventsSubscribersList from "./pages/admin/EventsSubscribersList";
import Cookiebar from "./components/Cookiebar";
import ArchiveRegistration from "./pages/admin/Archiveregistration";
import ScrollToTop from "./components/ScrollToTop";
import { ReCaptchaProvider } from "react-recaptcha-x";
import AdminOperator from "./pages/admin/AdminOperator";
import CondizioniUsoApp from "./pages/public/CondizioniUsoApp.js";
import PrivacyPolicyApp from "./pages/public/PrivacyPolicyApp.js";
import CookiePolicyApp from "./pages/public/CookiePolicyApp.js";
import AdminAllScan from "./pages/admin/AdminAllScan";
import ArchiveCongressScan from "./pages/admin/ArchiveCongressScan";
import ArchiveEventsScan from "./pages/admin/ArchiveEventsScan";
import CongressScanList from "./pages/admin/CongressScanList";
import EventScanlist from "./pages/admin/EventScanList";

const ROLES = {
  User: "user",
  Admin: "admin",
};

function modifyCookie() {
  document.getElementById("container").style.display = "block";
}

function App() {
  const { setAuth } = useAuth();
  const location = useLocation();
  const [redirectList, setRedirectList] = useState("");

  const checkLogin = async () => {
    let myAccessToken = localStorage.getItem("accessToken");
    if (myAccessToken !== "") {
      try {
        let formdata = new FormData();
        formdata.append("body", myAccessToken);

        const response = await axios.post(Globals.CHECKLOGIN, formdata, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        let accessToken = myAccessToken;
        let roles = response?.data?.roles;
        let user = response?.data?.username;
        let is_public_administration = response?.data?.public_administration;
        setAuth({ user, accessToken, roles, is_public_administration });
      } catch (err) {
        setAuth({});
        localStorage.setItem("accessToken", "");
      }
    }
  };

  const getRedirectList = async () => {
    try {
      const response = await axios.get(Globals.CHECKREDIRECT301, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setRedirectList(response?.data?.redirect);
    } catch (err) {
      setRedirectList("");
    }
  };

  const checkRedirectList = async () => {
    if (redirectList.length > 0 && redirectList !== "") {
      let redirect_element =
        redirectList.find(
          (element) => element?.old_url === window.location.href
        ) !== undefined
          ? redirectList.find(
              (element) => element?.old_url === window.location.href
            )
          : false;

      if (redirect_element !== false) {
        updateRedirectTable(redirect_element);
      }
    }
  };

  const updateRedirectTable = async (redirect_element) => {
    try {
      let formdata = new FormData();
      let body = {
        id: redirect_element.id,
      };

      formdata.append("body", JSON.stringify(body));
      const response = await axios.post(
        Globals.UPDATEREDIRECTCOUNTER,
        formdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.error === false) {
        window.location.replace(redirect_element.new_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkLogin();
    getRedirectList();
  }, []);

  useEffect(() => {
    checkRedirectList();
  }, [location, redirectList]);

  return (
    <ReCaptchaProvider
      siteKeyV2={Globals.recaptcha_v2_key}
      langCode="it"
      hideV3Badge={false}
    >
      <PayPalScriptProvider
        options={{
          "client-id": Globals.paypalClientId,
          currency: "EUR",
        }}
      >
        <>
          <NavbarSol />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />

              <Route path="congress">
                <Route path=":id/:slug" element={<Congress />} />
              </Route>

              <Route path="event">
                <Route path=":id/:slug" element={<SingleEvent />} />
              </Route>

              <Route path="ticket">
                <Route path=":id/:slug" element={<TicketService />} />
              </Route>

              <Route path="ticket">
                <Route path=":id" element={<TicketService />} />
              </Route>

              <Route path="ticketevent">
                <Route path=":id/:slug" element={<EventTicket />} />
              </Route>
              <Route path="ticketevent">
                <Route path=":id" element={<EventTicket />} />
              </Route>

              <Route path="portfolio">
                <Route path="/portfolio" element={<PortfolioSol />} />
              </Route>

              <Route path="condizioni-vendita">
                <Route
                  path="/condizioni-vendita"
                  element={<CondizioniVendita />}
                />
              </Route>

              <Route path="cookie-policy">
                <Route path="/cookie-policy" element={<CookiePolicy />} />
              </Route>
              <Route path="privacy-policy">
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Route>

              <Route path="cookie-policy-app">
                <Route
                  path="/cookie-policy-app"
                  element={<CookiePolicyApp />}
                />
              </Route>
              <Route path="privacy-policy-app">
                <Route
                  path="/privacy-policy-app"
                  element={<PrivacyPolicyApp />}
                />
              </Route>
              <Route path="condizioni-uso-app">
                <Route
                  path="/condizioni-uso-app"
                  element={<CondizioniUsoApp />}
                />
              </Route>

              <Route path="condizioni-uso">
                <Route
                  path="/condizioni-uso"
                  element={<CondizioniUso />}
                />
              </Route>

              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="unauthorized" element={<Unathourized />} />
              <Route path="/cart" element={<CartSol />} />
              <Route path="/account-attivato" element={<AccountActivated />} />
              <Route path="/success" element={<SuccessPage />} />

              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.Admin, ROLES.User]} />
                }
              >
                <Route path="homeclient" element={<HomeClient />} />
                <Route
                  path="activeRegistration"
                  element={<ActiveRegistration />}
                />
                <Route path="userRegistry" element={<UserRegistry />} />
                <Route
                  path="archiveRegistration"
                  element={<ArchiveRegistration />}
                />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="/admin" element={<AdminArea />} />
                <Route path="/homeadmin" element={<HomeAdmin />} />
                <Route path="/adminorder" element={<AdminOrder />} />
                <Route path="/adminUsers" element={<AdminUsers />} />
                <Route path="/adminOperator" element={<AdminOperator />} />
                <Route path="/adminAllScans" element={<AdminAllScan />} />

                <Route
                  path="archiveScanCongressRegistration"
                  element={<ArchiveCongressScan />}
                />
                <Route
                  path="archiveScanEventsRegistration"
                  element={<ArchiveEventsScan />}
                />

                <Route
                  path="archiveCongressRegistration"
                  element={<ArchiveCongressRegistration />}
                />
                <Route
                  path="archiveEventsRegistration"
                  element={<ArchiveEventsRegistration />}
                />

                <Route path="congressSubscribeList">
                  <Route path=":id" element={<CongressSubscibersList />} />
                </Route>
                <Route path="eventsSubscribeList">
                  <Route path=":id" element={<EventsSubscribersList />} />
                </Route>

                <Route path="congressScanSubscribeList">
                  <Route path=":id" element={<CongressScanList />} />
                </Route>
                <Route path="eventScanSubscribeList">
                  <Route path=":id" element={<EventScanlist />} />
                </Route>
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </ScrollToTop>
          <Cookiebar />
          <Footer modifyCookie={modifyCookie.bind()} />
        </>
      </PayPalScriptProvider>
    </ReCaptchaProvider>
  );
}

export default App;
