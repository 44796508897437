import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import {useNavigate, Link} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../context/AuthProvider";
import "../scss/navbar.scss";
import axios from "axios";
import Globals from "../parameter/globals";
import CartContext from "../context/CartProvider";
import logoMobile from "../images/logo-mobile.svg";
import logo from "../images/logo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {useState} from "react";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {toast} from "react-hot-toast";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import InfoModal from "./InfoModal";
function NavbarSol() {
	const {auth, setAuth, lang, setLang} = useContext(AuthContext);
	const {totalQuantities, resetCart} = useContext(CartContext);
	const location = useLocation();
	const navigate = useNavigate();

	const valueStored = String(localStorage.getItem("active"));
	const [active, setActive] = useState(valueStored ? valueStored : "default");

	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		localStorage.setItem("active", String(active));
	}, [active]);

	const logout = async () => {
		try {
			let body = {
				accessToken: auth.accessToken,
			};
			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.LOGOUT, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				window.alert(Globals.language.errorLabel.logoutError[lang]);
			} else {
				toast.success(Globals.language.toastMessage.logout[lang]);
				setAuth({});
				resetCart();
				localStorage.setItem("accessToken", "");
				navigate("/home");
			}
		} catch (err) {
			setAuth({});
			resetCart();
			localStorage.setItem("accessToken", "");
			navigate("/home");
		}
		setModalShow(false);
	};

	const setLanguageAndSaveToStorage = (language) => {
		setLang(language);
		localStorage.setItem("lang", language);
	};

	return (
		<div>
			<Container>
				<Navbar.Brand
					href='/home'
					className='position-absolute logo mx-1 py-1'
					style={{zIndex: 99, left: 0, top: -20}}>
					{" "}
					<img src={logo} alt='logo soluzioni omnia media' />
				</Navbar.Brand>
			</Container>
			<Navbar
				sticky='top'
				className='position-fixed border border-danger navbar'
				style={{zIndex: 9999, right: 0, padding: 5}}
				collapseOnSelect
				expand='xxl'
				bg='light'
				variant='light'>
				<Container style={{padding: "0"}}>
					<div className='logo-text text-danger'>
						<Link to='/home'>
							<img src={logoMobile} alt='logo mobile soluzioni omnia media' />
						</Link>
					</div>
					<div
						className='logo-text w-25 m-0 p-0'
						style={{
							borderLeft: "1px solid red",
							borderRight: "1px solid red",
						}}>
						<Link to='/cart'>
							<Button variant='none' className='border-0 p-0'>
								<i className='bi bi-basket2 text-danger' style={{fontSize: 22}}></i>{" "}
								<Badge bg='danger' style={{backgroundColor: "#d50037"}}>
									{totalQuantities}
								</Badge>
							</Button>
						</Link>
					</div>
					<Navbar.Toggle
						sticky='top'
						className='position-relative m-1'
						style={{
							zIndex: 9999,
							marginLeft: "auto",
							right: 0,
							background: "#fff",
						}}
						aria-controls='responsive-navbar-nav'
					/>
					{location.pathname === "/" ? (
						<Navbar.Collapse id='responsive-navbar-nav'>
							<Nav
								className='me-auto'
								activeKey={active}
								onSelect={(selectedKey) => setActive(selectedKey)}>
								<Nav.Link className='navLink' eventKey='default'>
									{" "}
									<AnchorLink href='#home'>
										<button className='btn btn-navbar navLink'> Home </button>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='chisiamo'>
									<AnchorLink offset={() => 110} href='#chisiamo'>
										<button className='btn btn-navbar    navLink '>
											{" "}
											{Globals.language.navbar.aboutUs[lang]}{" "}
										</button>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='congress'>
									<AnchorLink offset={() => 110} href='#congress'>
										<button className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.congress[lang]}{" "}
										</button>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='events'>
									<AnchorLink offset={() => 110} href='#events'>
										<button className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.events[lang]}{" "}
										</button>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink ' eventKey='client'>
									<AnchorLink offset={() => 110} href='#client'>
										<button className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.clients[lang]}{" "}
										</button>
									</AnchorLink>
								</Nav.Link>
								{/*<Nav.Link className='navLink' href='#dovesiamo'>*/}
								{/*	<AnchorLink href='#dovesiamo'><button  className='btn btn-navbar    navLink'> Dove siamo </button></AnchorLink>*/}
								{/*</Nav.Link>*/}
								<Nav.Link className='navLink' eventKey='contact'>
									<AnchorLink offset={() => 110} href='#contact'>
										<button className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.contacts[lang]}{" "}
										</button>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='portfolio'>
									<Link className='btn btn-navbar navLink' to='/portfolio'>
										{" "}
										{Globals.language.navbar.portfolio[lang]}{" "}
									</Link>
								</Nav.Link>
								{Object.keys(auth).length !== 0 ? (
									auth.roles === "admin" ? (
										<div className='d-flex justify-content-center'>
											<Nav.Link className='border-left' eventKey='#'>
												<Link
													className='navLink btn btn-navbar    navLink w-100'
													to='/homeadmin'>
													{" "}
													{Globals.language.navbar.adminArea[lang]}{" "}
												</Link>
											</Nav.Link>
											<Nav.Link className='' eventKey='#'>
												<Link
													className='btn btn-navbar    navLink w-100'
													to='/homeclient'
													eventKey='#'>
													{" "}
													{Globals.language.navbar.userArea[lang]}{" "}
												</Link>
											</Nav.Link>
										</div>
									) : (
										<Nav.Link className='navLink border-left'>
											<Link
												className='btn btn-navbar    navLink'
												to='/homeclient'
												eventKey='#'>
												{" "}
												{Globals.language.navbar.userArea[lang]}{" "}
											</Link>
										</Nav.Link>
									)
								) : (
									<></>
								)}
							</Nav>
							<Nav className='text-center'>
								<Nav.Link className='border-left'>
									{" "}
									<Container
									className="px-0"
										fluid
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
										}}>
										{auth?.user ? (
											<>
												<span onClick={() => navigate("/homeClient")}>
												<span style={{fontSize:"16px"}}>{Globals.language.navbar.welcome[lang]}, <strong>{auth.user}</strong></span>
												</span>

												<Nav.Link eventKey='#' className='p-0'>
													<button
														className='btn btn-danger mb-1 mx-auto btn-custom-login'
														style={{backgroundColor: "#d50037"}}
														onClick={() => setModalShow(true)}>
														{" "}
														Logout{" "}
													</button>
												</Nav.Link>
											</>
										) : (
											<>
												<Nav.Link eventKey='#' className='p-0'>
													<Link
														className='btn btn-outline-danger my-1 w-100 btn-custom-login'
														to='/login'>
														{" "}
														{Globals.language.navbar.accedi[lang]}{" "}
													</Link>
												</Nav.Link>

												<Nav.Link eventKey='#' className='p-0'>
													<Link
														to='/register'
														className='btn btn-outline-danger w-100 btn-custom-login'>
														{Globals.language.navbar.registrati[lang]}
													</Link>
												</Nav.Link>
											</>
										)}
									</Container>
								</Nav.Link>

								<Nav.Link
									eventKey={2}
									className='border-left border-right'
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<Link to='/cart'>
										<Button variant='none' className='border-0 p-0'>
											<i className='bi bi-basket2 text-danger' style={{fontSize: 22}}></i>{" "}
											<Badge bg='danger' style={{backgroundColor: "#d50037"}}>
												{totalQuantities}
											</Badge>
										</Button>

										{/* {totalQuantities}{" "} */}
									</Link>
									{totalQuantities !== 0 ? (
										<button
											className='btn btn-danger'
											style={{backgroundColor: "#d50037"}}
											onClick={() => resetCart()}>
											{" "}
											{Globals.language.navbar.empty[lang]}{" "}
										</button>
									) : (
										<></>
									)}
								</Nav.Link>
								<Nav.Link
									eventKey={3}
									className='border-right'
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<span className='nav-link text-danger'>
										{" "}
										<span
											style={{
												textDecoration: lang === "it" ? "underline" : "none",
											}}
											onClick={() => setLanguageAndSaveToStorage("it")}>
											ITA{" "}
										</span>{" "}
										|{" "}
										<span
											style={{
												textDecoration: lang === "en" ? "underline" : "none",
											}}
											onClick={() => setLanguageAndSaveToStorage("en")}>
											ENG{" "}
										</span>{" "}
										<br />
									</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					) : (
						<Navbar.Collapse id='responsive-navbar-nav'>
							<Nav
								className='me-auto'
								activeKey={active}
								onSelect={(selectedKey) => setActive(selectedKey)}>
								<Nav.Link className='navLink' eventKey='default'>
									{" "}
									<AnchorLink href='#home'>
										<Link
											to={{
												pathname: "/",
												hash: "#home",
											}}
											className='btn btn-navbar navLink'>
											{" "}
											Home{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>

								<Nav.Link className='navLink' eventKey='chisiamo'>
									<AnchorLink offset={() => 110} href='#chisiamo'>
										<Link
											to={{
												pathname: "/",
												hash: "#chisiamo",
											}}
											className='btn btn-navbar    navLink '>
											{" "}
											{Globals.language.navbar.aboutUs[lang]}{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>

								<Nav.Link className='navLink' eventKey='congress'>
									<AnchorLink offset={() => 110} href='#congress'>
										<Link
											to={{
												pathname: "/",
												hash: "#congress",
											}}
											className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.congress[lang]}{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>

								<Nav.Link className='navLink' eventKey='events'>
									<AnchorLink offset={() => 110} href='#events'>
										<Link
											to={{
												pathname: "/",
												hash: "#events",
											}}
											className='btn btn-navbar navLink'>
											{" "}
											{Globals.language.navbar.events[lang]}{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='client'>
									<AnchorLink offset={() => 110} href='#client'>
										<Link
											to={{
												pathname: "/",
												hash: "#client",
											}}
											className='btn btn-navbar    navLink'>
											{" "}
											{Globals.language.navbar.clients[lang]}{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>
								{/*<Nav.Link className='navLink' href='#dovesiamo'>*/}
								{/*	<AnchorLink href='#dovesiamo'><button  className='btn btn-navbar    navLink'> Dove siamo </button></AnchorLink>*/}
								{/*</Nav.Link>*/}
								<Nav.Link className='navLink' eventKey='contact'>
									<AnchorLink offset={() => 110} href='#contact'>
										<Link
											to={{
												pathname: "/",
												hash: "#contact",
											}}
											className='btn btn-navbar    navLink'>
											{" "}
											{Globals.language.navbar.contacts[lang]}{" "}
										</Link>
									</AnchorLink>
								</Nav.Link>
								<Nav.Link className='navLink' eventKey='portfolio'>
									<Link className='btn btn-navbar navLink' to='/portfolio'>
										{" "}
										{Globals.language.navbar.portfolio[lang]}{" "}
									</Link>
								</Nav.Link>
								{Object.keys(auth).length !== 0 ? (
									auth.roles === "admin" ? (
										<div className='d-flex justify-content-center'>
											<Nav.Link className='border-left' eventKey='#'>
												<Link
													className='navLink btn btn-navbar    navLink w-100'
													to='/homeadmin'>
													{" "}
													{Globals.language.navbar.adminArea[lang]}{" "}
												</Link>
											</Nav.Link>
											<Nav.Link className='' eventKey='#'>
												<Link
													className='btn btn-navbar    navLink w-100'
													to='/homeclient'
													eventKey='#'>
													{" "}
													{Globals.language.navbar.userArea[lang]}{" "}
												</Link>
											</Nav.Link>
										</div>
									) : (
										<Nav.Link className='navLink border-left' eventKey='#'>
											<Link
												className='btn btn-navbar navLink'
												to='/homeclient'
												eventKey='#'>
												{" "}
												{Globals.language.navbar.userArea[lang]}{" "}
											</Link>
										</Nav.Link>
									)
								) : (
									<></>
								)}
							</Nav>
							<Nav className='text-center'>
								<Nav.Link variant='' className='border-left'>
									{" "}
									<Container
										fluid
										className="px-0"
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
										}}>
										{auth?.user ? (
											<>
												<span onClick={() => navigate("/homeClient")}>
													<span style={{fontSize:"16px"}}>{Globals.language.navbar.welcome[lang]}, <strong>{auth.user}</strong></span>
												</span>
												<Nav.Link eventKey='#' className='p-0'>
													<button
														className='btn btn-danger mb-1 mx-auto btn-custom-login '
														style={{backgroundColor: "#d50037"}}
														onClick={() => setModalShow(true)}>
														{" "}
														Logout{" "}
													</button>
												</Nav.Link>
											</>
										) : (
											<>
												<Nav.Link eventKey='#' className='p-0'>
													<Link
														className='btn btn-outline-danger my-1 w-100 btn-custom-login'
														to='/login'>
														{" "}
														{Globals.language.navbar.accedi[lang]}{" "}
													</Link>
												</Nav.Link>

												<Nav.Link eventKey='#' className='p-0'>
													<Link
														to='/register'
														className='btn btn-outline-danger w-100 btn-custom-login'>
														{Globals.language.navbar.registrati[lang]}
													</Link>
												</Nav.Link>
											</>
										)}
									</Container>
								</Nav.Link>

								<Nav.Link
									eventKey={2}
									href='#memes'
									className='border-left border-right'
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<Link to='/cart'>
										{totalQuantities !== 1 ? (
											<div>
												<Button variant='none' className='border-0'>
													<i
														className='bi bi-basket2 text-danger'
														style={{fontSize: 22}}></i>{" "}
												</Button>
											</div>
										) : (
											<Button variant='none' className='border-0'>
												<i className='bi bi-basket2 text-danger' style={{fontSize: 22}}></i>{" "}
												<Badge bg='danger' style={{backgroundColor: "#d50037"}}>
													{totalQuantities}
												</Badge>
											</Button>
										)}
										{/* <i className='bi bi-basket2 text-danger' style={{ fontSize: 22 }}></i>{" "}
										{totalQuantities}{" "} */}
									</Link>

									{totalQuantities !== 0 ? (
										<button
											className='btn btn-danger btn-md '
											style={{backgroundColor: "#d50037"}}
											onClick={() => resetCart()}>
											{" "}
											{Globals.language.navbar.empty[lang]}{" "}
										</button>
									) : (
										<></>
									)}
								</Nav.Link>
								<Nav.Link
									eventKey={3}
									className='border-right'
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<span className='nav-link text-danger p-0'>
										{" "}
										<span
											style={{
												textDecoration: lang === "it" ? "underline" : "none",
											}}
											className="p-0"
											onClick={() => setLanguageAndSaveToStorage("it")}>
											ITA{" "}
										</span>{" "}
										|{" "}
										<span
											style={{
												textDecoration: lang === "en" ? "underline" : "none",
											}}
											className="p-0"

											onClick={() => setLanguageAndSaveToStorage("en")}>
											ENG{" "}
										</span>{" "}
										<br />
									</span>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					)}
				</Container>
			</Navbar>

			<InfoModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				onConfirm={() => logout()}
				action={Globals.language.infoModal.logoutTitle[lang]}
				text={Globals.language.infoModal.logoutMessage[lang]}
			/>
		</div>
	);
}

export default NavbarSol;
