import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../../parameter/globals";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";

function CookiePolicy() {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const [cookiePolicy, setCookiePolicy] = useState([]);
	const { lang } = useContext(AuthContext);

	useEffect(() => {
		getPageLegal();
	}, [lang]);

	const getPageLegal = async () => {
		try {
			const response = await axios.get(Globals.GETLEGALPAGE, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setCookiePolicy(
					lang === "it"
						? response?.data?.cookie_policy?.htmlcontents_it
						: response?.data?.cookie_policy?.htmlcontents_en
				);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};

	return (
		<div
			className='container'
			style={{ marginTop: "140px", marginBottom: "100px" }}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.cookiePolicy[lang]}</title>
				<link
					rel='canonical'
					href='https://www.soluzioniomniamedia.com/cookie-policy'
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<div dangerouslySetInnerHTML={{ __html: cookiePolicy }} />
		</div>
	);
}

export default CookiePolicy;
