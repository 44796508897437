import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "../../scss/success-page.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";

function SuccessPage(props) {
  const { state } = useLocation();
  const { paypal_order_id, product, code, typePayment } = state;
  const { lang } = useContext(AuthContext);

  return (
    <div
      className="container"
      style={{ marginTop: "140px", marginBottom: "100px" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.title.success[lang]}</title>
        <link rel="canonical" href="https://www.soluzioniomniamedia.com/" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Card className="text-center">
        <Card.Header>
          <i
            className="bi bi-check2-circle text-success"
            style={{ fontSize: 100 }}
          ></i>
          <h2>{Globals.language.successPage.successMessage[lang]} </h2>
        </Card.Header>
        <Card.Body>
          {/* <h2>Ecco le informazioni di ripeilogo del tuo ordine </h2> */}
          <Card.Title>
            <h2>
              {Globals.language.successPage.reservationMade[lang]}{" "}
              <span className="text-danger"> {product[0].name}</span>
            </h2>
          </Card.Title>
          <Card.Text>
            {paypal_order_id && (
              <h2>
                {" "}
                {Globals.language.successPage.paypalOrderCode[lang]} :{" "}
                {paypal_order_id}{" "}
              </h2>
            )}

            {code && (
              <h4>
                {" "}
                {Globals.language.successPage.reservationCode[lang]} : {code}{" "}
              </h4>
            )}
            <hr></hr>
            <div className="py-3 responsive-success">
              <div className="col-lg-3 col-md-6 col-sm-12 my-auto mx-2">
                <i
                  className="bi bi-card-checklist text-success"
                  style={{ fontSize: 100 }}
                ></i>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 my-auto mx-2">
                <h4>{Globals.language.successPage.informationLabel[lang]}</h4>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-start">
                <li>{Globals.language.successPage.messageMail[lang]}</li>
                <li>
                  {typePayment === "paypal"
                    ? Globals.language.successPage.messageMailSecondPaypalOrFree[lang]
                    : Globals.language.successPage.messageMailSecondTransfer[lang]}
                  {}
                </li>
              </div>
            </div>
            <hr></hr>
            <div>
              <h2> {Globals.language.successPage.thankyouMessage[lang]}</h2>
            </div>

            {/* <p>
				{" "}
				Le ricordiamo inoltre che il qrcode è disponibile nella mail di riepilogo
				oppure nell'area privata di questo sito{" "}
			</p> */}
          </Card.Text>
          <Link to="/home">
            <Button variant="danger">
              {Globals.language.cart.backHome[lang]}
            </Button>
          </Link>
        </Card.Body>
        <Card.Footer className="text-muted">
          {Globals.language.successPage.codeOrder[lang]} {code}{" "}
        </Card.Footer>
      </Card>
    </div>
  );
}

export default SuccessPage;
