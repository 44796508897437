import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";

function AccountActivated() {
	const { lang } = useContext(AuthContext);

	return (
		<div className='container py-5' style={{ marginTop: "180px" }}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.activeAccount[lang]}</title>
				<link rel='canonical' href='https://www.soluzioniomniamedia.com/' />
				<meta name="robots" content="noindex" />

			</Helmet>
			<Card>
				<div className='row text-center d-flex justify-content-center'>
					<div className='col-sm-6 col-sm-offset-3'>
						<h2 style={{ color: "#0fad00" }}>
							{Globals.language.activeAccount.activeAccount[lang]}
						</h2>
						<i
							className='bi bi-check-circle-fill'
							style={{ fontSize: "50px", color: "green" }}></i>
						<p style={{ fontSize: "20px", color: "#5C5C5C" }}>
							{Globals.language.activeAccount.labelaccount[lang]}
						</p>
						<button className='btn btn-success btn-lg'>
							<Link className='text-light' to='/login'>
								{" "}
								{Globals.language.activeAccount.buttonLogin[lang]}{" "}
							</Link>
						</button>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default AccountActivated;
