import { useRef, useState, useEffect } from "react";
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../scss/register.scss";
import Globals from "../../parameter/globals";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import InfoModal from "../../components/InfoModal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const UserRegistry = () => {
	const { auth, setAuth, lang } = useContext(AuthContext);
	const navigate = useNavigate();

	const userRef = useRef();
	const errRef = useRef();

	const [user, setUser] = useState("");
	const [validName, setValidName] = useState(false);
	const [userFocus, setUserFocus] = useState(false);
	const [isCompany, setIsCompany] = useState("");

	const [cap, setCap] = useState("");
	const [city, setCity] = useState("");

	const [province, setProvince] = useState("");

	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [birthPlace, setBirthPlace] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [company, setCompany] = useState("");
	const [vatCode, setVatCode] = useState("");
	const [address, setAddress] = useState("");
	const [fiscalCode, setFiscalCode] = useState("");
	const [fiscalCodeCompany, setFiscalCodeCompany] = useState("");
	const [sdi, setSdi] = useState("");
	const [pec, setPec] = useState("");

	const [errMsg, setErrMsg] = useState("");
	const [success, setSuccess] = useState(false);

	const [disable, setDisable] = useState(false);

	const [modalShow, setModalShow] = useState(false);

	const getData = async () => {
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.GETUSERDATA, formdata, {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				});
				let data = response.data.data;
				setUser(data.username);
				setSurname(data.surname);
				setName(data.name);
				setEmail(data.email);
				setPhoneNumber(data.mobile_number);
				setCompany(data.company);
				setVatCode(data.vat_code);
				setDateOfBirth(data.date_of_birth);
				setBirthPlace(data.birthplace);
				setFiscalCode(data.fiscal_code);
				setAddress(data.address);
				setIsCompany(data.is_company);
				setCap(data.cap);
				setCity(data.city);
				setProvince(data.province);
				setFiscalCodeCompany(data.company_fiscal_code);
				setSdi(data.code_sdi);
				setPec(data.pec);

				setDisable(true);
			} catch (err) {
				console.log(err);
			}
		}
	};
	const [public_administration, setPublicAdministration] = useState([]);

	useEffect(() => {
		checkLogin();
	}, []);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");
		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				});

				let accessToken = myAccessToken;
				let roles = response?.data?.roles;
				let user = response?.data?.username;
				let is_public_administration = response?.data?.public_administration;
				setPublicAdministration({
					user,
					accessToken,
					roles,
					is_public_administration,
				});
			} catch (err) {
				setPublicAdministration({});
				localStorage.setItem("accessToken", "");
			}
		}
	};
	useEffect(() => {
		getData();
	}, []);

	// useEffect(() => {
	// 	userRef.current.focus();
	// }, []);

	useEffect(() => {
		setErrMsg("");
	}, [user]);

	const handleSubmit = async () => {
		try {
			let body = {
				user: user,
				name: name,
				surname: surname,
				email: email,
				phoneNumber: phoneNumber,
				company: company,
				vatCode: vatCode,
				address: address,
				fiscalCode: fiscalCode,
				birthPlace: birthPlace,
				dateOfBirth: dateOfBirth,
				cap: cap,
				city: city,
				province: province,
				fiscalCodeCompany: fiscalCodeCompany,
				sdi: sdi,
				pec: pec,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.MODIFYUSERDATA, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				console.log(response?.data?.error);
			} else {
				setSuccess(true);
				getData();
				setModalShow(true);
			}
		} catch (err) {
			if (!err?.response) {
				// setErrMsg("No Server Response");
				console.log(err);
			} else if (err.response?.status === 409) {
				setErrMsg("Username Taken");
			} else {
				setErrMsg("Registration Failed");
			}
			// errRef.current.focus();
		}
	};
	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			{Globals.language.userRegistry.tooltip[lang]}
		</Tooltip>
	);
	const deleteUser = async () => {
		if (window.confirm(Globals.language.errorLabel.deleteUser[lang]) === true) {
			let myAccessToken = localStorage.getItem("accessToken");
			if (myAccessToken !== "") {
				try {
					let formdata = new FormData();
					formdata.append("access_token", myAccessToken);

					const response = await axios.post(Globals.DELETEUSER, formdata, {
						headers: { "Content-Type": "application/json" },
						withCredentials: true,
					});

					if (response?.data?.error === true) {
						setErrMsg(response.data.message);
					} else {
						setAuth({});
						localStorage.setItem("accessToken", "");
						alert(Globals.language.userRegistry.userDelete[lang]);
						navigate("/home");
					}
				} catch (err) {
					setErrMsg(Globals.language.errorLabel.serverError[lang]);
				}
			}
		}
	};

	return (
		<div
			style={{ textAlign: "center", marginTop: "120px", marginBottom: "100px" }}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.userRegistry[lang]}</title>
			</Helmet>
			{parseInt(isCompany) === 0 ? (
				<div className='registration-page bg-none'>
					<div className='container'>
						<h2 className='text-center'>
							{Globals.language.userRegistry.accountPrivate[lang]}
						</h2>
						<div className='row shadow-lg rounded'>
							<div className='col-sm-12 form-group'>
								<label htmlFor='email'>
									{Globals.language.userRegistry.email[lang]} {""}
									<OverlayTrigger
										placement='bottom'
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}>
										<Button className='btn btn-danger py-1 my-2' variant='danger'>
											?
										</Button>
									</OverlayTrigger>
								</label>
								<input
									type='email'
									id='email'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									aria-describedby='uidnote'
									className='form-control'
									name='email'
									placeholder={Globals.language.userRegistry.emailPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='name'>{Globals.language.userRegistry.name[lang]}</label>
								<input
									type='text'
									id='name'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setName(e.target.value)}
									value={name}
									aria-describedby='uidnote'
									className='form-control'
									name='name'
									placeholder={Globals.language.userRegistry.namePlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='username'>
									{Globals.language.userRegistry.surname[lang]}
								</label>
								<input
									type='text'
									id='surname'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setSurname(e.target.value)}
									value={surname}
									aria-describedby='uidnote'
									className='form-control'
									name='surname'
									placeholder={Globals.language.userRegistry.surnamePlaceholder[lang]}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='phoneNumber'>
									{Globals.language.userRegistry.phone[lang]}
								</label>
								<input
									type='text'
									id='phoneNumber'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setPhoneNumber(e.target.value)}
									value={phoneNumber}
									aria-describedby='uidnote'
									className='form-control'
									name='phoneNumber'
									placeholder={Globals.language.userRegistry.phonePlaceholder[lang]}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='fiscalCode'>
									{Globals.language.userRegistry.birtday[lang]}
								</label>
								<input
									type='date'
									id='fiscalCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setDateOfBirth(e.target.value)}
									value={dateOfBirth}
									aria-describedby='uidnote'
									name='fiscalCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.birtdayPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='birthPlace'>
									{Globals.language.userRegistry.luogoNascita[lang]}
								</label>
								<input
									type='text'
									id='birthPlace'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setBirthPlace(e.target.value)}
									value={birthPlace}
									aria-describedby='uidnote'
									name='birthPlace'
									className='form-control'
									placeholder={
										Globals.language.userRegistry.luogoNascitaPlaceholder[lang]
									}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='fiscalCode'>
									{Globals.language.userRegistry.fiscalCode[lang]}
								</label>
								<input
									type='text'
									id='fiscalCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setFiscalCode(e.target.value)}
									value={fiscalCode}
									aria-describedby='uidnote'
									name='fiscalCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.fiscalCodePlaceholder[lang]}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='address'>
									{Globals.language.userRegistry.indirizzo[lang]}
								</label>
								<input
									type='text'
									id='address'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setAddress(e.target.value)}
									value={address}
									aria-describedby='uidnote'
									name='address'
									className='form-control'
									placeholder={Globals.language.userRegistry.indirizzoPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='city'>{Globals.language.userRegistry.city[lang]}</label>
								<input
									type='text'
									id='city'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setCity(e.target.value)}
									value={city}
									aria-describedby='uidnote'
									name='city'
									className='form-control'
									placeholder={Globals.language.userRegistry.cityPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='province'>
									{Globals.language.userRegistry.province[lang]}
								</label>
								<input
									type='text'
									id='province'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setProvince(e.target.value)}
									value={province}
									aria-describedby='uidnote'
									name='province'
									className='form-control'
									placeholder={Globals.language.userRegistry.provincePlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='cap'>{Globals.language.userRegistry.cap[lang]}</label>
								<input
									type='text'
									id='cap'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setCap(e.target.value)}
									value={cap}
									aria-describedby='uidnote'
									name='cap'
									className='form-control'
									placeholder={Globals.language.userRegistry.capPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							{public_administration.is_public_administration !== "0" ? (
								<div className='d-flex justify-content-center p-3'>
									<Card>
										{public_administration.is_public_administration !== "0"
											? Globals.language.userRegistry.publicAdministration[lang]
											: ""}
									</Card>
								</div>
							) : (
								<div></div>
							)}

							<div className='p-3'>
								<button
									className='btn btn-warning mx-2'
									onClick={() => setDisable(!disable)}>
									{disable
										? Globals.language.userRegistry.buttonModify[lang]
										: Globals.language.userRegistry.buttonBack[lang]}
								</button>

								<button
									className='btn btn-success mx-2'
									disabled={disable}
									onClick={() => handleSubmit()}>
									{Globals.language.userRegistry.buttonConfirmEdit[lang]}
								</button>

								<button
									className='btn btn-danger mx-2'
									style={{ backgroundColor: "rgb(220, 53, 69)" }}
									onClick={deleteUser}>
									{Globals.language.userRegistry.buttonDeleteAccount[lang]}
								</button>
							</div>

							{success ? <div> </div> : <div></div>}
							{errMsg !== "" ? (
								<div>
									{" "}
									{Globals.language.userRegistry.errorReg[lang]} : {errMsg}{" "}
								</div>
							) : (
								<div></div>
							)}
						</div>
					</div>
				</div>
			) : (
				<div className='registration-page bg-none'>
					<div className='container'>
						<h2 className='text-center'>
							{Globals.language.userRegistry.businessAccount[lang]}
						</h2>
						<div className='row shadow-lg rounded'>
							<div className='col-sm-12 form-group'>
								<label htmlFor='email'>
									{Globals.language.userRegistry.email[lang]}{" "}
									<OverlayTrigger
										placement='bottom'
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}>
										<Button className='btn btn-danger py-1 my-2' variant='danger'>
											?
										</Button>
									</OverlayTrigger>
								</label>
								<input
									type='email'
									id='email'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									aria-describedby='uidnote'
									className='form-control'
									name='email'
									placeholder={Globals.language.userRegistry.emailPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='name'>{Globals.language.userRegistry.name[lang]}</label>
								<input
									type='text'
									id='name'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setName(e.target.value)}
									value={name}
									aria-describedby='uidnote'
									className='form-control'
									name='name'
									placeholder={Globals.language.userRegistry.namePlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='username'>
									{Globals.language.userRegistry.surname[lang]}
								</label>
								<input
									type='text'
									id='surname'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setSurname(e.target.value)}
									value={surname}
									aria-describedby='uidnote'
									className='form-control'
									name='surname'
									placeholder={Globals.language.userRegistry.surname[lang]}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='phoneNumber'>
									{Globals.language.userRegistry.phone[lang]}
								</label>
								<input
									type='text'
									id='phoneNumber'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setPhoneNumber(e.target.value)}
									value={phoneNumber}
									aria-describedby='uidnote'
									className='form-control'
									name='phoneNumber'
									placeholder={Globals.language.userRegistry.phonePlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-4 form-group'>
								<label htmlFor='company'>
									{Globals.language.userRegistry.companyName[lang]}
								</label>
								<input
									type='text'
									id='company'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setCompany(e.target.value)}
									value={company}
									aria-describedby='uidnote'
									className='form-control'
									name='company'
									placeholder={
										Globals.language.userRegistry.companyNamePlaceholder[lang]
									}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-2 form-group'>
								<label htmlFor='vatCode'>
									{Globals.language.userRegistry.vatNumber[lang]}
								</label>
								<input
									type='text'
									id='vatCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setVatCode(e.target.value)}
									value={vatCode}
									aria-describedby='uidnote'
									className='form-control'
									name='vatCode'
									placeholder={Globals.language.userRegistry.vatNumberPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='fiscalCode'>
									{Globals.language.userRegistry.birtday[lang]}
								</label>
								<input
									type='date'
									id='fiscalCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setDateOfBirth(e.target.value)}
									value={dateOfBirth}
									aria-describedby='uidnote'
									name='fiscalCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.birtdayPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='birthPlace'>
									{Globals.language.userRegistry.luogoNascita[lang]}
								</label>
								<input
									type='text'
									id='birthPlace'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setBirthPlace(e.target.value)}
									value={birthPlace}
									aria-describedby='uidnote'
									name='birthPlace'
									className='form-control'
									placeholder={
										Globals.language.userRegistry.luogoNascitaPlaceholder[lang]
									}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='fiscalCode'>
									{Globals.language.userRegistry.fiscalCode[lang]}
								</label>
								<input
									type='text'
									id='fiscalCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setFiscalCode(e.target.value)}
									value={fiscalCode}
									aria-describedby='uidnote'
									name='fiscalCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.fiscalCodePlaceholder[lang]}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='fiscalCode'>
									{Globals.language.userRegistry.fiscalCodeCompany[lang]}
								</label>
								<input
									type='text'
									id='fiscalCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setFiscalCodeCompany(e.target.value)}
									value={fiscalCodeCompany}
									aria-describedby='uidnote'
									name='fiscalCode'
									className='form-control'
									placeholder={
										Globals.language.userRegistry.fiscalCodeCompanyPlaceholder[lang]
									}
									disabled={disable}
								/>
							</div>

							<div className='col-sm-6 form-group'>
								<label htmlFor='address'>
									{Globals.language.userRegistry.indirizzo[lang]}
								</label>
								<input
									type='text'
									id='vatCode'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setAddress(e.target.value)}
									value={address}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.indirizzoPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='address'>
									{Globals.language.userRegistry.city[lang]}
								</label>
								<input
									type='text'
									id='city'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setCity(e.target.value)}
									value={city}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.cityPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='address'>
									{Globals.language.userRegistry.province[lang]}
								</label>
								<input
									type='text'
									id='province'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setProvince(e.target.value)}
									value={province}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.provincePlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='address'>
									{Globals.language.userRegistry.cap[lang]}
								</label>
								<input
									type='text'
									id='cap'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setCap(e.target.value)}
									value={cap}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.capPlaceholder[lang]}
									disabled={disable}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='sdi'>
									{Globals.language.userRegistry.uniqueCode[lang]}
								</label>
								<input
									type='text'
									id='sdi'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setSdi(e.target.value)}
									value={sdi}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.uniqueCodePlaceholder[lang]}
									disabled={disable}

									// disabled={pec !== "" ? true : false}
								/>
							</div>
							<div className='col-sm-6 form-group'>
								<label htmlFor='pec'>{Globals.language.userRegistry.pec[lang]}</label>
								<input
									type='text'
									id='pec'
									ref={userRef}
									autoComplete='off'
									onChange={(e) => setPec(e.target.value)}
									value={pec}
									aria-describedby='uidnote'
									name='vatCode'
									className='form-control'
									placeholder={Globals.language.userRegistry.pecPlaceholder[lang]}
									// disabled={sdi !== "" ? true : false}
									disabled={disable}
								/>
							</div>

							<div className='p-3'>
								<button
									className='btn btn-warning mx-2'
									onClick={() => setDisable(!disable)}>
									{disable
										? Globals.language.userRegistry.buttonModify[lang]
										: Globals.language.userRegistry.buttonBack[lang]}
								</button>
								<button
									className='btn btn-success mx-2'
									disabled={disable}
									onClick={() => handleSubmit()}>
									{Globals.language.userRegistry.buttonConfirmEdit[lang]}
								</button>

								<button
									className='btn btn-danger mx-2'
									style={{ backgroundColor: "rgb(220, 53, 69)" }}
									onClick={deleteUser}>
									{Globals.language.userRegistry.buttonDeleteAccount[lang]}
								</button>
							</div>

							{success ? <div></div> : <div></div>}
							{errMsg !== "" ? (
								<div>
									{" "}
									{Globals.language.userRegistry.errorReg[lang]} : {errMsg}{" "}
								</div>
							) : (
								<div></div>
							)}
						</div>
					</div>
				</div>
			)}
			<InfoModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				onConfirm={() => setModalShow(false)}
				action={Globals.language.userRegistry.infoModalActions[lang]}
				text={Globals.language.userRegistry.infoModal[lang]}
			/>
		</div>
	);
};

export default UserRegistry;
