import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import AuthContext from "../context/AuthProvider";
import Globals from "../parameter/globals";
import Table from "react-bootstrap/Table";

function LogOrderModal(props) {
	const { lang } = useContext(AuthContext);

	return (
		<Modal
			{...props}
			aria-labelledby='contained-modal-title-vcenter'
            size={'lg'}
			centered
			style={{ zIndex: "9999" }}>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{" "}
					{props.action}{" "}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                {props.data.length > 0 ? <Table striped bordered hover>
					<thead>
						<tr>
							<th>Data/ora</th>
							<th>Registro</th>
							<th>Email mittente</th>
							<th>Email destinatario</th>
						</tr>
					</thead>
					<tbody>
						{props.data.map((item) => {
							return (
								<tr>
									<td>{item.date}</td>
									<td>{item.text}</td>
									<td>{item.email_sender}</td>
									<td>{item.email_recipient}</td>
								</tr>
							);
						})}
					</tbody>
				</Table> : <p> Nessuna azione</p> }
				
			</Modal.Body>
			<Modal.Footer>
				<Button variant='danger' onClick={props.onHide}>
					{Globals.language.infoModal.cancel[lang]}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default LogOrderModal;
