import "../scss/partners.scss";
import React from "react";
import { useContext } from "react";
import Globals from "../parameter/globals";
import AuthContext from "../context/AuthProvider";
import { Helmet } from "react-helmet";

function Partners(props) {
	const client = props.data || false;
	const { lang } = useContext(AuthContext);


	return (
		<div className='App' id='client'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Clienti - Soluzioni Omnia media</title>
				<link rel='canonical' href='#client' />
			</Helmet>
			<section className='section section-light mt-none mb-none'>
				<div className='container'>
					<h2 className='mb-sm text-center title-homepage-all '>
						{client.length !== 0 ? (Globals.language.customers.titleCustomers[lang]) : ""}
					</h2>
					<strong>
						<div className='row'>
							{client !== false && client.length !== 0 ? (
								client.map((item, key) => (
									<div className='col-sm-6 col-md-4 col-lg-3' key={key}>
										<div className='square-holder shadow'>
											<img
											loading="lazy" 
											 fetchpriority="high"
												alt=''
												className='img-responsive image-card-clienti'
												src={item.url}
											/>
										</div>
									</div>
								))
							) : (
								<div></div>
							)}
						</div>
					</strong>
				</div>
			</section>
		</div>
	);
}

export default Partners;
