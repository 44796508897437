import React, {useContext, useState} from "react";
import AuthContext from "../../context/AuthProvider";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import "../../scss/admin-area.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function HomeAdmin() {
	const {auth} = useContext(AuthContext);
	const [showAppMenu, setShowAppScan] = useState(false);

	const AppMenu = (props) => (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
      dialogClassName="mt-5 py-5"
			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					Seleziona voce di menù per App & Scan
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row row-admin-area'>
					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminOperator' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-menu-up fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'>Operatori</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminAllScans' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>

										<hr />
										<p className='card-title lead text-light'>Tutte le scan</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveScanCongressRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<i
											className='bi bi-journal-medical fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<hr />
										<p className='card-title lead text-light'>Scan Congressi</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveScanEventsRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-qr-code fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>
										<i
											className='bi bi-journal-richtext fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}
										/>

										<hr />
										<p className='card-title lead text-light'>Scan Eventi</p>
									</div>
								</div>
							</Link>
						</a>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Chiudi</Button>
			</Modal.Footer>
		</Modal>
	);

	return (
		<article
			className='responsive-div-area-admin'
			style={{
				textAlign: "center",
				display: "flex",
				alignItems: "center",
				height: "auto",
				marginTop: "100px",
				marginBottom: "100px",
			}}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Area Admin - Soluzioni Omnia media</title>
				<meta name="robots" content="noindex" />
			</Helmet>
			<div className='container  '>
				<div className='row '>
					<div className='col-lg-12 p-5'>
						<h1 className='d-flex justify-content-center'> Gestione dei contenuti</h1>
						<h2 className='d-flex justify-content-end my-auto'>
							{" "}
							<i className='bi bi-person my-auto' aria-hidden='true'></i>{" "}
							<p className='text-danger my-auto' style={{fontSize: "20px"}}>
								{" "}
								{auth.user}
							</p>
						</h2>
						<hr />
					</div>
				</div>
				<div className='row row-admin-area'>
					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/admin' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-gear fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'>CMS e CRM</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminorder' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-clipboard2-data fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'> Gestione Ordini </p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveEventsRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-cart-fill fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'>
											{" "}
											Gestione iscrizione eventi{" "}
										</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/archiveCongressRegistration' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-cart-fill fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'>
											{" "}
											Gestione iscrizione congressi{" "}
										</p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a className='text-decoration-none' href='#'>
							<Link to='/adminUsers' className='text-light'>
								<div
									className='card p-3 shadow text-center border-0 h-100'
									style={{backgroundColor: "#d50037"}}>
									<div className='card-body p-1'>
										<i
											className='bi bi-person-lines-fill fa-2x'
											aria-hidden='true'
											style={{fontSize: "30px", color: "#fff"}}></i>
										<hr />
										<p className='card-title lead text-light'> Gestione utenti </p>
									</div>
								</div>
							</Link>
						</a>
					</div>

					{/* <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1">
            <a className="text-decoration-none" href="#">
              <Link to="/adminOperatorAgencies" className="text-light">
                <div
                  className="card p-3 shadow text-center border-0 h-100"
                  style={{ backgroundColor: "#d50037" }}
                >
                  <div className="card-body p-1">
                    <i
                      className="bi bi-menu-up fa-2x"
                      aria-hidden="true"
                      style={{ fontSize: "30px", color: "#fff" }}
                    ></i>
                    <hr />
                    <p className="card-title lead text-light">
                      {" "}
                      Gestione Operatori e Agenzie{" "}
                    </p>
                  </div>
                </div>
              </Link>
            </a>
          </div> */}

					<div className='col-xs-6 col-sm-6 col-md-3 col-lg-3 p-1'>
						<a
							className='text-decoration-none'
							href='#'
							onClick={() => setShowAppScan(true)}>
							<div
								className='card p-3 shadow text-center border-0 h-100'
								style={{backgroundColor: "#d50037"}}>
								<div className='card-body p-1'>
									<i
										className='bi bi-phone fa-2x'
										aria-hidden='true'
										style={{fontSize: "30px", color: "#fff"}}
									/>
									<i
										className='bi bi-qr-code-scan fa-2x'
										aria-hidden='true'
										style={{fontSize: "30px", color: "#fff"}}
									/>
									<hr />
									<p className='card-title lead text-light'> App & Scan </p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>

			<AppMenu show={showAppMenu} onHide={() => setShowAppScan(false)} />
		</article>
	);
}

export default HomeAdmin;
