import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../scss/order-modal.scss";
import {
	InputGroup,
	ListGroup,
	Badge,
	Container,
	Col,
	Image,
	Row,
	Form,
	Table,
	Card,
} from "react-bootstrap";
import axios from "axios";
import Globals from "../parameter/globals";
import Select from "react-select";
import { toast } from "react-hot-toast";

function OrderFormModal(props) {
	const {
		method,
		action,
		fields,
		orders_row,
		biglietti_multipli,
		image,
		service,
		agents,
		additional_notes,
	} = props.data;
	const { getOrder } = props;

	const [type, setType] = useState(""); // tipo evento/congresso

	const [elements, setElements] = useState([]); // congresso/evento lista
	const [selectedElement, setSelectElement] = useState(""); // elemento selezionato

	const [services, setServices] = useState([]); // lista dei servizi relativi ad un congresso
	const [selectedService, setSelectedService] = useState([]); // servizio scelta

	const [ticket, setTickets] = useState([]); // lista dei biglietti relativi ad un congresso
	const [selectedTicket, setSelectedTicket] = useState([]); // biglietti a scelta

	const [viewAgents, setAgents] = useState([]); // lista degli agenti relativi ad un congresso/evento
	const [selectedAgent, setSelectedAgent] = useState([]); // agente scelta

	const [user, setUser] = useState([]); //lista utenti
	const [selectUser, setSelectUser] = useState("");

	const [sendEmail, setSendEmail] = useState(false);
	const [pay, setNotPay] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const [success, setSuccess] = useState(true);

	const getElements = async () => {
		if (type !== "") {
			try {
				let body = {
					type: type,
					access_token: props.accessToken,
				};

				let formdata = new FormData();

				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINORDERGETELEMENTS, formdata, {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setError(response?.data?.message);
				} else {
					setElements(response?.data?.elements);
				}
			} catch (error) {
				setError(error);
				return false;
			}
		}
	};

	const escludePrevService = (element) => {
		setSelectedService(
			selectedService.filter(
				(item) => item.id_service_category !== element.id_service_category
			)
		);
		setSelectedService((selectedService) => [...selectedService, element]);
	};

	const getServicesAndTicket = async () => {
		if (type === "Congresso") {
			try {
				let body = {
					id: selectedElement.value,
					access_token: props.accessToken,
				};

				let formdata = new FormData();

				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(
					Globals.ADMINORDERGETCONGRESSSERVICE,
					formdata,
					{
						headers: { "Content-Type": "application/json" },
						withCredentials: true,
					}
				);

				if (response?.data?.error === true) {
					setError(response?.data?.message);
				} else {
					setTickets(response?.data?.multiple_tickets);
					setServices(response?.data?.services);
					setAgents(response?.data?.agents);
				}
			} catch (error) {
				setError(error);
				return false;
			}
		}
	};

	const getUser = async () => {
		try {
			let body = {
				id: selectedElement,
				access_token: props.accessToken,
				type: type,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINORDERGETUSER, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(response?.data?.message);
			} else {
				setUser(response?.data?.users);
			}
		} catch (error) {
			setError(error);
			return false;
		}
	};
	const getAgent = async () => {
		if (type !== "" && selectedElement !== "") {
			try {
				let body = {
					id: selectedElement.value,
					access_token: props.accessToken,
					type: type,
				};

				let formdata = new FormData();

				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINGETNEWORDERAGENT, formdata, {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setError(response?.data?.message);
				} else {
					setAgents(response?.data?.agents);
				}
			} catch (error) {
				setError(error);
				return false;
			}
		}
	};

	const createOrder = async () => {
		setLoading(true);
		try {
			let body = {
				id: selectedElement.value,
				access_token: props.accessToken,
				type: type,
				element: selectedElement,
				services: selectedService,
				ticket: selectedTicket,
				sendEmail: sendEmail,
				isPayed: pay,
				user: selectUser,
				agent:
					selectedAgent?.value !== undefined && selectedAgent?.value !== ""
						? selectedAgent.value
						: false,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINCREATEORDER, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setLoading(false);
				setError(response?.data?.message);
			} else {
				setLoading(false);
				setSuccess(true);
				toast.success(`Ordine creato con successo.`);
				props.onHide();
				getOrder();
			}
		} catch (error) {
			setLoading(false);
			setError(error);
			return false;
		}
	};

	//quando viene aperta la form deve essere resettato il valore
	useEffect(() => {
		setType("");
		setSelectElement("");
		setSelectedService([]);
		setSelectedTicket([]);
		setSelectUser("");
		setServices([]);
		setElements([]);

		setNotPay(false);
		setSendEmail(false);

		setSelectedAgent("");
		setAgents([]);

		setSuccess(false);
	}, [props.onHide]);

	//firstUseEffect, ottengo i congressi o eventi
	useEffect(() => {
		setSelectedService([]);
		setSelectedTicket([]);
		setSelectUser("");
		setSelectedAgent("");
		setServices([]);
		setElements([]);
		setAgents([]);

		getElements();
		getUser();
	}, [type, selectedElement]);

	useEffect(() => {
		getServicesAndTicket();
		getAgent();
	}, [selectedElement]);

	return (
		<Modal
			{...props}
			size='xl'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			style={{ zIndex: "9999" }}>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'> {action} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row gy-2 gx-3 align-items-center'>
					{method === "new" && (
						<>
							<Form.Group className='mb-2' controlId='formGridState'>
								<div className='mb-4'>
									<Form.Label>
										{" "}
										<strong>Congresso/Evento </strong>
									</Form.Label>
									<Form.Select onChange={(e) => setType(e.target.value)}>
										<option selected value={""}>
											Seleziona ...
										</option>
										<option value={"Congresso"}>Congresso</option>
										<option value={"Evento"}>Evento </option>
									</Form.Select>
								</div>

								{type !== "" && (
									<>
										<div className='mb-4'>
											<Form.Label>
												<strong>Seleziona {type} </strong>
											</Form.Label>

											<Select
												options={elements}
												isSearchable={true}
												isMulti={false}
												placeholder={"Seleziona " + type}
												onChange={(e) => {
													setSelectElement(e);
												}}
												isOptionDisabled={(option) => option.disabled === true}
											/>
										</div>

										{type === "Congresso" &&
											selectedElement !== "" &&
											ticket.length !== 0 && (
												<div className='mb-4'>
													<Form.Label>
														<strong>Seleziona biglietto </strong>{" "}
													</Form.Label>
													<Form.Select onChange={(e) => setSelectedTicket(e.target.value)}>
														<option selected value={""}>
															Seleziona biglietto ...
														</option>
														{ticket?.map((item, key) => {
															return (
																<option value={item.id} key={key}>
																	{item.name}
																</option>
															);
														})}
													</Form.Select>
												</div>
											)}
										{type === "Congresso" &&
											selectedElement.length !== "" &&
											services.length !== 0 && (
												<div className='mb-4'>
													<Form.Label>
														<strong>Seleziona servizi aggiuntivi </strong>{" "}
													</Form.Label>

													{services?.map((item, key) => {
														return (
															<Table responsive key={key}>
																<thead>
																	<tr>
																		<th width={"33%"} className='border-0 th-custom p-0'>
																			<div className='principal-div-servizi'>
																				<div className='p-2 text-center border-0'>
																					{item[0].category}
																				</div>
																			</div>
																		</th>
																	</tr>
																	<tr style={{ borderTop: "1px solid #d50037" }}>
																		<th width={"10%"} className='border-0 text-center'></th>
																		<th width={"30%"} className='border-0 text-center'>
																			Descrizione
																		</th>
																		<th width={"30%"} className='border-0 text-center'>
																			Prezzo
																		</th>
																	</tr>
																</thead>
																{item[0].is_esclusive === "1" &&
																	item.map((element, index) => {
																		return (
																			<tbody key={index}>
																				<tr
																					style={{
																						opacity: element.disabled === true ? 0.2 : 1,
																					}}>
																					<td className='text-center'>
																						<input
																							type='radio'
																							name={item[0].category}
																							onChange={(e) => escludePrevService(element)}
																							disabled={element.disabled}
																						/>
																					</td>
																					<td className='text-center'> {element.name} </td>
																					<td className='text-center'>
																						{Intl.NumberFormat("de-DE", {
																							style: "currency",
																							currency: "EUR",
																						}).format(element.price)}{" "}
																						{/* <span>iva compresa</span> */}
																						{/* <span className="mx-1" style={{fontSize:"20px"}}>iva incl. </span> */}
																					</td>
																				</tr>
																			</tbody>
																		);
																	})}

																{item[0].is_esclusive === "0" &&
																	item.map((element, index) => {
																		return (
																			<tbody key={index}>
																				<tr
																					style={{
																						opacity: element.disabled === true ? 0.2 : 1,
																					}}>
																					<td className='text-center'>
																						<input
																							type='checkbox'
																							onChange={(e) =>
																								e.target.checked
																									? setSelectedService((selectedService) => [
																											...selectedService,
																											element,
																									  ])
																									: setSelectedService(
																											selectedService.filter(
																												(item) => item.id !== element.id
																											)
																									  )
																							}
																							disabled={element.disabled}
																						/>
																					</td>
																					<td className='text-center'> {element.name} </td>
																					<td className='text-center'>
																						{Intl.NumberFormat("de-DE", {
																							style: "currency",
																							currency: "EUR",
																						}).format(element.price)}{" "}
																						{/* <span>iva compresa</span> */}
																						{/* <span className="mx-1" style={{fontSize:"20px"}}>iva incl. </span> */}
																					</td>
																				</tr>
																			</tbody>
																		);
																	})}
															</Table>
														);
													})}
												</div>
											)}

										{selectedElement !== "" && viewAgents.length > 0 && (
											<div className='mb-4'>
												<Form.Label>
													<strong>Seleziona agente </strong>
												</Form.Label>

												<Select
													options={viewAgents}
													isSearchable={true}
													isMulti={false}
													isClearable={true}
													placeholder={"Seleziona Agente"}
													onChange={(e) => {
														setSelectedAgent(e);
													}}
													isOptionDisabled={(option) => option.disabled === true}
												/>
											</div>
										)}

										<div className='mb-4'>
											<Form.Label>
												<strong>Seleziona utente </strong>
											</Form.Label>

											<Select
												options={user}
												isSearchable={true}
												isMulti={false}
												isClearable={true}
												placeholder={"Seleziona Utente"}
												onChange={(e) => {
													setSelectUser(e);
												}}
												isOptionDisabled={(option) => option.disabled === true}
											/>
										</div>

										{type !== "" && selectedElement !== "" && selectUser !== "" && (
											<>
												<div className='form-check form-switch'>
													<input
														className='form-check-input'
														type='checkbox'
														onChange={(e) => setSendEmail(!sendEmail)}
													/>
													<label
														className='form-check-label'
														htmlFor='flexSwitchCheckDefault'>
														Notifica ordine con email
													</label>
												</div>
												<div className='form-check form-switch'>
													<input
														className='form-check-input'
														type='checkbox'
														onChange={(e) => setNotPay(!pay)}
													/>
													<label
														className='form-check-label'
														htmlFor='flexSwitchCheckChecked'>
														Pagato
													</label>
												</div>
											</>
										)}
									</>
								)}
								{error !== "" && (
									<h3 style={{ textAlign: "center", color: "red" }}> {error} </h3>
								)}
							</Form.Group>
						</>
					)}

					{method === "view" && (
						<Container>
							<Row>
								<Col>
									<ListGroup as='ol'>
										{fields.map((item, key) => {
											return (
												<ListGroup.Item
													as='li'
													className='d-flex justify-content-between align-items-center border-0 responsive-modal'>
													<div className='d-flex justify-content-center col-lg-12 col-sm-12 responsive-modal'>
														<div className='col-md-6 col-sm-12'>
															<span className='fw-bold'>{item.label}: </span>
														</div>
														<div className='col-md-6 col-sm-12 '>
															<span
																className=' text-light d-flex justify-content-center'
																style={{ backgroundColor: "#d50037" }}>
																{" "}
																{item.value}{" "}
															</span>
														</div>
													</div>
												</ListGroup.Item>
											);
										})}
									</ListGroup>
								</Col>
								<Col>
									<ListGroup as='ol'>
										<div className='text-center'>Dettaglio</div>
										<ListGroup.Item
											as='li'
											className='d-flex justify-content-between align-items-start border-0 '>
											<div className='mx-auto'>
												<Image fluid rounded src={image} width={"300px"} />
											</div>
										</ListGroup.Item>
										{orders_row.map((item, key) => {
											return (
												<ListGroup.Item
													as='li'
													className='d-flex justify-content-between align-items-center border-0 responsive-modal'>
													<div className='d-flex col-lg-12 col-sm-12 responsive-modal'>
														<div className='col-md-6 col-sm-12'>
															<span className='fw-bold'>{item.label}: </span>
														</div>
														<div className='col-md-6 col-sm-12 responsive-modal'>
															<span
																className=' d-flex justify-content-center'
																style={{ borderBottom: "1px solid #d50037" }}>
																{" "}
																{item.value}{" "}
															</span>
														</div>
													</div>
												</ListGroup.Item>
											);
										})}

										{biglietti_multipli.length !== 0 &&
											biglietti_multipli.map((item, key) => {
												return (
													<Table responsive>
														<thead>
															<tr>
																<th width={"33%"} className='border-0 th-custom p-0'>
																	<div className='principal-div-servizi'>
																		<div className='p-2 text-center border-0 text-light categoria-bg'>
																			BIGLIETTO MULTIPLO
																		</div>
																	</div>
																</th>
															</tr>
															<tr style={{ borderTop: "1px solid #d50037" }}>
																<th width={"50%"} className='border-0 text-center'>
																	Nome
																</th>
																{/* <th
                                  width={"40%"}
                                  className="border-0 text-center"
                                >
                                  Descrizione
                                </th> */}
																<th width={"50%"} className='border-0 text-center'>
																	Prezzo
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className='text-center'>{item.name}</td>
																{/* <td className="text-center">
                                      {" "}
                                      {item.description}{" "}
                                    </td> */}
																<td className='text-center'>{item.price}</td>
															</tr>
														</tbody>
													</Table>
												);
											})}

										{service.length !== 0 &&
											service.map((item, key) => {
												return (
													<Table responsive>
														<thead>
															<tr>
																<th width={"33%"} className='border-0 th-custom p-0'>
																	<div className='principal-div-servizi'>
																		<div className='p-2 text-center border-0 text-light categoria-bg'>
																			SERVIZIO - {item.categories_name}
																		</div>
																	</div>
																</th>
															</tr>
															<tr style={{ borderTop: "1px solid #d50037" }}>
																<th width={"20%"} className='border-0 text-center'>
																	Nome
																</th>
																<th width={"40%"} className='border-0 text-center'>
																	Quantità
																</th>
																<th width={"40%"} className='border-0 text-center'>
																	Prezzo
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className='text-center'>{item.name}</td>
																<td className='text-center'> {item.quantity} </td>
																<td className='text-center'>{item.price}</td>
															</tr>
														</tbody>
													</Table>
												);
											})}

										{agents.length !== 0 &&
											agents.map((item, key) => {
												return (
													<Table responsive>
														<thead>
															<tr>
																<th width={"33%"} className='border-0 th-custom p-0'>
																	<div className='principal-div-servizi'>
																		<div className='p-2 text-center border-0 text-light categoria-bg'>
																			AGENTE
																		</div>
																	</div>
																</th>
															</tr>
															<tr style={{ borderTop: "1px solid #d50037" }}>
																<th width={"50%"} className='border-0 text-center'>
																	Nome
																</th>
																<th width={"50%"} className='border-0 text-center'>
																	Cognome
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className='text-center'>{item.name}</td>
																<td className='text-center'> {item.surname} </td>
															</tr>
														</tbody>
													</Table>
												);
											})}
										{additional_notes !== "" ? (
											<Card>
												<div>
													<h4>Note aggiuntive</h4>
												</div>
												{additional_notes}
											</Card>
										) : (
											<></>
										)}
									</ListGroup>
								</Col>
							</Row>
						</Container>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={props.onHide}>
					Chiudi
				</Button>
				{method === "new" && success === false && (
					<Button
						variant='success'
						onClick={() => createOrder()}
						disabled={
							type !== "" && selectedElement !== "" && selectUser !== "" ? false : true
						}>
						Conferma
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}

export default OrderFormModal;
