import {useParams} from "react-router-dom";
import axios from "axios";
import React, {useState, useContext, useEffect, useMemo} from "react";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import {GlobalFilter} from "../../components/globalFilter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import {useNavigate, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Card} from "react-bootstrap";
import SubscribeModal from "../../components/SubscribeModal";
import {toast} from "react-hot-toast";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AdminNav from "../../components/AdminNav";
import {
	useTable,
	useGlobalFilter,
	useSortBy,
	setGlobalFilter,
	usePagination,
} from "react-table";

import {
	faCheckCircle,
	faXmark,
	faBan,
	faEye,
	faPaperPlane,
	faCheckDouble,
	faLock,
	faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import LogOrderModal from "../../components/LogOrderModal";

function EventsSubscribersList() {
	const {id} = useParams();

	const type = "Events";

	const location = useLocation();

	//dichiara variabili di evento
	const [subscribe, setSubscribe] = useState([]);
	//variabili server, error
	const {auth} = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate();
	const [modalShow, setModalShow] = useState(false);
	const [logModalShow, setLogModalShow] = useState(false);

	// const [send, setSend] = useState("");
	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			Per poter inviare la conferma di pagamento è necessario settare il pagato a
			confermato.
		</Tooltip>
	);
	const [formData, setFormData] = useState({
		action: "",
		method: "",
		id: "",
		data: [],
		service: [],
		ticket: [],
		type: "events",
	});

	const [logdata, setLogdata] = useState([]);

	//chiamata di visualizzazione congressi, con conteggio iscritti

	const getSubscribe = async () => {
		try {
			setLoading(true);
			let body = {
				id: id,
				access_token: auth.accessToken,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.ADMINGETEVENTSSUBSCRIBE,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				setLoading(false);
				setErrMsg(response?.data?.message);
			} else {
				setLoading(false);
				setSubscribe(response?.data?.subscribe);
			}
		} catch (err) {
			setLoading(false);
			setErrMsg(err);
		}
	};
	const modifyPaidState = async (id) => {
		if (window.confirm("Vuoi modificare lo stato di questo ordine?") === true) {
			try {
				setLoading(true);
				let body = {
					access_token: auth.accessToken,
					id: id,
				};

				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINSETPAIDUSER, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setErrMsg(response?.data?.message);
					toast.error(response?.data?.message);
					setLoading(false);
				} else {
					setLoading(false);
					toast.success(`Modificato lo stato dell'ordine con successo.`);
					getSubscribe();
				}
			} catch (err) {
				setLoading(false);
				if (!err?.response) {
					setErrMsg("No Server Response");
				} else if (err.response?.status === 400) {
					setErrMsg("Missing Username or Password");
				} else if (err.response?.status === 401) {
					setErrMsg("Unauthorized");
				} else {
					console.log(err);
					// console.log(err);
				}
			}
		}
	};

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear(),
			hour = "" + d.getHours(),
			minutes = "" + d.getMinutes(),
			seconds = "" + d.getSeconds();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		if (hour.length < 2) hour = "0" + hour;
		if (minutes.length < 2) minutes = "0" + minutes;
		if (seconds.length < 2) seconds = "0" + seconds;

		return [day, month, year].join("-") + " " + [hour, minutes].join(":");
	};

	const exportPdf = async () => {
		window
			.open(
				Globals.EXPORTSUBSCRIPTIONPDF +
					id +
					"&type=" +
					type +
					"&accessToken=" +
					auth.accessToken,
				"name",
				"titlebar=no",
			)
			.focus();
	};

	const exportExcel = async () => {
		window
			.open(
				Globals.EXPORTSUBSCRIPTIONEXCEL +
					id +
					"&type=" +
					type +
					"&accessToken=" +
					auth.accessToken,
				"name",
				"titlebar=no",
			)
			.focus();
	};

	const UnsubscribeUser = async (id) => {
		if (
			window.confirm("Sei sicuro di voler discrivere questo utente?") === true
		) {
			try {
				setLoading(true);
				let body = {
					id: id,
					access_token: auth.accessToken,
					type: "Evento",
				};

				let formdata = new FormData();

				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINUNSUBSCRIBE, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setLoading(false);
					setErrMsg(response?.data?.message);
				} else {
					setLoading(false);
					toast.success(`Utente disiscritto con successo.`);
					getSubscribe();
				}
			} catch (err) {
				setLoading(false);
				setErrMsg(err);
			}
		}
	};
	const SendEmailSubscribe = async (id, id_ordine, email_order, descrizione) => {
		if (
			window.confirm(
				"Sei sicuro di voler inviare nuovamente l'ordine all'utente?",
			) === true
		) {
			try {
				setLoading(true);
				let body = {
					id: id,
					id_ordine: id_ordine,
					email_order: email_order,
					descrizione: descrizione,
					access_token: auth.accessToken,
				};
				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.RESENDEMAIL, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setLoading(false);
					setErrMsg(response?.data?.message);
				} else {
					setLoading(false);
					// setSend("Inviata!");
					toast.success(`Email dell'ordine inviata con successo.`);
					getSubscribe();
				}
			} catch (err) {
				setLoading(false);
				setErrMsg(err);
			}
		}
	};
	const confirmPayment = async (id, id_ordine, email_order, descrizione) => {
		if (
			window.confirm(
				"Sei sicuro di voler inviare la conferma di pagamento all'utente",
			) === true
		) {
			try {
				setLoading(true);
				let body = {
					id: id,
					id_ordine: id_ordine,
					email_order: email_order,
					descrizione: descrizione,
					access_token: auth.accessToken,
				};
				let formdata = new FormData();
				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.CONFIRMPAYMENT, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setLoading(false);
					setErrMsg(response?.data?.message);
				} else {
					setLoading(false);
					// setSend(true);
					toast.success(`Email di conferma dell'ordine inviata con successo.`);
					getSubscribe();
				}
			} catch (err) {
				setLoading(false);
				setErrMsg(err);
			}
		}
	};

	const visualizeSub = async (id) => {
		try {
			setLoading(true);

			let body = {
				id: id,
				access_token: auth.accessToken,
				type: "events",
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINGETSINGLESUB, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				toast.error(response?.data?.message);
				setLoading(false);
			} else {
				setFormData({
					id: id,
					method: "visualize",
					action: "Visualizza",
					data: response.data.element,
					service: response.data.service,
					ticket: response.data.ticket,
					agents: response.data.agents,
				});
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				toast.error("Errore del server");
			} else if (err.response?.status === 400) {
				toast.error("Errore 400");
			} else if (err.response?.status === 401) {
				toast.error("Errore 401");
			} else {
				console.log(err);
				toast.error("Errore di caricamento dei dati");
			}
		}

		setModalShow(true);
	};

	const newModal = async () => {
		try {
			let body = {
				id: id,
				access_token: auth.accessToken,
				type: "events",
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.ADMINGETDETAILCONGRESSPRESUB,
				formdata,
				{
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				},
			);

			if (response?.data?.error === true) {
				toast.error(response?.data?.message);
			} else {
				setFormData({
					id: id,
					access_token: auth.accessToken,
					method: "new",
					action: "Aggiungi nuova iscrizione a " + location.state.name,
					data: response?.data?.users,
					service: [],
					ticket: [],
				});
				setModalShow(true);
			}
		} catch (error) {
			toast.error(error);
			return false;
		}
	};

	const getLog = async (id) => {
		try {
			let body = {
				id: id,
				access_token: auth.accessToken,
				type: "Evento",
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINGETLOG, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				toast.error(response?.data?.message);
				setLoading(false);
			} else {
				setLogdata(response?.data?.log || []);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				toast.error("Errore del server");
			} else if (err.response?.status === 400) {
				toast.error("Errore 400");
			} else if (err.response?.status === 401) {
				toast.error("Errore 401");
			} else {
				console.log(err);
				toast.error("Errore di caricamento dei dati");
			}
		}

		setLogModalShow(true);
	};

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			{
				id: "Paid",
				Header: "Pagato",
				Cell: ({row}) =>
					parseInt(row.original.pagato) === 1 ? (
						<div className='text-center'>
							<FontAwesomeIcon
								icon={faCheckCircle}
								style={{color: "green"}}
								onClick={() => modifyPaidState(row.original.id_ordine)}
							/>
						</div>
					) : (
						<div className='text-center'>
							<FontAwesomeIcon
								icon={faXmark}
								style={{color: "red"}}
								onClick={() => modifyPaidState(row.original.id_ordine)}
							/>
						</div>
					),
			},
			{
				id: "Visualize",
				Header: "Visualizza",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faEye}
							onClick={() => visualizeSub(row.original.id)}
						/>
					</div>
				),
			},
			{
				id: "Delete",
				Header: "Disiscrivi",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faBan}
							onClick={() => UnsubscribeUser(row.original.id)}
							className='hover:text-red-500'
						/>
					</div>
				),
			},
			{
				id: "SendEmail",
				Header: "Rimanda iscrizione",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faPaperPlane}
							onClick={() =>
								SendEmailSubscribe(
									row.original.id,
									subscribeData[row.id]["id_ordine"],
									subscribeData[row.id]["email_order"],
									subscribeData[row.id]["descrizione"],
								)
							}
							className='hover:text-red-500'
						/>{" "}
						{/* {send !== "" ? send : ""} */}
					</div>
				),
			},
			{
				id: "ConfirmPayment",
				Header: "Conferma pagamento",
				Cell: ({row}) => (
					<div className='text-center'>
						{row.original.metodo_pagamento === "bonifico anticipato" ||
						row.original.metodo_pagamento === "Ordine creato" ? (
							<div>
								{parseInt(row.original.pagato) === 1 ? (
									<div>
										<FontAwesomeIcon
											icon={faCheckDouble}
											onClick={() =>
												confirmPayment(
													row.original.id,
													subscribeData[row.id]["id_ordine"],
													subscribeData[row.id]["email_order"],
													subscribeData[row.id]["descrizione"],
												)
											}
											className='hover:text-red-500'
											style={{fontSize: "22px"}}
										/>{" "}
									</div>
								) : (
									<div>
										<OverlayTrigger
											placement='top'
											delay={{show: 250, hide: 400}}
											overlay={renderTooltip}>
											<Button
												variant='light'
												style={{backgroundColor: "transparent", border: "none"}}>
												<FontAwesomeIcon
													icon={faCheckDouble}
													style={{
														color: "#666666",
														cursor: "not-allowed",
														fontSize: "22px",
													}}
												/>
											</Button>
										</OverlayTrigger>
									</div>
								)}
							</div>
						) : (
							<div>
								<FontAwesomeIcon
									icon={faLock}
									style={{
										color: "#666666",
										cursor: "not-allowed",
										fontSize: "22px",
									}}
								/>
							</div>
						)}
					</div>
				),
			},
			{
				id: "register",
				Header: "Log Azioni",
				Cell: ({row}) => (
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faFileInvoice}
							onClick={() => getLog(subscribeData[row.id]["id_ordine"])}
							className='hover:text-red-500'
						/>{" "}
						{/* {send !== "" ? send : ""} */}
					</div>
				),
			},
		]);
	};

	const subscribeData = useMemo(() => [...subscribe], [subscribe]);

	const subscribeColumns = useMemo(
		() =>
			subscribe[0]
				? Object.keys(subscribe[0])
						.filter(
							(key) =>
								key !== "id" &&
								key !== "pagato" &&
								key !== "id_ordine" &&
								key !== "Conferma pagamento",
						)
						.map((key) => {
							if (key === "totale")
								return {
									Header: key,
									accessor: key,
									Cell: ({value}) => (
										<span>
											{" "}
											{Intl.NumberFormat("de-DE", {
												style: "currency",
												currency: "EUR",
											}).format(value)}{" "}
										</span>
									),
									maxWidth: 70,
								};

							if (key === "data")
								return {
									Header: "Data",
									accessor: key,
									maxWidth: 70,
									Cell: ({value}) => <span>{formatDate(value)}</span>,
								};

							return {Header: key, accessor: key};
						})
				: [],
		[subscribe],
	);

	const tableInstance = useTable(
		{
			columns: subscribeColumns,
			data: subscribeData,
			initialState: {pageIndex: 0, pageSize: 25},
		},
		useGlobalFilter,
		tableHooks,
		useSortBy,
		usePagination,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		preGlobalFilteredRows,
		setGlobalFilter,
		state,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		page,
	} = tableInstance;
	const {pageIndex, pageSize} = state;

	useEffect(() => {
		getSubscribe();
	}, []);

	//dichiarazione tabella di visualizzazione dei subscribers con annesso hover

	return (
		<div
			className='container'
			style={{marginTop: "140px", marginBottom: "100px"}}>
			<AdminNav />
			<div>
				<Button onClick={() => navigate(-1)}>
					<span> Torna indietro</span>
				</Button>
				<Button className='float-end' variant='warning' onClick={() => exportPdf()}>
					<span> Esportazione pdf partecipanti</span>
				</Button>
				<Button
					className='float-end mx-2'
					variant='success'
					onClick={() => exportExcel()}>
					<span> Esportazione excel partecipanti</span>
				</Button>
				<h2> Gestione iscrizione all'evento "{location.state.name}"</h2>
				{/* <Button onClick={() => NewModal()}> Inserisci Nuovo Ordine </Button> */}
			</div>

			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				setGlobalFilter={setGlobalFilter}
				globalFilter={state.globalFilter}
			/>
			<div className='p-2'>
				<Button onClick={() => newModal()}> Aggiungi una nuova iscrizione</Button>
			</div>

			{loading === true ? (
				<Loading />
			) : (
				<>
					{subscribe.length === 0 ? (
						<span> Nessun iscritto a questo evento</span>
					) : (
						<div>
							<Table
								{...getTableProps()}
								striped
								bordered
								hover
								responsive
								variant='light'>
								<thead>
									{headerGroups.map((headerGroup) => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map((column) => (
												<th
													{...column.getHeaderProps(column.getSortByToggleProps())}
													style={{
														borderBottom: "solid 3px ",
														color: "black",
														fontWeight: "bold",
														fontSize: "20px",
														textTransform: "capitalize",
														textAlign: "center",
													}}>
													{column.render("Header")}
													{column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
												</th>
											))}
										</tr>
									))}
								</thead>

								<tbody {...getTableBodyProps()}>
									{page.map((row) => {
										prepareRow(row);

										return (
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td
															{...cell.getCellProps()}
															style={{
																padding: "5px",
																border: "solid 1px gray",
																cursor: "pointer",
															}}>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</Table>
							<Card>
								<div className='responsive-pagination'>
									<div>
										<button
											className='btn btn-light'
											onClick={() => gotoPage(0)}
											disabled={!canPreviousPage}>
											{"<<"}
										</button>{" "}
										<button
											className='btn btn-light mx-1'
											onClick={() => previousPage()}
											disabled={!canPreviousPage}>
											Precedente
										</button>{" "}
										<button
											className='btn btn-light mx-1'
											onClick={() => nextPage()}
											disabled={!canNextPage}>
											Successiva
										</button>{" "}
										<button
											onClick={() => gotoPage(pageCount - 1)}
											disabled={!canNextPage}
											className='btn btn-light mx-1'>
											{">>"}
										</button>{" "}
									</div>
									<div className='float-end'>
										<span className='w-100'>
											Pagina:{" "}
											<strong>
												{pageIndex + 1} di {pageOptions.length}
											</strong>{" "}
										</span>
									</div>
									<div className='text-center'>
										<span>
											Vai alla pagina{" "}
											<input
												type='number'
												defaultValue={pageIndex + 1}
												onChange={(e) => {
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
													gotoPage(pageNumber);
												}}
												className='d-flex justify-content-center mx-auto responsive-pagination'
											/>
										</span>{" "}
									</div>
									<select
										className='form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination'
										value={pageSize}
										onChange={(e) => setPageSize(Number(e.target.value))}>
										{[10, 25, 50, 75].map((pageSize) => (
											<option key={pageSize} value={pageSize}>
												Visualizza {pageSize} righe
											</option>
										))}
									</select>
								</div>
							</Card>
						</div>
					)}
				</>
			)}
			<SubscribeModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				accessToken={auth.accessToken}
				getOrder={() => getSubscribe()}
				data={formData}
			/>

			<LogOrderModal
				show={logModalShow}
				onHide={() => setLogModalShow(false)}
				onConfirm={() => setLogModalShow(false)}
				action={"Storico ordine"}
				data={logdata}
			/>
			{errMsg && <p className='fs-1 fw-bolder danger'> {errMsg}</p>}
		</div>
	);
}

export default EventsSubscribersList;
