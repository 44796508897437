import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }), 0);
	}, [pathname]);


	return children;
}


export default ScrollToTop;
