import React, { useState, useEffect } from "react";
import "../scss/portfolio.scss";

import Globals from "../parameter/globals";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const Portfolio = () => {
	const location = useLocation();
	const { lang } = useContext(AuthContext);

	const [error, setError] = useState(false);

	const [message, setMessage] = useState("");
	const [portfoil, setPortfoil] = useState([]);
	const [portfoilCongress, setPortfoilCongress] = useState([]);
	const [portfoilEvents, setPortfoilEvents] = useState([]);

	const [item, setItem] = useState(portfoil);
	const [years, setYears] = useState();
	const [loading, setLoading] = useState(false);

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};

	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		d.setMonth(month - 1);

		let chartMonth = d.toLocaleString([], { month: "long" });

		return chartMonth.substring(0, 3);
	};

	useEffect(() => {
		getEventPortfoil();
		setTimeout(() => {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}, 200);
	}, [location.pathname]);

	useEffect(() => {
		setItem(portfoil);
		setItem(portfoilCongress);
		setItem(portfoilEvents);
	}, [portfoil, portfoilCongress, portfoilEvents]);

	const getEventPortfoil = async () => {
		setLoading(true);
		try {
			const response = await axios.get(Globals.GETPORTFOIL + "&lang=" + lang, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setPortfoil(response?.data?.portofolio);
				setPortfoilCongress(response?.data?.portfolio_congressi);
				setPortfoilEvents(response?.data?.portfolio_eventi);
				setYears(response?.data?.years);
				setLoading(false);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
			setLoading(false);
		}
	};

	const menuItems = [
		...new Set(portfoil.map((Val) => formatYear(Val.end_date))),
	];

	const filterItem = (curcat) => {
		const portfolio = portfoil.map((num) => num);
		const newItem = portfolio.filter((newVal) => {
			return parseInt(newVal.years) === parseInt(curcat);
		});
		setItem(newItem);
	};

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div
				className='container container-portfolio'
				style={{ marginTop: "120px", height: "auto", marginBottom: "10px" }}>
				<Helmet>
					<meta charSet='utf-8' />
					<title>Portfolio - Soluzioni Omnia media</title>
					<link
						rel='canonical'
						href='https://www.soluzioniomniamedia.com/portfolio'
					/>
				</Helmet>

				{parseInt(item.length) !== 0 ? (
					<div className='d-flex justify-content-center py-3 responsive-button-year scroll-color-colors'>
						<button
							className='btn-danger text-white p-1 px-3 mx-3 fw-bold btn btn-lg'
							style={{ backgroundColor: "#d50037" }}
							onClick={() => setItem(portfoil)}>
							{Globals.language.portfolio.allEventsCongress[lang]}
						</button>
						<button
							className='btn-danger text-white p-1 px-3 mx-3 fw-bold btn btn-lg'
							style={{ backgroundColor: "#d50037" }}
							onClick={() => setItem(portfoilCongress)}>
							{Globals.language.portfolio.congress[lang]}
						</button>
						<button
							className='btn-danger text-white p-1 px-3 mx-3 fw-bold btn btn-lg'
							style={{ backgroundColor: "#d50037" }}
							onClick={() => setItem(portfoilEvents)}>
							{Globals.language.portfolio.events[lang]}
						</button>
						{menuItems.map((Val, id) => {
							return (
								<button
									className='btn-danger text-white p-1 px-1 mx-3 btn fw-bold btn-lg'
									style={{ backgroundColor: "#d50037" }}
									onClick={() => filterItem(Val)}
									key={id}>
									{Val}
								</button>
							);
						})}
					</div>
				) : (
					<></>
				)}

				<div
					className='cards-portfolio scroll-color-colors'
					style={{ width: "100%" }}>
					<div className='container-fluid'>
						<div className='row justify-content-center'>
							{parseInt(item.length) !== 0 ? (
								item.map((item, key) => {
									return (
										<li
											className='card card-portfolio border border-0'
											key={key}
											data-label={
												item.type === "Evento"
													? Globals.language.portfolio.card_events[lang]
													: Globals.language.portfolio.card_congress[lang]
											}>
											<div>
												<div
													className='my-2 title-card-congress'
													style={{
														display: "flex",
														justifyContent: "start",
														alignItems: "center",
													}}>
													<div className='circle-portfolio'></div>
													<h3 className='card-title mx-5'>{item.name}</h3>
												</div>

												<div className='container-card-portfolio-responsive'>
													{parseInt(item.is_year) === 1 ? (
														<section className='data-container'>
															<i
																className='bi bi-calendar-event mx-1'
																style={{ fontSize: "15px" }}></i>
															{formatYear(item.end_date)}
														</section>
													) : (
														<section className='data-container'>
															{item.start_date === item.end_date ? (
																<span className='mx-1'>
																	{formatDay(item.start_date) === "NaN"
																		? "00"
																		: formatDay(item.start_date)}{" "}
																	{formatMonth(item.start_date) === "NaN"
																		? "00"
																		: formatMonth(item.start_date)}{" "}
																</span>
															) : (
																<div>
																	<span className='mx-1'>
																		{formatDay(item.start_date) === "NaN"
																			? "00"
																			: formatDay(item.start_date)}{" "}
																		{formatMonth(item.start_date) === "NaN"
																			? "00"
																			: formatMonth(item.start_date)}{" "}
																	</span>

																	<span className='mx-1'>
																		{formatDay(item.end_date) === "NaN"
																			? "00"
																			: formatDay(item.end_date)}{" "}
																		{formatMonth(item.end_date) === "NaN"
																			? "00"
																			: formatMonth(item.end_date)}{" "}
																	</span>
																</div>
															)}

															<div>
																<i
																	className='bi bi-calendar-event'
																	style={{ fontSize: "15px" }}></i>{" "}
																<span style={{ fontSize: "15px" }} className='mx-1 year-font'>
																	{/*{formatYear(item.start_date) !== "Nan"*/}
																	{/*    ? formatYear(item.start_date)*/}
																	{/*    : "0000"}*/}
																	{formatYear(item.start_date) === formatYear(item.end_date) ? (
																		formatYear(item.end_date)
																	) : (
																		<span className='text-center'>
																			{formatYear(item.start_date)} {formatYear(item.end_date)}{" "}
																		</span>
																	)}
																</span>
															</div>
														</section>
													)}

													<aside className='image-container'>
														<img
															src={item.url}
															className='img-responsive image-card-portfolio'
															alt='...'
														/>
													</aside>
												</div>
												<div
													style={{
														display: "flex",
														justifyContent: "start",
														alignItems: "center",
													}}>
													<div className='col-lg-2 col-md-0 col-sm-0'></div>
													<div className='col-lg-10 col-md-11 col-sm-12 image-always-max'>
														<div
															className='text-congress-description my-2 '
															style={{ minHeight: "90px" }}>
															<span className='mx-2 my-2 w-75 responsive-description-port'>
																{item.short_description !== ""
																	? item.short_description
																	: Globals.language.errorLabel.noDescription[lang]}
															</span>
														</div>
													</div>
												</div>
											</div>
										</li>
									);
								})
							) : (
								<div>
									<Card className='d-flex justify-content-center align-items-center mx-auto py-5 w-75 mb-5 mt-5'>
										<h3>{Globals.language.portfolio.noPortfoil[lang]}</h3>
									</Card>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Portfolio;
