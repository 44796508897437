import axios from "axios";
import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  setGlobalFilter,
  usePagination,
} from "react-table";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import Table from "react-bootstrap/Table";
import { GlobalFilter } from "../../components/globalFilter";
import "bootstrap-icons/font/bootstrap-icons.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-hot-toast";
import { Button, Card } from "react-bootstrap";

import AdminAppNav from "../../components/AdminAppNav";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

function AdminAllScan() {
  const { auth } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [scans, setScans] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const getScan = async () => {
    try {
      setLoading(true);

      let body = {
        access_token: auth.accessToken,
      };

      let formdata = new FormData();
      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(Globals.ADMINGETALLSCAN, formdata, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setErrMsg(response?.data?.message);
        setLoading(false);
      } else {
        setScans(response.data.data);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        console.log(err);
        setErrMsg("Errore di caricamemento dati");
        // console.log(err);
      }
    }
  };

  const deleteRow = async (id) => {
    if (window.confirm("Vuoi eliminare questa scannerizzazione ?") === true) {
      try {
        setLoading(true);
        let body = {
          access_token: auth.accessToken,
          id: id,
        };

        let formdata = new FormData();
        formdata.append("body", JSON.stringify(body));

        const response = await axios.post(Globals.ADMINDELETESCAN, formdata, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        if (response?.data?.error === true) {
          setErrMsg(response?.data?.message);
          toast.error(response?.data?.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success(`Scan eleminata con successo.`);
          getScan();
        }
      } catch (err) {
        setLoading(false);
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          console.log(err);
          // console.log(err);
        }
      }
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      minutes = "" + d.getMinutes(),
      seconds = "" + d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (seconds.length < 2) seconds = "0" + seconds;

    return [day, month, year].join("-") + " " + [hour, minutes].join(":");
  };

  const scansData = useMemo(() => [...scans], [scans]);

  useEffect(() => {
    getScan();
  }, []);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      // {
      //     id: "Paid",
      //     Header: "Pagato",
      //     Cell: ({row}) =>
      //         parseInt(row.original.pagato) === 1 ? (
      //             <div className='text-center'>
      //                 <FontAwesomeIcon
      //                     icon={faCheckCircle}
      //                     style={{color: "green"}}
      //                     onClick={() => modifyPaidState(row.original.id)}
      //                     cursor={"pointer"}
      //                 />
      //             </div>
      //         ) : (
      //             <div className='text-center'>
      //                 <FontAwesomeIcon
      //                     icon={faXmark}
      //                     style={{color: "red"}}
      //                     onClick={() => modifyPaidState(row.original.id)}
      //                     cursor={"pointer"}
      //                 />
      //             </div>
      //         ),
      // },
      // {
      //     id: "Visualize",
      //     Header: "Visualizza",
      //     Cell: ({row}) => (
      //         <div className='text-center'>
      //             <FontAwesomeIcon
      //                 icon={faEye}
      //                 onClick={() => viewModal(row.original.id)}
      //                 className='hover:text-red-500'
      //                 cursor={"pointer"}
      //             />
      //         </div>
      //     ),
      // },
      // {
      // 	id: "Edit",
      // 	Header: "Edit",
      // 	Cell: ({ row }) => (
      // 		<FontAwesomeIcon
      // 			icon={faEdit}
      // 			onClick={() => setModalShow(true)}
      // 			className='hover:text-red-500'
      // 		/>
      // 	),
      // },
      // {
      //     id: "register",
      //     Header: "Log Azioni",
      //     Cell: ({row}) => (
      //         <div className='text-center'>
      //             <FontAwesomeIcon
      //                 icon={faFileInvoice}
      //                 onClick={() => getLog(row.original.id)}
      //                 className='hover:text-red-500'
      //                 cursor={"pointer"}
      //             />{" "}
      //             {/* {send !== "" ? send : ""} */}
      //         </div>
      //     ),
      // },
      {
        id: "Delete",
        Header: "Elimina",
        Cell: ({ row }) => (
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => deleteRow(row.original.id)}
              className="hover:text-red-500"
              cursor={"pointer"}
            />
          </div>
        ),
      },
      // {
      // 	id: "Esporta",
      // 	Header: "Esporta",
      // 	Cell: ({ row }) => (
      // 		<FontAwesomeIcon icon={faFile} className='hover:text-red-500' />
      // 	),
      // },
      // {
      // 	id: "qr_code",
      // 	Header: "Invia qrcode",
      // 	Cell: ({ row }) => (
      // 		<button onClick={() => console.log(row.original.id)}>invia qrcode</button>
      // 	),
      // },
    ]);
  };

  const scansColumns = useMemo(
    () =>
      scans[0]
        ? Object.keys(scans[0])
            .filter((key) => key !== "id")
            .map((key) => {
              if (key === "totale")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => (
                    <span>
                      {" "}
                      {Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(value)}{" "}
                    </span>
                  ),
                  maxWidth: 70,
                };
              if (key === "data/ora")
                return {
                  Header: "Data/ora",
                  accessor: key,
                  maxWidth: 70,
                  Cell: ({ value }) => <span>{formatDate(value)}</span>,
                };

              if (key === "esito")
                return {
                  Header: "Esito",
                  accessor: key,
                  maxWidth: 70,
                  Cell: ({ value }) => (
                    <span
                      style={{
                        color: value === "Entrata" ? "green" : "red",
                        fontSize: 20,
                      }}
                    >
                      <strong> {value} </strong>
                    </span>
                  ),
                };
              return { Header: key, accessor: key };
            })
        : [],
    [scans]
  );

  const tableInstance = useTable(
    {
      columns: scansColumns,
      data: scansData,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    page,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  return (
    <div
      className="container"
      style={{ marginTop: "140px", marginBottom: "100px" }}
    >
      <AdminAppNav />
      <div className="d-flex justify-content-start mx-auto w-100 py-1">
        {/* <Button onClick={() => navigate(-1)}>
                    <span> Torna indietro</span>
                </Button> */}
        {/* <Button onClick={() => NewModal()}> Inserisci Nuovo Ordine </Button> */}

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />
      </div>
      <div className="py-3">
        {/* <Button onClick={() => NewModal()}> Crea un nuovo ordine</Button> */}
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div>
          {scans.length !== 0 ? (
            <div>
              <Table
                {...getTableProps()}
                bordered
                hover
                responsive
                variant="light"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            borderBottom: "solid 3px ",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ▼"
                              : " ▲"
                            : ""}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                {/* <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);

                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                padding: "5px",

                                                border: "solid 1px gray",
                                            }}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody> */}
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card>
                <div className="responsive-pagination">
                  <div>
                    <button
                      className="btn btn-light"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>{" "}
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Precedente
                    </button>{" "}
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Successiva
                    </button>{" "}
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                      className="btn btn-light mx-1"
                    >
                      {">>"}
                    </button>{" "}
                  </div>
                  <div className="float-end">
                    <span className="w-100">
                      Pagina:{" "}
                      <strong>
                        {pageIndex + 1} di {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </div>
                  <div className="text-center">
                    <span>
                      Vai alla pagina{" "}
                      <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                        className="d-flex justify-content-center mx-auto responsive-pagination"
                      />
                    </span>{" "}
                  </div>
                  <select
                    className="form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[10, 25, 50, 75].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Visualizza {pageSize} righe
                      </option>
                    ))}
                  </select>
                </div>
              </Card>
            </div>
          ) : (
            <div>Nessuna scan presente</div>
          )}
        </div>
      )}

      {errMsg && <p className="fs-1 fw-bolder danger"> {errMsg}</p>}
      {/* <OrderFormModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={formdata}
        setFormData={setFormData}
        accessToken={auth.accessToken}
        getOrder={() => getOrder()}
      />

      <LogOrderModal
        show={logModalShow}
        onHide={() => setLogModalShow(false)}
        onConfirm={() => setLogModalShow(false)}
        action={"Storico ordine"}
        data={logdata}
      /> */}
    </div>
  );
}

export default AdminAllScan;
