import { useParams } from "react-router-dom";
import axios from "axios";
import React, { useState, useContext, useEffect, useMemo } from "react";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";
import Loading from "../../components/Loading";
import { GlobalFilter } from "../../components/globalFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "react-bootstrap/Table";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment-timezone";
import it from "date-fns/locale/it";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { Card } from "react-bootstrap";
import {
  faPersonWalkingArrowRight,
  faPersonWalkingArrowLoopLeft,
  faEye,
  faTrash,
  faEyeSlash,
  faPlusCircle,
  faFileCircleCheck,
  faFileCircleXmark,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import AdminAppNav from "../../components/AdminAppNav";
import { Modal, Button, Form } from "react-bootstrap";

function EventScanlist() {
  const { id } = useParams();
  const location = useLocation();
  const type = "Events";

  moment.tz.setDefault("Europe/Rome");
  registerLocale("it", it);

  //dichiara variabili di congresso
  const [subscribe, setSubscribe] = useState([]);

  //variabili server, error
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [errModalMsg, setErrModalMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [typeOfScan, setTypeOfScan] = useState(null);
  const [typeOfAction, setTypeOfAction] = useState(null);
  // MODAL -> type 1 = ADD, type 2 = VISUAL, type 3 = EDIT

  const [idOrder, setIdOrder] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [action, setAction] = useState("");

  const [visualScanList, setVisualScanList] = useState([]);

  // const [send, setSend] = useState(false);
  // console.log(send)

  const renderAddEnter = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Aggiungi entrata
    </Tooltip>
  );

  const renderAddExit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Aggiungere uscita
    </Tooltip>
  );

  const renderNewEnter = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Aggiungi nuova entrata
    </Tooltip>
  );

  const navigate = useNavigate();

  //chiamata di visualizzazione congressi, con conteggio iscritti

  const getSubscribe = async () => {
    try {
      setLoading(true);
      let body = {
        id: id,
        access_token: auth.accessToken,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(
        Globals.ADMINGETALLSCANSUBEVENTS,
        formdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        setLoading(false);
        setErrMsg(response?.data?.message);
      } else {
        setLoading(false);
        setSubscribe(response?.data?.subscribers);
      }
    } catch (err) {
      setLoading(false);
      setErrMsg(err);
    }
  };

  const exportExcel = async () => {
    window
      .open(
        Globals.EXPORTSCANEXCEL +
          id +
          "&type=" +
          type +
          "&accessToken=" +
          auth.accessToken,
        "name",
        "titlebar=no"
      )
      .focus();
  };

  const openAddModal = (type = 1, order = null, user) => {
    setErrModalMsg("");
    setTypeOfAction(1);
    setModalShow(true);
    setIdOrder(order);
    setTypeOfScan(type);
    setAction(
      (type === 1
        ? "Registrazione scan entrata"
        : type === 2
        ? "Registrazione Nuova scan entrata"
        : "Registrazione scan di uscita") +
        " - " +
        user
    );
  };

  const visualizeAllScan = async (id, user) => {
    try {
      let body = {
        id: id,
        access_token: auth.accessToken,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(Globals.ADMINGETSINGLEORDER, formdata, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setErrModalMsg(response?.data?.message);
      } else {
        setErrModalMsg("");
        setModalShow(true);
        setTypeOfAction(2);
        setAction("Visualizza scan di : " + user);
        setVisualScanList(response?.data?.scan);
      }
    } catch (err) {
      setErrModalMsg(err);
    }
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " ora " : " ore ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto " : " minuti ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " secondo" : " secondi") : "";
    if (hDisplay === "" && mDisplay === "" && sDisplay === "") {
      return (
        <Card
          style={{
            color: "dark",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <span className="mx-2">
            <span style={{ fontWeight: 600 }}>Totale stimato: </span> da
            calcolare
          </span>
          <FontAwesomeIcon
            icon={faFileCircleXmark}
            style={{
              color: "red",
              fontSize: "22px",
              marginLeft: "5px",
            }}
          />{" "}
        </Card>
      );
    }
    return (
      <Card
        style={{
          color: "dark",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <span className="mx-2">
          {" "}
          <span style={{ fontWeight: 600 }}>Totale stimato: </span>{" "}
          {hDisplay + mDisplay + sDisplay}
        </span>
        <FontAwesomeIcon
          icon={faFileCircleCheck}
          style={{
            color: "lightgreen",
            fontSize: "22px",
            marginLeft: "5px",
          }}
        />{" "}
      </Card>
    );
  }
  const insertNewScan = async () => {
    try {
      setLoading(true);
      const formattedDate = moment(dateTime).format();

      let body = {
        order_code: idOrder,
        access_token: auth.accessToken,
        datetime: formattedDate,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(Globals.INSERTMANUALSCAN, formdata, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setLoading(false);
        setErrModalMsg(response?.data?.message);
      } else {
        setLoading(false);
        getSubscribe();
        setModalShow(false);
      }
    } catch (err) {
      setLoading(false);
      setErrModalMsg(err);
    }
  };

  const deleteSingleScan = async (id) => {
    try {
      setLoading(true);
      let body = {
        id: id,
        access_token: auth.accessToken,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(
        Globals.ADMINDELETESINGLESCAN,
        formdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        setLoading(false);
        setErrMsg(response?.data?.message);
        toast.error(response?.data?.message);
      } else {
        setLoading(false);
        setModalShow(false);

        toast.success(`Operazione completata con successo`);

        getSubscribe();
      }
    } catch (err) {
      setLoading(false);
      setErrMsg(err);
    }
  };

  const deleteAllScan = async (id) => {
    try {
      setLoading(true);
      let body = {
        id: id,
        access_token: auth.accessToken,
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));

      const response = await axios.post(
        Globals.ADMINDELETEALLORDERSCAN,
        formdata,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        setLoading(false);
        setErrMsg(response?.data?.message);
        toast.error(response?.data?.message);
      } else {
        setLoading(false);
        setModalShow(false);

        getSubscribe();
        toast.success(`Operazione completata con successo`);
      }
    } catch (err) {
      setLoading(false);
      setErrMsg(err);
    }
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "add",
        Header: "Azioni",
        Cell: ({ row }) => (
          <span style={{ textAlign: "center" }}>
            {parseInt(row.original.conseguimento) === 1 ? (
              <div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderAddEnter}
                >
                  <Button
                    variant="success"
                    onClick={() => {
                      openAddModal(1, row.original.id, row.original.utente);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{
                        color: "lightgreen",
                        fontSize: "22px",
                        marginLeft: "5px",
                      }}
                    />
                    <span className="mx-2"> Aggiungi entrata </span>
                  </Button>
                </OverlayTrigger>
              </div>
            ) : parseInt(row.original.conseguimento) === 2 ? (
              <div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderNewEnter}
                >
                  <Button
                    variant="success"
                    onClick={() => {
                      openAddModal(1, row.original.id, row.original.utente);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{
                        color: "lightgreen",
                        fontSize: "22px",
                        marginLeft: "5px",
                      }}
                    />
                    <span className="mx-2"> Aggiungi entrata </span>
                  </Button>
                </OverlayTrigger>
              </div>
            ) : (
              <div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderAddExit}
                >
                  <Button
                    variant="danger"
                    onClick={() => {
                      openAddModal(3, row.original.id, row.original.utente);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{
                        color: "white",
                        fontSize: "22px",
                        marginLeft: "5px",
                      }}
                    />
                    <span className="mx-2">Aggiungi uscita</span>
                  </Button>
                </OverlayTrigger>
              </div>
            )}
          </span>
        ),
      },
      {
        id: "Visualize",
        Header: "",
        Cell: ({ row }) =>
          row.original.empty === true ? (
            <FontAwesomeIcon
              icon={faEyeSlash}
              style={{ color: "grey" }}
              cursor={"pointer"}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "black" }}
              onClick={() =>
                visualizeAllScan(row.original.id, row.original.utente)
              }
              cursor={"pointer"}
            />
          ),
      },
      {
        id: "delete",
        Header: "",
        Cell: ({ row }) => (
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() =>
              window.confirm(
                "Eliminare tutte le scan di relative a quest'ordine di " +
                  row.original.utente
              )
                ? deleteAllScan(row.original.id)
                : ""
            }
            cursor={"pointer"}
          />
        ),
      },
    ]);
  };

  const subscribeData = useMemo(() => [...subscribe], [subscribe]);

  const subscribeColumns = useMemo(
    () =>
      subscribe[0]
        ? Object.keys(subscribe[0])
            .filter(
              (key) =>
                key !== "id" && key !== "conseguimento" && key !== "empty"
            )
            .map((key) => {
              if (key === "ultima scan")
                return {
                  Header: "Esito",
                  accessor: key,
                  maxWidth: 70,
                  Cell: ({ value }) => (
                    <span
                      style={{
                        color:
                          value === "Entrata"
                            ? "green"
                            : value === "Uscita"
                            ? "red"
                            : "black",
                        fontWeight:
                          value === "Nessuna scan" ? "normal" : "bold",
                      }}
                    >
                      {value}
                    </span>
                  ),
                };
              if (key === "ore conseguite")
                return {
                  Header: "Ore/minuti",
                  accessor: key,
                  maxWidth: 70,
                  Cell: ({ value }) => (
                    <span style={{ textAlign: "center" }}>
                      {/* {new Date(value * 1000).toISOString().substring(11, 16)} */}
                      {secondsToHms(value)}
                    </span>
                  ),
                };
              if (key === "")
                return {
                  Header: "Ore/minuti",
                  accessor: key,
                  maxWidth: 70,
                  Cell: ({ value }) => (
                    <div style={{ textAlign: "center" }}>
                      <span>
                        {new Date(value * 1000).toISOString().substring(11, 16)}
                      </span>
                    </div>
                  ),
                };
              return { Header: key, accessor: key };
            })
        : [],
    [subscribe]
  );

  const tableInstance = useTable(
    {
      columns: subscribeColumns,
      data: subscribeData,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    page,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  useEffect(() => {
    getSubscribe();
  }, []);

  //dichiarazione tabella di visualizzazione dei subscribers con annesso hover

  return (
    <div
      className="container"
      style={{ marginTop: "140px", marginBottom: "100px" }}
    >
      <AdminAppNav />
      <div>
        <Button onClick={() => navigate(-1)}>
          <span>
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{
                color: "#fff",
                fontSize: "15px",
                marginLeft: "5px",
              }}
            />
          </span>
          <span> Torna indietro</span>
        </Button>
        <Button
          className="float-end mx-2"
          variant="success"
          onClick={() => exportExcel()}
        >
          <span> Esportazione excel scannerizzazioni</span>
        </Button>
        <h2>
          {" "}
          Gestione iscrizione all'evento{" "}
          <strong> "{location.state.name}"</strong>
        </h2>
        {/* <Button onClick={() => NewModal()}> Inserisci Nuovo Ordine </Button> */}
      </div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={state.globalFilter}
      />

      {loading === true ? (
        <Loading />
      ) : (
        <>
          {subscribe.length === 0 ? (
            <span> Nessun iscritto a questo evento</span>
          ) : (
            <div>
              <Table
                {...getTableProps()}
                striped
                bordered
                hover
                responsive
                variant="light"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            borderBottom: "solid 3px ",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ▼"
                              : " ▲"
                            : ""}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{
                                padding: "5px",
                                border: "solid 1px gray",
                                cursor: "pointer",
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card>
                <div className="responsive-pagination">
                  <div>
                    <button
                      className="btn btn-light"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>{" "}
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Precedente
                    </button>{" "}
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Successiva
                    </button>{" "}
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                      className="btn btn-light mx-1"
                    >
                      {">>"}
                    </button>{" "}
                  </div>
                  <div className="float-end">
                    <span className="w-100">
                      Pagina:{" "}
                      <strong>
                        {pageIndex + 1} di {pageOptions.length}
                      </strong>{" "}
                    </span>
                  </div>
                  <div className="text-center">
                    <span>
                      Vai alla pagina{" "}
                      <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                        className="d-flex justify-content-center mx-auto responsive-pagination"
                      />
                    </span>{" "}
                  </div>
                  <select
                    className="form-select form-select-sm mt-4 w-25 mx-auto responsive-pagination"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[10, 25, 50, 75].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Visualizza {pageSize} righe
                      </option>
                    ))}
                  </select>
                </div>
              </Card>
            </div>
          )}
        </>
      )}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: "9999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{action}</Modal.Title>
        </Modal.Header>

        {typeOfAction === 1 && (
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formGridState">
              <Form.Label>Tipo di scan</Form.Label>
              <Form.Select
                defaultValue={typeOfScan}
                disabled={true}
                onChange={(e) => setTypeOfScan(e.target.value)}
              >
                <option value={1}>Entrata</option>
                <option value={3}>Uscita</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridState">
              <Form.Label>Data/Ora</Form.Label>
              <br></br>

              <DatePicker
                selected={dateTime}
                onChange={(date) => setDateTime(date)}
                locale="it"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd HH:mm:ss"
              />
            </Form.Group>

            {errModalMsg && (
              <p className="fs-1 fw-bolder danger"> Errore : {errModalMsg}</p>
            )}
          </Modal.Body>
        )}

        {typeOfAction === 2 && (
          <Modal.Body>
            {visualScanList?.map((item) => {
              return (
                <Card>
                  <Card.Body>
                    <p>
                      ESITO :{" "}
                      {parseInt(item.result) === 1 ? "Entrata" : "Uscita"}
                    </p>
                    <p> DATA : {item.date}</p>
                    <p> Utente : {item.user}</p>
                    <Button
                      variant="danger"
                      onClick={() =>
                        window.confirm("Sicuro di voler eliminare questa scan?")
                          ? deleteSingleScan(item.id)
                          : ""
                      }
                    >
                      {" "}
                      Elimina{" "}
                    </Button>
                  </Card.Body>
                </Card>
              );
            })}
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Chiudi</Button>
          {typeOfAction === 1 && (
            <Button variant="success" onClick={() => insertNewScan()}>
              Conferma
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {errMsg && <p className="fs-1 fw-bolder danger"> {errMsg}</p>}
    </div>
  );
}

export default EventScanlist;
