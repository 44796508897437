import Carousel from "react-bootstrap/Carousel";
import "../scss/carousel.scss";
import parse from "html-react-parser";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import standardVideoImage from "../images/fzrcgejp.bmp";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";
import AOS from "aos";
import "aos/dist/aos.css";
import overlayImage from "../images/badge.png";
import img1 from "../images/empty_carousel/001.png";
import img2 from "../images/empty_carousel/002.png";
import img3 from "../images/empty_carousel/003.png";
function CarouselSol(props) {
	let data = props.data || [];
	let video = props.video || [];
	let video_home = props.video_home || [];

	useEffect(() => {
		AOS.init();
	}, []);

	useEffect(() => {
		const glowInTexts = document.querySelectorAll(".glowIn");

		// split all text content into letters
		// for each letter, wrap it with a span
		// then let each span executes animation in sequence.
		glowInTexts.forEach((glowInText) => {
			const letters = glowInText.textContent.split("");
			glowInText.textContent = "";
			letters.forEach((letter, i) => {
				const span = document.createElement("span");
				span.textContent = letter;
				span.style.animationDelay = `${i * 0.02}s`;
				glowInText.append(span);
			});
		});
	}, []);

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		return month;
	};

	if (parseInt(video_home.length) === 0 && parseInt(data.length) === 0) {
		return (
			<div id='home'>
				<Carousel>
					<Carousel.Item
						className={"responsive-position-caption carousel-item-center"}>
						<img className='d-block w-100 ' src={img1} alt='First slide' />
					</Carousel.Item>
					<Carousel.Item
						className={"responsive-position-caption carousel-item-center"}>
						<img className='d-block w-100 ' src={img2} alt='Second slide' />
					</Carousel.Item>
					<Carousel.Item
						className={"responsive-position-caption carousel-item-center"}>
						<img className='d-block w-100 ' src={img3} alt='Third slide' />
					</Carousel.Item>
				</Carousel>
			</div>
		);
	} else {
		return (
			<div id='home'>
				{video.map((item, key) => (
					<div key={item}>
						{video_home.length !== 0 ? (
							<div
								className='bg-video-wrap'
								style={{ backgroundImage: "url(" + { standardVideoImage } + ")" }}>
								<video
									loop
									muted
									autoPlay
									playsInline
									disablePictureInPicture
									width='100%'
									height='100%'
									className='iframe-react-carousel'>
									<source src={video_home.url} type='video/mp4'></source>
									<img src={standardVideoImage} title='Immagine di fallback' />
								</video>

								<div className='principal-description'>
									<h1 className='glowIn'>
										{video_home.title !== "" ? (
											<div>{video_home.title}</div>
										) : (
											<div></div>
										)}
									</h1>
									<p className='glowIn'>
										{video_home.description !== "" ? (
											<div>{video_home.description}</div>
										) : (
											<div></div>
										)}
									</p>
									{video_home.button_name !== "" ? (
										<div>
											<a target='_blank' href={video_home.link_button}>
												<button
													className='btn btn-danger btn-lg'
													style={{ backgroundColor: "#d50037" }}>
													{video_home.button_name}
												</button>
											</a>
										</div>
									) : (
										<div></div>
									)}
								</div>
								<div className='overlay-video'></div>
							</div>
						) : (
							<Carousel variant='dark' pause={false} className="carousel-sol" controls={false} interval={2500} id='home'>
								{!Array.isArray(data) ? <></> : data.map((item, key) => (
									<Carousel.Item
										className={
											"responsive-position-caption" + parseInt(item.active_card) !== 1
												? "carousel-item-center"
												: "carousel-item-center"
										}>
											<div className="bg-image-wrap">
											
										<img
											src={item.url}
											className='img-fluid image-card-carousel '
											alt='...'
										/>
										</div>

										{parseInt(item.active_card) === 1 ? (
											<div>
												<Carousel.Caption className='position-caption-central '>
													<Card
														data-aos='fade-right'
														style={{ border: "1px solid #d50037" }}>
														{item.title_claim}
													</Card>
												</Carousel.Caption>
												{parseInt(item.active_overlay_image) !== 0 ? (
													<div
														className='badge-carousel'
														data-aos='fade-left'
														data-aos-duration='1000'
														data-aos-delay='1000'>
														<img className='img-fluid' src={overlayImage} alt='Badge' />
													</div>
												) : (
													<div></div>
												)}
											</div>
										) : (
											<div></div>
										)}

										{parseInt(item.active_advertising_card) === 1 ? (
											<div className='container-carousel-height-responsive'>
												<Carousel.Caption className='position-caption'>
													<Card className='card border border-danger'>
														<Card.Header className='title-carousel'>
															<div className='circle'></div>
														</Card.Header>
														<Card.Text className='date-carousel'>
															{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
															{formatYear(item.start_date)} / {formatDay(item.end_date)}-
															{formatMonth(item.end_date)}-{formatYear(item.end_date)}
														</Card.Text>
														<Card.Body className='body-carousel'>
															<Card.Title className='date-title'>{item.title}</Card.Title>
															<Card.Text className='description-carousel'>
																{parse(item.description)}
															</Card.Text>
															<div className='container-btn-carousel'>
																<Link to={item.link}>
																	<Button variant='danger btn-lg' className='btn-carousel'>
																		{item.text_button}{" "}
																	</Button>
																</Link>
															</div>
														</Card.Body>
													</Card>
												</Carousel.Caption>
												{parseInt(item.active_overlay_image) !== 0 ? (
													<div
														className='badge-carousel'
														data-aos='fade-left'
														data-aos-duration='1000'
														data-aos-delay='1000'>
														<img className='img-fluid' src={overlayImage} alt='Badge' />
													</div>
												) : (
													<div></div>
												)}
											</div>
										) : (
											<div></div>
										)}
									</Carousel.Item>
								))}
							</Carousel>
						)}
					</div>
				))}
			</div>
		);
	}
}

export default CarouselSol;
