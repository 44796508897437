import React, { useState } from "react";
import "../scss/newsletter.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Globals from "../parameter/globals";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

function Newsletter() {
	const { lang } = useContext(AuthContext);

	const [email, setemail] = useState("");
	const [privacy, setPrivacy] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (privacy === false) {
			setErrMsg = Globals.language.newsletter.errorPrivacyNewsletter[lang];
			return false;
		}

		try {
			let body = {
				email: email,
				privacy: privacy,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.SUBSCRIBE_NEWSLETTER, formdata, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setErrMsg(response.data.message);
				setSuccess(false);
			} else {
				//clear state and controlled inputs
				setSuccess(true);
			}
		} catch (err) {
			setSuccess(false);
			setErrMsg(Globals.language.errorLabel.serverError[lang]);
		}
	};

	return (
		<div className='container-fluid my-5'>
			{success ? (
				<div className='container py-5'>
					<Card>
						<div className='row text-center d-flex justify-content-center'>
							<div className='col-sm-6 col-sm-offset-3'>
								<h2 style={{ color: "green" }}>
									{Globals.language.newsletter.titleAccossentNewsletter[lang]}
								</h2>
								<i
									className='bi bi-check-circle'
									style={{ fontSize: "50px", color: "green" }}></i>
								<p style={{ fontSize: "20px", color: "#5C5C5C" }}>
									{Globals.language.newsletter.descAccossentNewsletter[lang]}
								</p>
								{/*<btn className="btn btn-success btn-lg"><Link className="text-light" to='/login'> Clicca qui per andare al login  </Link></btn>*/}
							</div>
						</div>
					</Card>
				</div>
			) : (
				<>
					<div className='row'>
						<div className='col-sm text-center'>
							<h1 className='div-heading display-4 title-homepage-all'>
								{Globals.language.newsletter.subrscribeNewsletter[lang]}
							</h1>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6 col-sm-12 mx-auto text-start'>
							<Form onSubmit={(e) => handleSubmit(e)}>
								<Form.Group
									className='mb-3 col-sm-12 display-newsletter-full'
									controlId='formBasicEmail'>
									<Form.Control
										type='email'
										placeholder={Globals.language.newsletter.insertEmailNewsletter[lang]}
										value={email}
										onChange={(e) => setemail(e.target.value)}
										required
									/>
								</Form.Group>
								<Form.Group className='mb-3' controlId='formBasicCheckbox'>
									<Form.Check
										type='checkbox'
										label={Globals.language.newsletter.newsletterConfirmPrivacy[lang]}
										onChange={(e) =>
											e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
										}
										required
									/>
								</Form.Group>
								<Button
									variant='danger'
									style={{backgroundColor:"#d50037"}}
									type='submit'
									className='d-flex justify-content-center m-auto'>
									{Globals.language.newsletter.buttonSubscribeNewsletter[lang]}
								</Button>
								{errMsg && <span> {errMsg} </span>}
							</Form>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Newsletter;
