import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "../../scss/success-page.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import Globals from "../../parameter/globals";

function Unathourized() {
	const { lang } = useContext(AuthContext);

	return (
		<div
			className='container'
			style={{ marginTop: "140px", marginBottom: "100px" }}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{Globals.language.title.unathorized[lang]}</title>
				<link rel='canonical' href='https://www.soluzioniomniamedia.com/' />
				<meta name="robots" content="noindex" />
			</Helmet>
			<Card className='text-center'>
				<Card.Header>
					<i
						className='bi bi-exclamation-octagon-fill text-danger'
						style={{ fontSize: 200 }}></i>
					<h2>{Globals.language.errorLabel.error_unauthorized[lang]} </h2>
				</Card.Header>
				<Card.Body>
					{/* <h2>Ecco le informazioni di ripeilogo del tuo ordine </h2> */}
					<Card.Title>
						<h2>{Globals.language.errorLabel.label_unauthorized[lang]} </h2>
					</Card.Title>
					<Link to='/home'>
						<Button className="mt-5" variant='danger'>{Globals.language.cart.backHome[lang]}</Button>
					</Link>
				</Card.Body>
			</Card>
		</div>
	);
}

export default Unathourized;
