import "../scss/error404.scss";
import { Helmet } from "react-helmet";
import AuthContext from "../context/AuthProvider";
import React, {useContext} from "react";
import Globals from "../parameter/globals";

const Error = () => {

	const {lang} = useContext(AuthContext);

	return (
		<div className='error-404'>
			{/* <div className="error">
            <svg classname="svg-error" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 600 300">

                <symbol id="s-text">
				x="50%" y="50%" dy=".35em">
				404
				<text text-anchor="middle"
				</text>

                </symbol>

                <use xlinkHref="#s-text" className="text"
                ></use>
                <use xlinkHref="#s-text" className="text"
                ></use>
                <use xlinkHref="#s-text" className="text"
                ></use>
                <use xlinkHref="#s-text" className="text"
                ></use>
                <use xlinkHref="#s-text" className="text"
                ></use>

            </svg>
            <div className='d-flex justify-content-center p-2 text-light'>
                <h2>Pagina non trovata</h2>
        
            </div>
            <div className='d-flex justify-content-center p-2'>
            <button className='btn btn-success  btn-lg' onClick={() => navigate("/")}>
				{" "}
				Torna alla home
		</button>
        </div>
        </div> */}

			<Helmet>
				<meta charSet='utf-8' />
				<title>404 - Soluzioni Omnia media</title>
				<link rel='canonical' href='https://www.soluzioniomniamedia.com/' />
				<meta name="robots" content="noindex" />
			</Helmet>
			<div
				className='row w-100'
				style={{ marginTop: "200px", marginBottom: "150px" }}>
				<div className='col-md-12'>
					<div className='error-template'>
						<h1>Oops!</h1>
						<h2>404 Not Found</h2>
						<div className='error-details'>
							{Globals.language.errorLabel.errorPagelabel[lang]}
						</div>
						<div className='error-actions'>
							<a
								href='/home'
								className='btn text-light btn-lg'
								style={{ backgroundColor: "#d50037" }}>
								<span className='glyphicon glyphicon-home'></span>
								{Globals.language.errorLabel.comeBackHome[lang]}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Error;
