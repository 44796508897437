import React, {useContext, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Loading from "./Loading";

import AuthContext from "../context/AuthProvider";
import Globals from "../parameter/globals";
import axios from "axios";
import CartContext from "../context/CartProvider";

function ModalCoupon(props) {
	const {lang} = useContext(AuthContext);
	const {setCoupon} = useContext(CartContext);
	const [couponCode, setCouponCode] = useState("");
	const [selectedSponsor, setSelectedSponsor] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({
		isError: false,
		message: "",
	});

	const checkCouponCode = async () => {
		// controllare se il codice è vuoto
		if (couponCode === "") {
			return false;
		}
		// controllare se è stato inserito uno sponsor
		if (selectedSponsor === "") {
			return false;
		}

		try {
			setLoading(true);

			let body = {
				code: couponCode,
				sponsor: selectedSponsor,
				congress_id: props.congressId,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.CHECKCOUPONCODE + '&lang=' + lang, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response.data.error) {
				setLoading(false);
				setError({isError: true, message: response.data.message});
			} else {
				setLoading(false);

				if (response.data.valid) {
					setCoupon({
						active: true,
						code: couponCode,
						sponsor: selectedSponsor,
						congress_id: props.congressId,
					});
					props.methodPayment(response.data.id_quote);
					props.onHide();
				}
			}
		} catch (err) {
			setLoading(false);
			setError({isError: true, message: Globals.language.errorLabel.serverError});
		}
	};

	return (
		<Modal
			{...props}
			aria-labelledby='contained-modal-title-vcenter'
			centered
			style={{zIndex: "9999"}}>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{" "}
					{props.action}{" "}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<Loading />
				) : (
					<Form.Group className='mb-2' controlId='formGridState'>
						<p>{Globals.language.ticketService.labelModalCoupon[lang]}</p>

						<Form.Control
							type='text'
							className='mb-4'
							onChange={(e) => setCouponCode(e.target.value)}
							placeholder={Globals.language.ticketService.labelInsertCode[lang]}
						/>

						<Form.Select onChange={(e) => setSelectedSponsor(e.target.value)}>
							<option selected value={""}>
								{Globals.language.ticketService.labelSelectSponsor[lang]}
							</option>

							{props.sponsor?.map((item, key) => {
								return (
									<option value={item.id} key={key}>
										{item.name}
									</option>
								);
							})}
						</Form.Select>
					</Form.Group>
				)}

				{error.isError ? <p style={{color: "red"}}> {error.message}</p> : <></>}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='success'
					onClick={() => checkCouponCode()}
					disabled={couponCode !== "" && selectedSponsor !== "" ? false : true}>
					{Globals.language.infoModal.confirm[lang]}
				</Button>
				<Button variant='danger' onClick={props.onHide}>
					{Globals.language.infoModal.cancel[lang]}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ModalCoupon;
