import React from "react";
import Portfolio from "../../components/Portfolio";

function PortfolioSol() {
    return (
        <div>
        <Portfolio></Portfolio>
        </div>
    );
}

export default PortfolioSol;
