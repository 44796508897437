import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Globals from "../parameter/globals";
import Loading from "./Loading";

const RequireAuth = ({ allowedRoles }) => {
	const { auth, setAuth, lang } = useAuth();
	const [loading, setLoading] = useState(true);

	const checkLogin = async () => {
		let myAccessToken = localStorage.getItem("accessToken");

		if (myAccessToken !== "") {
			try {
				let formdata = new FormData();
				formdata.append("body", myAccessToken);

				const response = await axios.post(Globals.CHECKLOGIN, formdata, {
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				});

				let accessToken = myAccessToken;
				let roles = response?.data?.roles;
				let user = response?.data?.username;
				setAuth({ user, accessToken, roles });
				setLoading(false);
			} catch (err) {
				setLoading(false);
				setAuth({});
			}
		}

		setLoading(false);
	};

	useEffect(() => {
		checkLogin();
	}, []);

	const location = useLocation();
	let role = auth?.roles?.toString();

	if (loading) {
		return <Loading />;
	} else {
		return allowedRoles.includes(role) ? (
			<Outlet />
		) : auth?.user ? (
			<Navigate to='/unauthorized' state={{ from: location }} replace />
		) : (
			<Navigate to='/login' state={{ from: location }} replace />
		);
	}
};

export default RequireAuth;
