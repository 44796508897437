import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import "../scss/eventi.scss";
import Image from "react-bootstrap/Image";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../context/CartProvider";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import Globals from "../parameter/globals";
import img1 from "../images/empty_carousel/001.png";
import img2 from "../images/empty_carousel/002.png";
import img3 from "../images/empty_carousel/003.png";
import AuthContext from "../context/AuthProvider";
import { Helmet } from "react-helmet";

function Eventi(props) {
	const { lang } = useContext(AuthContext);

	const eventElements = props.data || false;

	const { onAdd, activeEcommerce } = useContext(CartContext);

	const navigate = useNavigate();
	const [error, setError] = useState(false);

	const [message, setMessage] = useState("");
	const [portfoil, setPortfoil] = useState([]);

	const formatYear = (item) => {
		let d = new Date(item);
		let year = d.getFullYear();
		return year;
	};
	const formatDay = (item) => {
		let d = new Date(item);
		let day = "" + d.getDate();
		if (day.length < 2) {
			day = "0" + day;
		}

		return day;
	};
	// const formatMonth = (item) => {
	// 	let d = new Date(item);

	// 	let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	// 	if (month.length < 2) month = "0" + month;
	// 	return month;
	// };
	const formatMonth = (item) => {
		let d = new Date(item);
		let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
		if (month.length < 2) month = "0" + month;
		d.setMonth(month - 1);

		let local = lang === "it" ? "it-IT" : "en-GB";

		let chartMonth = d.toLocaleString(local, { month: "long" });

		return chartMonth.substring(0, 3);
	};

	useEffect(() => {
		getEventPortfoil();
	}, []);

	const getEventPortfoil = async () => {
		try {
			const response = await axios.get(Globals.GETPORTFOIL, {
				headers: { "Content-Type": "application/json" },
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setError(true);
				setMessage(response?.data?.error);
			} else {
				setPortfoil(response?.data?.portofolio);
			}
		} catch (err) {
			setError(true);
			setMessage(err);
		}
	};
	return (
		<div className='container container-eventi scroll-color-colors' id='events'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Eventi - Soluzioni Omnia media</title>
				<link rel='canonical' href='https://www.soluzioniomniamedia.com/#events' />
			</Helmet>
			<h2 className='text-center title-homepage-all'>
				{eventElements.length !== 0
					? Globals.language.events.titleEvents[lang]
					: ""}
			</h2>
			<ul className='cards'>
				{eventElements !== false && eventElements.length !== 0 ? (
					eventElements.map((item, key) => (
						<li
							className={
								item.already_subscribe === true || item.limit === true
									? "card card-events border border-0 mx-4 "
									: "card border border-0 mx-4"
							}
							data-label={
								item.already_subscribe === true
									? Globals.language.events.iscritto[lang]
									: item.limit === true
									? Globals.language.events.capienzaRaggiunta[lang]
									: ""
							}
							key={key}>
							<div>
								<div
									className='my-2 title-card-events mb-auto'
									style={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
										height:"95px"
									}}>
									<div className='circle-eventi'></div>
									{/* aggiunfere text-dark */}
									<h3 className='card-title  mx-5'>{item.name}</h3>
								</div>

								<div className='container-card-congressi-responsive'>
									<section className='data-container'>
										<span className='p-0 mx-1'>
											{formatDay(item.start_date) === "NaN"
												? "00"
												: formatDay(item.start_date)}{" "}
											{formatMonth(item.start_date) === "NaN"
												? "00"
												: formatMonth(item.start_date)}{" "}
										</span>

										<div>
											<i className='bi bi-calendar-event' style={{ fontSize: "15px" }}></i>{" "}
											<span style={{ fontSize: "15px" }} className='mx-1 year-font'>
												{formatYear(item.start_date) !== "Nan"
													? formatYear(item.start_date)
													: "0000"}
											</span>
										</div>
									</section>

									<aside className='image-container'>
										<img
											src={item.url}
											className=' img-responsive image-card-congressi'
											alt='...'
										/>
									</aside>
								</div>
								<div
									className='resposnive-events-card'
									style={{
										display: "flex",
										justifyContent: "start",
										alignItems: "center",
									}}>
									<div className='col-lg-2 col-md-0 col-sm-0'></div>
									<div className='col-lg-10 col-md-11 col-sm-12'>
										<div className='text-congress-description my-2 '
										style={{ minHeight: "95px" }}>

											<span className='mx-2 my-2 '>
												{item.short_description !== ""
													? item.short_description
													: Globals.language.events.nessunaDescrizione[lang]}
											</span>
										</div>
										<div className='card-link-wrapper w-75 btn-congress'>
											{/* {item.already_subscribe === true || item.limit === true ? (
												<div>
													<button
														className='navLink card-link-info my-2 border border-danger'
														style={{ cursor: "cursor", opacity: 0.5, textDecoration: "none" }}
														to={"/"}>
														{" "}
														{Globals.language.events.scopriPiu[lang]}{" "}
													</button>
												</div>
											) : ( */}
											<div>
												{" "}
												<Link
													className='navLink card-link-info my-2 border border-danger'
													to={"/event/" + item.id + "/" + item.slug_url + "#detail"}>
													{" "}
													{Globals.language.events.scopriPiu[lang]}{" "}
												</Link>{" "}
											</div>
											{/* )} */}

											{/*
											case 1 = Evento a pagamento
											case 2 = Evento gratuito con iscrizione
											case 3 = Evento senza iscrizione
											*/}
											{item.already_subscribe === true && (
												<span
													className='card-link border-0'
													style={{ backgroundColor: "grey" }}>
													{Globals.language.events.iscrittoCongresso[lang]}{" "}
												</span>
											)}
											{(item.already_subscribe === undefined ||
												item.already_subscribe === false) &&
												activeEcommerce[0] !== "1" && (
													<div>
														{item.limit === true && (
															<span className='card-link border-0'>
																{Globals.language.events.capienzaRaggiunta[lang]}{" "}
															</span>
														)}

														{item.is_buy === "1" && item.limit === false && (
															<button
																className='card-link border-0'
																onClick={() =>
																	navigate(
																		"/ticketevent/" + item.id + "/" + item.slug_url + "#detail"
																	)
																}>
																{Globals.language.events.iscriviti[lang]}{" "}
															</button>
														)}
														{item.is_buy === "2" && item.limit === false && (
															<button
																className='card-link border-0'
																onClick={() =>
																	navigate(
																		"/ticketevent/" + item.id + "/" + item.slug_url + "#detail"
																	)
																}>
																{Globals.language.events.iscrivitiGratuitamente[lang]}{" "}
															</button>
														)}
														{item.is_buy === "3" && <div></div>}
													</div>
												)}
										</div>
									</div>
								</div>
							</div>
						</li>
					))
				) : (
					<div className='container py-2'>
						<Card>
							<div className='row text-center d-flex justify-content-center'>
								<div className='col-lg-6 col-md-12 col-sm-12 col-sm-offset-3'>
									<h2 className='text-danger'>
										{Globals.language.events.messageNoEventsCongress[lang]}{" "}
									</h2>
									<h4 className='text-dark'>
										{parseInt(portfoil.length) !== 0
											? Globals.language.events.titleNoEventsCongress[lang]
											: ""}
									</h4>
									<div className='col-md-12 ps-0 '>
										<div className='form-right h-100 bg-light text-white text-center pt-5 '>
											<Carousel>
												{parseInt(portfoil.length) !== 0 ? (
													portfoil.map((item) => (
														<Carousel.Item className='carousel-events-not-events'>
															<img className='d-block w-100 ' src={item.url} alt='Slide' />
															<Carousel.Caption>
																<h3>{item.name}</h3>
																<p>{item.short_description}</p>
															</Carousel.Caption>
														</Carousel.Item>
													))
												) : (
													<div>
														<img className='d-block w-100 ' src={img2} alt='Slide' />
													</div>
												)}
											</Carousel>
										</div>
									</div>
								</div>
							</div>
						</Card>
					</div>
				)}
			</ul>
		</div>
	);
}

export default Eventi;
