import React, {useState, useEffect, useContext} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../context/AuthProvider";

import {ListGroup, Container, Col, Row, Form, Table} from "react-bootstrap";
import Loading from "./Loading";
import axios from "axios";
import Globals from "../parameter/globals";
import Select from "react-select";
import {toast} from "react-hot-toast";

function SubscribeModal(props) {
	const {action, id, method, data, service, ticket, type, agents} = props.data;
	const {getOrder} = props;

	const {auth} = useContext(AuthContext);

	const [sendEmail, setSendEmail] = useState(false);
	const [pay, setNotPay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const [viewAgents, setAgents] = useState([]); // lista degli agenti relativi ad un congresso/evento
	const [selectedAgent, setSelectedAgent] = useState([]); // agente scelta

	const [selectedService, setSelectedService] = useState([]); // servizio scelta

	const [selectedTicket, setSelectedTicket] = useState([]); // servizio scelta
	const [selectedElement, setSelectedElement] = useState([]);

	const [selectUser, setSelectUser] = useState("");

	const [success, setSuccess] = useState(false);

	const escludePrevService = (element) => {
		setSelectedService(
			selectedService.filter(
				(item) => item.id_service_category !== element.id_service_category,
			),
		);
		setSelectedService((selectedService) => [...selectedService, element]);
	};

	const createOrder = async () => {
		setLoading(true);
		try {
			let body = {
				id: id,
				access_token: auth.accessToken,
				type: type === "congresso" ? "Congresso" : "Evento",
				services: selectedService,
				ticket: selectedTicket,
				sendEmail: sendEmail,
				isPayed: pay,
				user: selectUser,
				agent:
					selectedAgent?.value !== undefined && selectedAgent?.value !== ""
						? selectedAgent.value
						: false,
			};

			let formdata = new FormData();

			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(Globals.ADMINCREATEORDER, formdata, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});

			if (response?.data?.error === true) {
				setLoading(false);
				setError(response?.data?.message);
			} else {
				setLoading(false);
				setSuccess(true);
				props.onHide();
				getOrder();
				toast.success(`Ordine creato con successo.`);
			}
		} catch (error) {
			setLoading(false);
			setError(error);
			return false;
		}
	};
	const getAgent = async () => {
		if (type !== "" && props.data.id !== "") {
			try {
				let body = {
					id: props.data.id,
					access_token: auth.accessToken,
					type: type === "congresso" ? "Congresso" : "evento",
				};

				let formdata = new FormData();

				formdata.append("body", JSON.stringify(body));

				const response = await axios.post(Globals.ADMINGETNEWORDERAGENT, formdata, {
					headers: {"Content-Type": "application/json"},
					withCredentials: true,
				});

				if (response?.data?.error === true) {
					setError(response?.data?.message);
				} else {
					setAgents(response?.data?.agents);
				}
			} catch (error) {
				setError(error);
				return false;
			}
		}
	};

	useEffect(() => {
		setSelectedService([]);
		setSelectedTicket([]);
		setSelectUser("");
		setSelectedAgent("");
		setError("");
		setSuccess(false);
		setNotPay(false);
		setSendEmail(false);
	}, [props.onHide]);
	useEffect(() => {
		getAgent();
	}, [selectUser]);

	return (
		<Modal
			{...props}
			size='xl'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			style={{zIndex: "9999"}}>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'> {action} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='row gy-2 gx-3 align-items-center'>
					{loading && <Loading />}
				</div>
				{method === "visualize" && (
					<Container>
						<Row>
							<Col>Codice: {data.codice}</Col>
							<Col>Data ordine : {data.data_ora} </Col>
						</Row>

						<hr />
						<Row className='text-center'>
							<Col>
								<ListGroup>
									<ListGroup.Item variant='danger'>
										{" "}
										<strong> Dati utente</strong>
									</ListGroup.Item>
									<ListGroup.Item>
										{" "}
										<div className='fw-bold'>Nome </div> {data.name}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Cognome </div> {data.surname}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Codice Fiscale </div> {data.fiscal_code}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Data di nascita </div> {data.date_of_birth}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Luogo di nascita </div> {data.birthplace}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Email </div> {data.email}{" "}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Numero di telefono </div>{" "}
										{data.mobile_number}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Indirizzo </div> {data.address} - {data.city}{" "}
										- {data.province}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Stato </div> {data.country}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Specialità Medica </div>{" "}
										{data.medical_specialty}
									</ListGroup.Item>
								</ListGroup>
							</Col>
							{parseInt(data.azienda) === 1 && (
								<Col>
									<ListGroup>
										<ListGroup.Item variant='danger'>
											{" "}
											<strong> Dati aziendali</strong>
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>Azienda </div> {data.company}
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>Codice Fiscale azienda </div>{" "}
											{data.company_fiscal_code}
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>P.IVA </div> {data.vat_code}
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>PEC </div> {data.pec}
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>Codice univoco </div> {data.sdi}{" "}
										</ListGroup.Item>
										<ListGroup.Item>
											<div className='fw-bold'>Numero di telefono </div>{" "}
											{data.mobile_number}
										</ListGroup.Item>
										{/* <ListGroup.Item>
                      <div className="fw-bold">Indirizzo </div> {data.address} -{" "}
                      {data.city} - {data.province}
                    </ListGroup.Item> */}
									</ListGroup>
								</Col>
							)}
						</Row>
						{ticket !== "" && ticket !== null && ticket.length !== 0 && <hr />}
						<Row className='text-center'>
							{ticket !== "" && ticket !== null && ticket.length !== 0 ? (
								<>
									<Col>
										<ListGroup>
											<ListGroup.Item variant='danger'>
												{" "}
												<strong> Biglietto </strong>
											</ListGroup.Item>
											<ListGroup.Item>
												<div className='fw-bold'>Nome</div> {ticket.name}
											</ListGroup.Item>
											<ListGroup.Item>
												<div className='fw-bold'>Prezzo </div>{" "}
												{Intl.NumberFormat("de-DE", {
													style: "currency",
													currency: "EUR",
												}).format(ticket.price)}{" "}
											</ListGroup.Item>
										</ListGroup>
									</Col>
								</>
							) : type === "congresso" ? (
								<span> Nessun biglitto multiplo aggiunto </span>
							) : (
								<></>
							)}
						</Row>
						{service.length !== 0 && <hr />}
						<Row className='text-center'>
							{service.length !== 0 ? (
								<>
									<div className='fw-bold'>Servizi Aggiuntivi </div>
									{service?.map((item, key) => {
										return (
											<Col key={key}>
												<ListGroup>
													<ListGroup.Item variant='danger'>
														{" "}
														<strong> Servizio : {item.categories_name}</strong>
													</ListGroup.Item>
													<ListGroup.Item>
														<div className='fw-bold'>Nome servizio</div> {item.name}
													</ListGroup.Item>
													<ListGroup.Item>
														<div className='fw-bold'>Quantità </div> {item.quantity}
													</ListGroup.Item>
													<ListGroup.Item>
														<div className='fw-bold'>Prezzo </div>{" "}
														{Intl.NumberFormat("de-DE", {
															style: "currency",
															currency: "EUR",
														}).format(item.total_price)}{" "}
													</ListGroup.Item>
												</ListGroup>
											</Col>
										);
									})}
								</>
							) : type === "congresso" ? (
								<span> Nessun servizio aggiuntivo </span>
							) : (
								<></>
							)}
						</Row>

						<hr />
						<Row className='text-center'>
							<div>
								<ListGroup>
									<ListGroup.Item variant='danger'>
										{" "}
										<strong> Pagamento</strong>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Modalità di pagamento </div>{" "}
										{data.payment_method}
									</ListGroup.Item>
									{data.paypal_cart !== "" ? (
										<ListGroup.Item>
											<div className='fw-bold'>Codice paypal ordine </div>{" "}
											{data.paypal_cart}
										</ListGroup.Item>
									) : (
										<></>
									)}
									<ListGroup.Item>
										<div className='fw-bold'>Pagato </div>{" "}
										{parseInt(data.paid) === 1 ? "SI" : "NO"}
									</ListGroup.Item>
									<ListGroup.Item>
										<div className='fw-bold'>Totale </div>{" "}
										{Intl.NumberFormat("de-DE", {
											style: "currency",
											currency: "EUR",
										}).format(data.total_price)}{" "}
									</ListGroup.Item>
								</ListGroup>
							</div>
						</Row>
						<Row className='text-center'>
							<div>
								<div>
									<hr />
								</div>
								{props.data.data.additional_notes !== "" ? (
									<>
										<ListGroup>
											<ListGroup.Item variant='danger'>
												<strong> Note aggiuntive</strong>
											</ListGroup.Item>
											<ListGroup.Item>{props.data.data.additional_notes}</ListGroup.Item>
										</ListGroup>
									</>
								) : (
									<></>
								)}
							</div>
						</Row>

						<Row className='text-center'>
							{agents.length !== 0 ? (
								<>
									<div>
										<hr />
									</div>

									{agents?.map((item, key) => {
										return (
											<Col key={key}>
												<ListGroup>
													<ListGroup.Item variant='danger'>
														{" "}
														<strong> Agenti </strong>
													</ListGroup.Item>
													<ListGroup.Item>
														<div className='fw-bold'>Nome</div> {item.name}
													</ListGroup.Item>
													<ListGroup.Item>
														<div className='fw-bold'>Cognome </div> {item.surname}
													</ListGroup.Item>
												</ListGroup>
											</Col>
										);
									})}
								</>
							) : type === "congresso" ? (
								<span> Nessun servizio aggiuntivo </span>
							) : (
								<></>
							)}
						</Row>
					</Container>
				)}

				{method === "new" && (
					<>

						<Form.Group className='mb-2' controlId='formGridState'>
							<>
								{type === "congresso" && (
									<div className='mb-4'>
										{type === "congresso" && ticket?.length !== 0 && (
											<div className='mb-4'>
												<Form.Label>
													<strong>Seleziona quota di iscrizione </strong>{" "}
												</Form.Label>
												<Form.Select onChange={(e) => setSelectedTicket(e.target.value)}>
													<option selected value={""}>
														Seleziona quota di iscrizione ...
													</option>
													{ticket?.map((item, key) => {
														return (
															<option value={item.id} key={key}>
																{item.name}
															</option>
														);
													})}
												</Form.Select>
											</div>
										)}
										{service.length !== 0 && (
											<Form.Label>
												<strong>Seleziona servizi aggiuntivi </strong>{" "}
											</Form.Label>
										)}
										{service?.map((item, key) => {
											return (
												<Table responsive key={key}>
													<thead>
														<tr>
															<th width={"33%"} className='border-0 th-custom p-0'>
																<div className='principal-div-servizi'>
																	<div className='p-2 text-center border-0'>
																		{item[0].category}
																	</div>
																</div>
															</th>
														</tr>
														<tr style={{borderTop: "1px solid #d50037"}}>
															<th width={"10%"} className='border-0 text-center'></th>
															<th width={"30%"} className='border-0 text-center'>
																Descrizione
															</th>
															<th width={"30%"} className='border-0 text-center'>
																Prezzo
															</th>
														</tr>
													</thead>
													{item[0].is_esclusive === "1" &&
														item.map((element, index) => {
															return (
																<tbody key={index}>
																	<tr
																		style={{
																			opacity: element.disabled === true ? 0.2 : 1,
																		}}>
																		<td className='text-center'>
																			<input
																				type='radio'
																				name={item[0].category}
																				onChange={(e) => escludePrevService(element)}
																				disabled={element.disabled}
																			/>
																		</td>
																		<td className='text-center'> {element.name} </td>
																		<td className='text-center'>
																			{Intl.NumberFormat("de-DE", {
																				style: "currency",
																				currency: "EUR",
																			}).format(element.price)}{" "}
																			{/* <span>iva compresa</span> */}
																		</td>
																	</tr>
																</tbody>
															);
														})}

													{item[0].is_esclusive === "0" &&
														item.map((element, index) => {
															return (
																<tbody key={index}>
																	<tr
																		style={{
																			opacity: element.disabled === true ? 0.2 : 1,
																		}}>
																		<td className='text-center'>
																			<input
																				type='checkbox'
																				onChange={(e) =>
																					e.target.checked
																						? setSelectedService((selectedService) => [
																								...selectedService,
																								element,
																						  ])
																						: setSelectedService(
																								selectedService.filter(
																									(item) => item.id !== element.id,
																								),
																						  )
																				}
																				disabled={element.disabled}
																			/>
																		</td>
																		<td className='text-center'> {element.name} </td>
																		<td className='text-center'>
																			{Intl.NumberFormat("de-DE", {
																				style: "currency",
																				currency: "EUR",
																			}).format(element.price)}{" "}
																			{/* <span>iva compresa</span> */}
																		</td>
																	</tr>
																</tbody>
															);
														})}
												</Table>
											);
										})}
									</div>
								)}
								<div className='mb-4'>
									<Form.Label>
										<strong>Seleziona utente </strong>
									</Form.Label>

									<Select
										options={data}
										isSearchable={true}
										isMulti={false}
										isClearable={true}
										placeholder={"Seleziona Utente"}
										onChange={(e) => {
											setSelectUser(e);
										}}
										isOptionDisabled={(option) => option.disabled === true}
									/>
								</div>

								{selectUser !== "" && viewAgents.length > 0 && (
									<div className='mb-4'>
										<Form.Label>
											<strong>Seleziona agente </strong>
										</Form.Label>

										<Select
											options={viewAgents}
											isSearchable={true}
											isMulti={false}
											isClearable={true}
											placeholder={"Seleziona Agente"}
											onChange={(e) => {
												setSelectedAgent(e);
											}}
											isOptionDisabled={(option) => option.disabled === true}
										/>
									</div>
								)}

								{type !== "" && selectedElement !== "" && selectUser !== "" && (
									<>
										<div className='form-check form-switch'>
											<input
												className='form-check-input'
												type='checkbox'
												onChange={(e) => setSendEmail(!sendEmail)}
											/>
											<label className='form-check-label' for='flexSwitchCheckDefault'>
												Notifica ordine con email
											</label>
										</div>
										<div className='form-check form-switch'>
											<input
												className='form-check-input'
												type='checkbox'
												onChange={(e) => setNotPay(!pay)}
											/>
											<label className='form-check-label' for='flexSwitchCheckChecked'>
												Pagato
											</label>
										</div>
									</>
								)}
							</>
							{error !== "" && (
								<h3 style={{textAlign: "center", color: "red"}}> {error} </h3>
							)}
						</Form.Group>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={props.onHide}>
					Chiudi
				</Button>
				{method === "new" && success === false && (
					<Button
						variant='success'
						onClick={() => createOrder()}
						disabled={
							type !== "" && selectUser !== "" && selectUser !== null ? false : true
						}>
						Conferma
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
}

export default SubscribeModal;
