import React, {useState} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import {useLocation, Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
function AdminAppNav() {
	const {pathname} = useLocation();

	return (
		<div className='container  '>
			<div className='row row-admin-area'>
				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/homeadmin' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-clipboard2-data fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            				<hr /> */}
									<p
										className='card-title lead'
										style={{
											color: "#fff",
										}}>
										<FontAwesomeIcon
											icon={faChevronLeft}
											style={{
												color: "#fff",
												fontSize: "21px",
												marginRight: "5px",
											}}
										/>
										Torna alla gestione contenuti
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/adminOperator' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: pathname.includes("/adminOperator")
										? "#fff"
										: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-cart-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            					<hr /> */}
									<p
										className='card-title lead'
										style={{
											color: pathname.includes("/adminOperator") ? "#d50037" : "#fff",
										}}>
										{" "}
										Gestione Operatori{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/adminAllScans' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor: pathname.includes("/adminAllScans")
										? "#fff"
										: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-cart-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            					<hr /> */}
									<p
										className='card-title lead'
										style={{
											color: pathname.includes("/adminAllScans") ? "#d50037" : "#fff",
										}}>
										{" "}
										Tutte le scan{" "}
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/archiveScanCongressRegistration' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor:
										pathname.includes("/archiveScanCongressRegistration") ||
										pathname.includes("/congressScanSubscribeList")
											? "#fff"
											: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-cart-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
            						<hr /> */}
									<p
										className='card-title lead'
										style={{
											color:
												pathname.includes("/archiveScanCongressRegistration") ||
												pathname.includes("/congressScanSubscribeList")
													? "#d50037"
													: "#fff",
										}}>
										{" "}
										Gestione scan congressi
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>

				<div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 p-1'>
					<a className='text-decoration-none' href='#'>
						<Link to='/archiveScanEventsRegistration' className='text-light'>
							<div
								className='card p-3 shadow text-center border-0'
								style={{
									backgroundColor:
										pathname.includes("/archiveScanEventsRegistration") ||
										pathname.includes("/eventScanSubscribeList")
											? "#fff"
											: "#d50037",
									height: "80%",
								}}>
								<div className='card-body p-1'>
									{/* <i className="bi bi-person-lines-fill fa-2x" aria-hidden="true" style={{fontSize:"30px", color:"#fff"}}></i>
           					 <hr /> */}
									<p
										className='card-title lead'
										style={{
											color:
												pathname.includes("/archiveScanEventsRegistration") ||
												pathname.includes("/eventScanSubscribeList")
													? "#d50037"
													: "#fff",
										}}>
										{" "}
										Gestione scan eventi
									</p>
								</div>
							</div>
						</Link>
					</a>
				</div>
			</div>
		</div>
	);
}

export default AdminAppNav;
