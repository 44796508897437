import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import axios from "axios";
import Globals from "../../parameter/globals";
import "../../scss/archive-registration.scss";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AuthContext from "../../context/AuthProvider";
import { useContext } from "react";

const formatYear = (item) => {
	let d = new Date(item);
	let year = d.getFullYear();
	return year;
};
const formatDay = (item) => {
	let d = new Date(item);
	let day = "" + d.getDate();
	if (day.length < 2) {
		day = "0" + day;
	}

	return day;
};
const formatMonth = (item) => {
	let d = new Date(item);
	let month = d.getMonth() < 12 ? "" + (d.getMonth() + 1) : 1;
	if (month.length < 2) month = "0" + month;
	return month;
};
function ArchiveRegistration() {
	const [loading, setLoading] = useState(true);
	const [error, setErrMsg] = useState("");
	const [message, setMessage] = useState("");
	const { lang } = useContext(AuthContext);

	const [show, setShow] = useState({ showelement: false, postId: "" });

	const [showEv, setShowEv] = useState({ showelement: false, postId: "" });

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleCloseEv = () => setShowEv(false);
	const handleShowEv = () => setShowEv(true);
	const [congress, setCongress] = useState([]);
	const [events, setEvents] = useState([]);

	const { auth } = useAuth();

	//creazione della modal

	//gestione errori server

	//api di caricamento dei contenuti pre e post data di fine

	const getMyCongressAndEventArchive = async () => {
		try {
			setLoading(true);
			let body = {
				access_token: auth.accessToken,
			};

			let formdata = new FormData();
			formdata.append("body", JSON.stringify(body));

			const response = await axios.post(
				Globals.GETMYEVENTANDMYCONGRESSARCHIVE + "&lang=" + lang,
				formdata,
				{
					headers: { "Content-Type": "application/json" },
					withCredentials: true,
				}
			);

			if (response?.data?.error === true) {
				setLoading(false);
				setErrMsg(response.data.message);
			} else {
				setCongress(response.data.congress);
				setEvents(response.data.events);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			if (!err?.response) {
				setErrMsg("No Server Response");
			} else if (err.response?.status === 400) {
				setErrMsg("Missing Username or Password");
			} else if (err.response?.status === 401) {
				setErrMsg("Unauthorized");
			} else {
				setErrMsg("Errore");
			}
		}
	};
	const toggleModal = React.useCallback(
		(id) => () => {
			setShowEv({ showelement: false, postId: id });
			// console.log(`Post id: ${id}`);
		},
		[]
	);

	const toggleModalCong = React.useCallback(
		(id) => () => {
			setShow({ showelement: false, postId: id });
			// console.log(`Post id: ${id}`);
		},
		[]
	);

	useEffect(() => {
		getMyCongressAndEventArchive();
	}, [lang]);

	if (loading === true) {
		return <Loading />;
	} else {
		return (
			<div style={{ height: "auto", marginTop: "140px", marginBottom: "auto" }}>
				<Helmet>
					<meta charSet='utf-8' />
					<title>{Globals.language.title.enrollmentHistory[lang]}</title>
					<meta name="robots" content="noindex" />
				</Helmet>

				{events.length !== 0 ? (
					events.map((item, key) => (
						<div key={item.id} className='container-fluid text-center'>
							{/* <h1> Eventi passati</h1> */}
							<ul className='team-archive'>
								{key % 3 === 0 ? (
									<li className='member co-events'>
										<div className='thumb'>
											<img src={item.img_url} />
										</div>
										<div className='description  text-start'>
											<h3 className='title-archive'>{item.name}</h3>
											<p className='description-archive'>
												{item.title_description}
												<br></br>
												<div className='float-end py-2'>
													{Globals.language.archiveRegistration.title[lang]}
													<div>
														{item.start_date === item.end_date ? (
															<div>
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)}
															</div>
														) : (
															<div>
																{" "}
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)} / {formatDay(item.end_date)}-
																{formatMonth(item.end_date)}-{formatYear(item.end_date)}
															</div>
														)}
													</div>
												</div>
												<br></br>

												{item.agents?.length > 0 && (
													<div className='text-center'>
														<strong>
															{" "}
															{Globals.language.archiveRegistration.agents[lang]}{" "}
														</strong>
														{item.agents.map((item, key) => {
															return (
																<div>
																	<span> {item.categories_name}</span> -
																	<span> {item.name} </span>
																</div>
															);
														})}
													</div>
												)}
												<br></br>
												<button
													className='btn btn-light my-2'
													onClick={toggleModal(item.id)}>
													{Globals.language.archiveRegistration.details[lang]}
												</button>
											</p>
										</div>
									</li>
								) : (
									<li className='member member-right  co-events co-events-right'>
										<div className='thumb'>
											<img src={item.img_url} />
										</div>
										<div className='description'>
											<h3 className='title-archive'>{item.name}</h3>
											<p className='description-archive'>
												{item.title_description}
												<br></br>
												<div className='float-start py-2'>
													{Globals.language.archiveRegistration.title[lang]}
													<div>
														{item.start_date === item.end_date ? (
															<div>
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)}
															</div>
														) : (
															<div>
																{" "}
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)} / {formatDay(item.end_date)}-
																{formatMonth(item.end_date)}-{formatYear(item.end_date)}
															</div>
														)}{" "}
													</div>
												</div>
												<br></br>
												<button
													className='btn btn-light my-2'
													onClick={toggleModal(item.id)}>
													{Globals.language.archiveRegistration.details[lang]}
												</button>
											</p>
										</div>
									</li>
								)}
							</ul>

							<Modal
								centered
								show={item.id == showEv.postId}
								size='md'
								onHide={handleCloseEv}
								style={{ zIndex: 9999 }}>
								<Modal.Header closeButton>
									<Modal.Title>{item.name}</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<img
										src={item.img_url}
										className='img-responsive image-card-congressi'></img>
									{/* <h3 className="text-center">
            {formatDay(start_date)}-{formatMonth(start_date)}-
            {formatYear(start_date)} / {formatDay(end_date)}-
            {formatMonth(end_date)}-{formatYear(end_date)}
          </h3> */}
									<div className='text-center'>
										<h3>
											{item.start_date === item.end_date ? (
												<div>
													{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
													{formatYear(item.start_date)}
												</div>
											) : (
												<div>
													{" "}
													{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
													{formatYear(item.start_date)} / {formatDay(item.end_date)}-
													{formatMonth(item.end_date)}-{formatYear(item.end_date)}
												</div>
											)}
										</h3>
										<div>
											<h2>{Globals.language.archiveRegistration.titleModal[lang]}</h2>
											<h4>{item.title_description}</h4>
										</div>
										<div>
											<h2>{Globals.language.archiveRegistration.streetModal[lang]}</h2>
											<h4>{item.address}</h4>
										</div>
										<div>
											<h2>{Globals.language.archiveRegistration.cityModal[lang]}</h2>
											<h4>{item.city}</h4>
										</div>
									</div>
									<h4 className='d-flex w-25 text-center align-item-center justify-content-center btn btn-danger disabled mx-auto'>
										{parseFloat(item.total_price) === 0
											? Globals.language.archiveRegistration.free[lang]
											: Intl.NumberFormat("de-DE", {
													style: "currency",
													currency: "EUR",
											  }).format(item.total_price)}
									</h4>
								</Modal.Body>
								<i className='bi bi-qr-code mx-auto' style={{ fontSize: "80px" }}></i>
								{/* <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal(item.id)}>
            Chiudi
          </Button>
        </Modal.Footer> */}
							</Modal>
						</div>
					))
				) : (
					<div>
						{" "}
						<div className='py-3'>
							<Card className='d-flex justify-content-center align-items-center mx-auto py-2 w-75 mb-5'>
								<h3>{Globals.language.archiveRegistration.noRegistration[lang]}</h3>
							</Card>
						</div>
					</div>
				)}

				{congress.length !== 0 ? (
					congress.map((item, key) => (
						<div key={item.id} className='container-fluid text-center'>
							<ul className='team-archive'>
								{key % 3 === 0 ? (
									<li className='member co-congress co-congress'>
										<div className='thumb'>
											<img src={item.img_url} />
										</div>
										<div className='description text-start'>
											<h3 className='title-archive'>{item.name}</h3>
											<p className='description-archive'>
												{item.title_description}
												<br></br>
												<div className='float-end py-2'>
													{Globals.language.archiveRegistration.title[lang]}
													<div>
														{item.start_date === item.end_date ? (
															<div>
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)}
															</div>
														) : (
															<div>
																{" "}
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)} / {formatDay(item.end_date)}-
																{formatMonth(item.end_date)}-{formatYear(item.end_date)}
															</div>
														)}{" "}
													</div>
												</div>
												<br></br>
												<button
													className='btn btn-light my-2'
													onClick={toggleModalCong(item.id)}>
													{Globals.language.archiveRegistration.details[lang]}
												</button>
											</p>
										</div>
									</li>
								) : (
									<li className='member member-right co-congress co-congress-right'>
										<div className='thumb'>
											<img src={item.img_url} />
										</div>
										<div className='description'>
											<h3 className='title-archive'>{item.name}</h3>
											<p className='description-archive'>
												{item.title_description}
												<br></br>
												<div className='float-start py-2'>
													{Globals.language.archiveRegistration.title[lang]}

													<div>
														{item.start_date === item.end_date ? (
															<div>
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)}
															</div>
														) : (
															<div>
																{" "}
																{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
																{formatYear(item.start_date)} / {formatDay(item.end_date)}-
																{formatMonth(item.end_date)}-{formatYear(item.end_date)}
															</div>
														)}{" "}
													</div>
												</div>
												<br></br>

												<button
													className='btn btn-light my-2'
													onClick={toggleModalCong(item.id)}>
													{Globals.language.archiveRegistration.details[lang]}
												</button>
											</p>
										</div>
									</li>
								)}
							</ul>
							<Modal
								centered
								show={item.id == show.postId}
								size='md'
								onHide={handleClose}
								style={{ zIndex: 9999 }}>
								<Modal.Header closeButton>
									<Modal.Title>{item.name}</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<img
										src={item.img_url}
										className='img-responsive image-card-congressi'></img>
									{/* <h3 className="text-center">
            {formatDay(start_date)}-{formatMonth(start_date)}-
            {formatYear(start_date)} / {formatDay(end_date)}-
            {formatMonth(end_date)}-{formatYear(end_date)}
          </h3> */}
									<div className='text-center'>
										<h3>
											{item.start_date === item.end_date ? (
												<div>
													{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
													{formatYear(item.start_date)}
												</div>
											) : (
												<div>
													{" "}
													{formatDay(item.start_date)}-{formatMonth(item.start_date)}-
													{formatYear(item.start_date)} / {formatDay(item.end_date)}-
													{formatMonth(item.end_date)}-{formatYear(item.end_date)}
												</div>
											)}
										</h3>
										<div>
											<h2> {Globals.language.archiveRegistration.titleModal[lang]}</h2>
											<h4>{item.title_description}</h4>
										</div>
										<div>
											<h2> {Globals.language.archiveRegistration.streetModal[lang]}</h2>
											<h4>{item.address}</h4>
										</div>
										<div>
											<h2> {Globals.language.archiveRegistration.cityModal[lang]}</h2>
											<h4>{item.city}</h4>
										</div>
									</div>

									{item.services?.length > 0 && (
										<div className='text-center'>
											<strong>
												{" "}
												{Globals.language.archiveRegistration.services[lang]}{" "}
											</strong>
											{item.services.map((item, key) => {
												return (
													<div>
														<span> {item.categories_name}</span> -<span> {item.name} </span>
													</div>
												);
											})}
										</div>
									)}

									{item.agents?.length > 0 && (
										<div className='text-center'>
											<strong>
												{" "}
												{Globals.language.archiveRegistration.agents[lang]}{" "}
											</strong>
											{item.agents.map((item, key) => {
												return (
													<div>
														<span> {item.categories_name}</span> -<span> {item.name} </span>
													</div>
												);
											})}
										</div>
									)}

									<h4 className='d-flex w-25 text-center align-item-center justify-content-center btn btn-danger disabled mx-auto'>
										{parseFloat(item.total_price) === 0
											? Globals.language.archiveRegistration.free[lang]
											: Intl.NumberFormat("de-DE", {
													style: "currency",
													currency: "EUR",
											  }).format(item.total_price)}
									</h4>
								</Modal.Body>
								{/* <i className='bi bi-qr-code mx-auto' style={{ fontSize: "80px" }}></i> */}
							</Modal>
						</div>
					))
				) : (
					<div>
						<Card className='d-flex justify-content-center align-items-center mx-auto py-2 w-75 mb-5'>
							<h3>
								{Globals.language.archiveRegistration.noRegistrationCongress[lang]}
							</h3>
						</Card>
					</div>
				)}
			</div>
		);
	}
}

export default ArchiveRegistration;
